import React from 'react'
import { Box } from '@mui/material'

// Local  Imports
import Layout from '../components/Layout'
import UsersList from '../components/Users/UsersList'

const UsersPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <UsersList />
      </Layout>
    </React.Fragment>
  )
}

export default UsersPage
