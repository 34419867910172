import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown,} from '@fortawesome/free-solid-svg-icons';
import bellIcon from '../../assets/bellIcon.png'
import loginAvatar from '../../assets/loginAvatar.png'
import { Link } from 'react-router-dom';
const HeaderPanel = ({message}) => {
    return (
        <header className="bg-white px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center border-b-2 border-gray-200">
            <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold">{message}</h1>
            <div className="flex-1 flex max-w-md ml-auto lg:mx-0 lg:ml-4 lg:flex-none">
                <div className="relative">
                    <input
                        type="search"
                        placeholder="Search"
                        className="w-full pl-10 pr-4 py-2 border-2 border-gray-300 rounded-full focus:outline-none focus:border-gray-400"
                    />
                    <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform mt-[-2px] -translate-y-1/2 text-gray-400" />
                </div>
                <div className="flex items-center gap-2 cursor-pointer">
                <Link to="/notification" className="text-gray-400 sm:text-lg mx-3 mt-[-2px]"> <img src={bellIcon} alt="error" /></Link>
  
                    <img
                        src={loginAvatar}
                        alt="Profile"
                        className="w-12 h-12 rounded-full object-cover"
                    />
                    <span className="hidden sm:inline">Gracetrans</span>
                    <FontAwesomeIcon icon={faChevronDown} className="text-gray-400" />
                </div>
            </div>

        </header>
    );
};

export default HeaderPanel;
