import React from 'react';
import { TextField as Field, Box, Typography, TextareaAutosize, styled, InputAdornment } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Import your styles here if needed
import Styles from '../styles/home.module.css';


const StyledTextarea = styled(TextareaAutosize)(({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 12px 12px;
  color: ${theme.palette.text.primary};
  background: ${theme.palette.secondary.main};
  resize: none;
  overflow: auto;
  height: 135px !important;
  
  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
    border-width: 2px;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`);

const TextField = ({
  fieldName,
  placeholder,
  name,
  value,
  onChange,
  width,
  error,
  helperText,
  type,
  showAndronment,
}) => {
  const [showPassword, setShowPassword] = React.useState(true);
  return (
    <React.Fragment>
      <Box width='100%'>
        <Typography fontSize='12px' color='text.secondary'>
          {fieldName}
        </Typography>
        {type !== 'textarea' ? (
          <>
            <Field
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={onChange}
              type={showAndronment && showPassword ? 'password' : type}
              autoComplete='off'
              fullWidth
              variant='outlined'
              className={Styles.inputFieldStyle} // Adjust class name based on your project's structure
              InputProps={{
                endAdornment: showAndronment && (
                  <InputAdornment
                    position='end'
                    sx={{ color: 'text.secondary', cursor: 'pointer' }} // Adjust styling as needed
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <RemoveRedEyeIcon />
                    )}
                  </InputAdornment>
                ),
              }}
              // Adjust MUI sx props as needed
              error={error}
            />

            {error ? (
              <Typography color='error' fontSize='12px'>
                {helperText}
              </Typography>
            ) : (
              ''
            )}
          </>
        ) : (
          <StyledTextarea
            minRows={5}
            onChange={onChange}
            value={value}
            name={name}
            aria-label='message'
            autoComplete='off'
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default TextField;
