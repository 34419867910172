// Library Imports
import React from 'react'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import DescriptionIcon from '@mui/icons-material/Description'
import CustomCheckBox from '../CheckBox'

// Local Imports

const BodyCell = styled(TableCell)(({ theme }) => ({
  // color: 'white',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: '12px',
  padding: '20px 10px',
  height: 20,
  borderBottom: 'none',
  //   '&:last-child': {
  //     borderBottom: 'none !important',
  //   },
}))

const BodyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'transparent',
}))

const TableMainBody = ({
  rows,
  columns,
  selectedIds,
  setSelectedIds,
  tableColumnExtensions,
  dataProviders,
  onRowClick,
  isUnits,
}) => {
  return (
    <React.Fragment>
      <TableBody>
        {rows?.length > 0 ? (
          rows?.map((row, rowIndex) => {
            const id = row?.id
            const odd = rowIndex % 2 === 1
            // IS SELECTED ID
            const isRowSelected = selectedIds?.includes(id)
            // ONCHANGE OF SELECTIONS CHECKBOX
            const onChange = () => {
              const ids = isRowSelected
                ? selectedIds?.filter((ele) => ele !== id)
                : [...selectedIds, id]
              setSelectedIds(ids)
            }
            return (
              <BodyRow
                key={rowIndex}
                sx={{
                  backgroundColor: odd ? '#f1f1f1' : 'unset',
                }}
              >
                <BodyCell>
                  <CustomCheckBox
                    checked={Boolean(isRowSelected)}
                    onChange={onChange}
                  />
                </BodyCell>
                {columns?.map((col, colIndex) => {
                  // GET COLUMN EXTENSION
                  const columnExtension = tableColumnExtensions?.find(
                    (item) => item?.columnName === col?.name
                  )
                  // GET EXTENSION WITH
                  const width = columnExtension?.width || 100
                  const dataProvider = dataProviders?.find(
                    (provider) => provider?.columnName[0] === col?.name
                  )

                  return (
                    <BodyCell
                      key={colIndex}
                      color={'primary'}
                      width={columnExtension?.width || 100}
                      style={{ minWidth: width }}
                      onClick={(event) => {
                        event.stopPropagation()
                        if (col?.name !== 'action' && isUnits) {
                          onRowClick(row)
                        }
                      }}
                      sx={{
                        cursor:
                          col?.name !== 'action' && isUnits
                            ? 'pointer'
                            : 'unset',
                      }}
                    >
                      {dataProvider !== undefined
                        ? dataProvider?.func({ row: row })
                        : row[col?.name]}
                    </BodyCell>
                  )
                })}
              </BodyRow>
            )
          })
        ) : (
          <div className='absolute flex flex-col items-center mt-10 left-[50%] translate-x-1/2'>
            <DescriptionIcon fontSize='large' color='info' />
            <p className='mt-2 text-[#13343B] whitespace-nowrap text-sm'>
              No data found.
            </p>
          </div>
        )}
      </TableBody>
    </React.Fragment>
  )
}

export default TableMainBody
