import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
// Local Import
import GlobalContext from "../../../context/GlobalContext";
import InfoIcon from '../../../assets/icons/InfoIcon'
import LeftArrowIcon from '../../../assets/icons/LeftArrowIcon'
import JazzCashIconMobile from '../../../assets/icons/JazzCashIconMobile'

const JazzCashMethod = ({ onClickPayNow, loading, error }) => {
  // NAVIGATION
  const { themeToggle, } = useContext(GlobalContext);
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Box
        component='div'
        padding='40px'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        minHeight='50vh'
        flexWrap='wrap'
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap='20px'
        >
          <div className='flex items-start gap-2 '>
            <InfoIcon />
            <p className={`text-[14px] leading-4 font-[400] ${themeToggle ? "text-white" : "text-[#333333]"}`}>
              Transfer <span className='font-bold'>Amount PKR 2000</span> to
              account numspaner <span className='font-bold'>1025487651234</span>{' '}
              bank name Allied Bank and attach transaction id or screen shoot
              below
            </p>
          </div>
          <div className=' items-center gap-4 hidden'>
            <div className='w-[100%] flex flex-col gap-3 justify-start'>
              <label className='flex items-center gap-2 text-[16px] leading-[18px] text-[#333333] font-[400]'>
                <span className='text-[#EB5757]'>*</span> Card Number
              </label>
              <input
                type='text'
                placeholder='Enter Card Number'
                className='placeholder:text-[#C2C2C2] p-2 outline-none border-1 border-[#13343B] rounded focus:border-2 focus:border-[#13343B]'
              />
            </div>
            <div className='w-[100%] flex flex-col gap-3 justify-start'>
              <label className='flex items-center gap-2 text-[16px] leading-[18px] text-[#333333] font-[400]'>
                <span className='text-[#EB5757]'>*</span>Name on Card
              </label>
              <input
                type='text'
                placeholder='Enter Number On Card'
                className='placeholder:text-[#C2C2C2] p-2 outline-none border-1 border-[#13343B] rounded focus:border-2 focus:border-[#13343B]'
              />
            </div>
          </div>
        </Box>
        <Box display='flex' alignItems='start' flexDirection='column'>
          <div className='flex items-center gap-[12px]'>
            <button
              className='bg-[#20808D] hover:bg-[#20808D] px-6 py-2  text-white rounded-full'
              onClick={() => onClickPayNow('jazzcash')}
            >
              {loading ? (
                <CircularProgress
                  color='inherit'
                  size={18}
                  sx={{ marginTop: '3px' }}
                />
              ) : (
                'Pay Now'
              )}
            </button>
            <button
              onClick={() => navigate(-1)}
              className={`hover:bg-slate-300 px-6 py-2 rounded-full ${themeToggle ? "text-white" : "text-black"}`}
            >
              Go Back
            </button>
          </div>
          <div className='w-full mt-[auto] pt-2 '>
            <p className={`text-[#FF0000] text-[14px]`}>
              {error?.type === 'wrong' ? error?.message : ''}
            </p>
          </div>
        </Box>
      </Box>
    </React.Fragment >
  )
}

export default JazzCashMethod

export const JazzCashMethodMobile = ({ onClickPayNow, loading, error }) => {
  // NAVIGATION
  const navigate = useNavigate()
  const { themeToggle, } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <Box
        component='div'
        padding='0px 40px 40px 40px '
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        minHeight='50vh'
        flexWrap='wrap'
      >
        {/* Back Icon */}
        <div
          className='flex items-center pt-3 cursor-pointer hover:text-[#676565]'
          onClick={() => navigate(-1)}
        >
          <div>
            <LeftArrowIcon className={'rotate-180'} />
          </div>
          <div className='flex items-center gap-1'>
            <p className={`font-[600] mb-0`}>Payment Details</p>
          </div>
        </div>

        {/* Payment Logo  */}
        <div className='flex items-center gap-4 '>
          <JazzCashIconMobile width={100} height={80} />
          <p
            className={` font-[600] text-[20px] font-['ui-sans-serif'] translate-y-2`}
          >
            Jazz Cash
          </p>
        </div>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        // gap='0px'
        >
          <div className='flex items-start gap-2 '>
            <p className={`text-[14px] leading-4 font-[400] ${themeToggle ? "text-white" : "text-[#333333]"}`}>
              Transfer <span className='font-bold'>Amount PKR 2000</span> to
              account numspaner <span className='font-bold'>1025487651234</span>{' '}
              bank name Allied Bank and attach transaction id or screen shoot
              below
            </p>
          </div>
        </Box>
        <Box display='flex' alignItems='center' gap='10px' mt='40px'>
          <button
            className='bg-[#20808D] hover:bg-[#20808D] px-6 py-2 text-white rounded-full'
            onClick={() => onClickPayNow('jazzcash')}
          >
            {loading ? (
              <CircularProgress
                color='inherit'
                size={18}
                sx={{ marginTop: '3px' }}
              />
            ) : (
              'Pay Now'
            )}
          </button>
          <button
            className={`hover:bg-gray-500 px-6 py-2 rounded-full ${themeToggle ? "text-white" : "text-black"}`}
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </Box>
        <div className='w-full '>
          <p className={`text-[#FF0000] text-[14px]`}>
            {error?.type === 'wrong' ? error?.message : ''}
          </p>
        </div>
      </Box>
    </React.Fragment>
  )
}
