import * as React from 'react'
const BankTransferIconMobile = (props) => (
  <svg
    width={70}
    height={49}
    viewBox='0 0 73 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.98312 0H67.479C70.2314 0 72.4621 2.23071 72.4621 4.98312V43.3189C72.4621 46.0714 70.2314 48.3021 67.479 48.3021H4.98312C2.23071 48.3021 0 46.0714 0 43.3189V4.98312C0 2.23071 2.23071 0 4.98312 0Z'
      fill='#F2F2F2'
    />
    <path
      d='M25.8873 12.7372C27.0926 12.7372 28.0281 12.989 28.7297 13.4927C29.4133 13.9964 29.7551 14.77 29.7731 15.7954C29.7731 16.2991 29.6652 16.8028 29.4313 17.2525C29.2334 17.6663 28.8916 18.0081 28.4778 18.206V18.2779C28.9456 18.4938 29.3413 18.8356 29.6292 19.2854C29.8091 19.5552 29.935 19.843 30.0249 20.1489C30.1329 20.5087 30.1869 20.9044 30.1689 21.2822C30.1869 21.8579 30.0609 22.4156 29.8271 22.9193C29.6112 23.387 29.2694 23.7828 28.8736 24.0886C28.4599 24.4124 27.9921 24.6463 27.4884 24.7902C26.9667 24.9521 26.409 25.024 25.8693 25.024H19.2852V12.7012H25.8873V12.7372ZM22.4513 17.2705H25.3477C25.7074 17.3065 26.0492 17.1986 26.3191 16.9647C26.499 16.7668 26.6069 16.497 26.5889 16.2271C26.5889 15.9753 26.499 15.7234 26.3191 15.5255C26.0492 15.2917 25.6895 15.1837 25.3477 15.2197H22.4513V17.2705ZM22.4513 22.5595H25.3836C25.8334 22.5954 26.2831 22.4695 26.6249 22.1817C26.8768 21.9118 27.0027 21.57 26.9847 21.1923C27.0207 20.7785 26.8588 20.3827 26.553 20.1129C26.2112 19.861 25.7974 19.7351 25.3836 19.7531H22.4513V22.5595Z'
      fill='#010202'
    />
    <path
      d='M33.1732 25.0588H29.8271L34.1806 12.7539H37.4188L41.8802 25.0588H38.3542L37.7606 22.918H33.7849L33.1732 25.0588ZM35.7817 15.974H35.7097L34.4325 20.5434H37.041L35.7817 15.974Z'
      fill='#010202'
    />
    <path
      d='M45.5858 17.7734H45.5138V25.0592H42.3477V12.7363H45.8556L50.0472 19.6084H50.1192V12.7363H53.2853V25.0592H50.1012L45.5858 17.7734Z'
      fill='#010202'
    />
    <path
      d='M57.6925 12.7363V17.0538L61.7401 12.7363H65.9137L60.9486 17.4316L66.2015 25.0592H62.2258L58.5919 19.5904L57.6745 20.4719V25.0592H54.5083V12.7363H57.6925Z'
      fill='#010202'
    />
    <path
      d='M12.2506 26.9482V29.017H10.0919V36.2669H7.80719V29.017H5.64844V26.9482H12.2506Z'
      fill='#010202'
    />
    <path
      d='M17.7378 26.9482C18.0616 26.9482 18.4034 27.0202 18.6912 27.1461C18.9791 27.2721 19.2489 27.4699 19.4468 27.7218C19.6627 27.9736 19.8246 28.2615 19.9325 28.5853C20.0584 28.9271 20.1124 29.2869 20.1124 29.6467C20.1304 30.1864 19.9865 30.7081 19.7166 31.1758C19.4468 31.6075 19.069 31.9314 18.6193 32.1472L20.3103 36.2669H17.8817L16.4785 32.471H14.6436V36.2669H12.3589V26.9482H17.7378ZM17.0542 30.5282C17.27 30.5462 17.4859 30.4562 17.6119 30.2763C17.7378 30.1144 17.7917 29.9165 17.7917 29.7186C17.7917 29.6287 17.7738 29.5208 17.7558 29.4308C17.7378 29.3409 17.6838 29.2509 17.6298 29.161C17.5759 29.089 17.4859 29.017 17.396 28.9631C17.288 28.9091 17.1801 28.8731 17.0542 28.8911H14.6616V30.5282H17.0542Z'
      fill='#010202'
    />
    <path
      d='M22.1274 36.2669H19.7168L22.847 26.9482H25.1856L28.3878 36.2669H25.8512L25.4195 34.6478H22.5592L22.1274 36.2669ZM24.0163 29.3948H23.9803L23.0629 32.8488H24.9338L24.0163 29.3948Z'
      fill='#010202'
    />
    <path
      d='M30.7263 30.762H30.6724V36.2669H28.3877V26.9482H30.9062L33.9285 32.1472H33.9825V26.9482H36.2671V36.2669H33.9645L30.7263 30.762Z'
      fill='#010202'
    />
    <path
      d='M40.1707 36.5016C39.667 36.5196 39.1633 36.4476 38.6776 36.3037C38.2818 36.1778 37.922 35.9979 37.5982 35.764C37.3104 35.5302 37.0585 35.2603 36.8786 34.9185C36.6987 34.5767 36.5548 34.2169 36.4648 33.8571L38.6416 32.9756C38.6596 33.4434 38.8395 33.8751 39.1633 34.1989C39.5051 34.4868 39.9368 34.6307 40.3686 34.6127C40.4945 34.6127 40.6204 34.5947 40.7464 34.5767C40.8723 34.5587 40.9802 34.5227 41.0882 34.4508C41.1961 34.3968 41.2681 34.3069 41.34 34.1989C41.412 34.091 41.448 33.9471 41.448 33.8211C41.448 33.6952 41.43 33.5693 41.394 33.4434C41.34 33.3174 41.2681 33.2095 41.1781 33.1375C41.0522 33.0296 40.9083 32.9397 40.7464 32.8677C40.5125 32.7598 40.2786 32.6878 40.0448 32.6158C39.5411 32.4719 39.0553 32.292 38.5876 32.0941C38.2098 31.9322 37.868 31.7164 37.5442 31.4465C37.2744 31.2127 37.0585 30.9248 36.9146 30.601C36.7707 30.2412 36.6987 29.8634 36.6987 29.4857C36.6987 29.0719 36.7887 28.6581 36.9686 28.2983C37.1485 27.9565 37.4003 27.6507 37.7241 27.4168C38.0659 27.165 38.4437 26.9851 38.8575 26.8772C39.3252 26.7512 39.8109 26.6973 40.2966 26.6973C40.7284 26.6973 41.1781 26.7332 41.5919 26.8412C41.9337 26.9311 42.2575 27.075 42.5453 27.2729C42.8152 27.4528 43.049 27.6867 43.2289 27.9565C43.4268 28.2624 43.5707 28.5682 43.6967 28.91L41.7358 29.7735C41.6638 29.4137 41.4839 29.0899 41.2321 28.838C40.6924 28.4782 40.0088 28.4602 39.4511 28.7841C39.2892 28.91 39.1993 29.0899 39.1813 29.2878C39.1813 29.4137 39.1993 29.5216 39.2532 29.6296C39.3072 29.7375 39.3971 29.8454 39.5051 29.9174C39.649 30.0253 39.8109 30.1153 39.9908 30.1693C40.2606 30.2772 40.5125 30.3492 40.7823 30.4211C41.2141 30.547 41.6458 30.673 42.0236 30.7989C42.3834 30.9248 42.7252 31.1047 43.031 31.3386C43.3189 31.5724 43.5527 31.8603 43.7147 32.1841C43.8946 32.5979 43.9845 33.0656 43.9665 33.5153C43.9845 33.9651 43.8766 34.3968 43.6787 34.8106C43.4808 35.1704 43.2109 35.4942 42.8691 35.728C42.5093 35.9799 42.0956 36.1778 41.6638 36.2857C41.1601 36.4476 40.6564 36.5196 40.1707 36.5016Z'
      fill='#010202'
    />
    <path
      d='M46.4126 30.4022H49.9566V32.471H46.4126V36.2669H44.1279V26.9482H51.0359V29.017H46.4126V30.4022Z'
      fill='#010202'
    />
    <path
      d='M51.2163 26.9482H58.1243V29.017H53.501V30.2763H57.5486V32.3451H53.501V34.216H58.2502V36.2848H51.2163V26.9482Z'
      fill='#010202'
    />
    <path
      d='M63.7905 26.9482C64.1143 26.9482 64.4561 27.0202 64.744 27.1461C65.0318 27.2721 65.3016 27.4699 65.4995 27.7218C65.7154 27.9736 65.8773 28.2615 65.9852 28.5853C66.1112 28.9271 66.1651 29.2869 66.1651 29.6467C66.1831 30.1864 66.0392 30.7081 65.7694 31.1758C65.4995 31.6075 65.1217 31.9314 64.672 32.1472L66.363 36.2669H63.9344L62.5312 32.471H60.6963V36.2669H58.4116V26.9482H63.7905ZM63.1069 30.5282C63.3228 30.5462 63.5387 30.4562 63.6646 30.2763C63.7905 30.1144 63.8445 29.9165 63.8445 29.7186C63.8445 29.6287 63.8265 29.5208 63.8085 29.4308C63.7905 29.3409 63.7365 29.2509 63.6826 29.161C63.6286 29.089 63.5387 29.017 63.4487 28.9631C63.3408 28.9091 63.2328 28.8731 63.1069 28.8911H60.7143V30.5282H63.1069Z'
      fill='#010202'
    />
    <path d='M18.061 12.7363H5.59424V15.2369H18.061V12.7363Z' fill='#010202' />
    <path d='M18.061 17.4316H5.59424V19.9322H18.061V17.4316Z' fill='#010202' />
    <path d='M18.061 22.5771H5.59424V25.0777H18.061V22.5771Z' fill='#010202' />
  </svg>
)
export default BankTransferIconMobile
