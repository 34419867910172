import React, { useContext, useState, useEffect } from 'react'
import '../styles/ConversationView.css'
import Layout from '../components/Layout'
import GlobalContext from '../context/GlobalContext'
import ChatComponent from '../components/ChatComponent'
import { IoLockClosedOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import Judgements from '../components/Judgements'
import ChatBot from '../components/ChatBot'
import ResponsiveNav from '../components/ResponsiveNav'
import Statute from '../components/Statute'
import QuizApp from '../components/quiz/QuizApp'
import Drafts from '../components/Drafts'
import Cookies from 'js-cookie'
import Button from '@mui/material/Button'
function ConversationView(props) {
  const {
    selectedTab,
    userInfo,
    isChatViewVisible,
    themeToggle,
    isSubscriptionActive,
  } = useContext(GlobalContext)

  const subscription_plan = Cookies.get('subscriptionPlan') === 'true'
  const { id } = useParams()
  const navigate = useNavigate()
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768)
  const isStatuteActive =
    selectedTab === 'Statutes' ||
    selectedTab === 'Drafts' ||
    selectedTab === 'Judgement'

  const handleNavigate = () => {
    navigate('/payment')
  }

  useEffect(() => {
    const handleResize = () => {
      const newIsSmallScreen = window.innerWidth < 768
      if (newIsSmallScreen !== isSmallScreen) {
        setIsSmallScreen(newIsSmallScreen)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isSmallScreen])

  const isProtectedTab = [
    'ChatBot',
    'Judgement',
    'Drafts',
    'Statutes',
  ].includes(selectedTab)
  const isStudentTab = ['Judgement', 'Drafts'].includes(selectedTab)
  const showLockPrompt = !isSubscriptionActive
    ? isProtectedTab
    : isStudentTab && subscription_plan
  const SubscriptionPrompt = () => (
    <div className='blur_bg right-0 left-0 bottom-0 top-[73px] w-full flex-col flex items-center justify-center'>
      <div className='flex flex-col-reverse  items-center justify-center gap-3'>
        <span className='font-roboto 2xl:text-[30px] text-[20px] font-normal text-[#4f4f4f]-600 '>
          For query please upgrade your payment plan 
        </span>
        <IoLockClosedOutline
          className='2xl:w-[40px] 2xl:h-[40px]  w-[30px] h-[30px]'
          color='#000'
        />
      </div>
      <Button
        onClick={handleNavigate}
        className='payment-button lowercase flex items-center justify-center cursor-pointer gap-1 mt-2'
        sx={{
          textDecoration: 'none',
          width: '100px', // For smaller screens
          height: '40px',
          borderRadius: '11px',
          border: '1px solid #192D31',
          backgroundColor: '#21808D',
          color: '#fff',
          fontSize: '16px', // For smaller screens
          fontWeight: '600',
          boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
          transition: 'all 0.2s ease-in-out',
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: '#1A6F75',
          },
          '&:active': {
            backgroundColor: '#1A6F75', // Darker when pressed
            boxShadow: 'inset 0 3px 5px rgba(0,0,0,0.2)', // Simulate depth on press
          },
          '@media (min-width: 1536px)': {
            // 2xl breakpoint
            width: '150px',
            height: '60px',
            fontSize: '20px',
          },
        }}
      >
        Proceed
      </Button>
    </div>
  )

  return (
    <Layout>
      <div
        className={`md:flex xs:block w-100 h-100 ${
          themeToggle ? 'dark-mode' : ''
        } dark:bg-[#232123]`}
      >
        {isSmallScreen && <ResponsiveNav />}
        {!isSmallScreen && selectedTab === 'ChatBot' && <ChatComponent />}
        {isChatViewVisible && (
          <div
            id='conversation-view'
            className={`dark:bg-[#232123] ${
              isStatuteActive ? 'statute-view' : ''
            }`}
          >
            {showLockPrompt ? (
              <SubscriptionPrompt />
            ) : (
              {
                ChatBot: (
                  <ChatBot
                    debugMessage={props.debugMessage}
                    setDebugMessage={props.setDebugMessage}
                  />
                ),
                Judgement: <Judgements />,
                Drafts: <Drafts />,
                Statutes: <Statute />,
                MCQs: <QuizApp className='mcqs-tab' />,
                // ... other tab components
              }[selectedTab]
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ConversationView
