// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDAx_LiReycVSHCjZtG8tfwQlL2IaET4hw",
  authDomain: "lexa-react.firebaseapp.com",
  projectId: "lexa-react",
  storageBucket: "lexa-react.appspot.com",
  messagingSenderId: "1019998955416",
  appId: "1:1019998955416:web:4f9b6274689b36e313eded",
  measurementId: "G-JJTCFNRV17"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export { app, analytics }; 