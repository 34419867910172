import React from 'react'
import { Delete, Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

export const Action = (restProps, callBack) => {
  return (
    <React.Fragment>
      <div className='flex  justify-start items-center '>
        {/* <IconButton
          className='cursor-pointer !p-[4px]'
          onClick={() => callBack('edit', restProps.row)}
          sx={{ color: '#13343B' }}
        >
          <Edit fontSize='small' />
        </IconButton> */}
        <IconButton
          className='cursor-pointer !p-[4px]'
          onClick={() => callBack('delete', restProps.row)}
          sx={{ color: '#13343B' }}
        >
          <Delete fontSize='small' />
        </IconButton>
      </div>
    </React.Fragment>
  )
}

export const Status = (restProps) => {
  return (
    <div className='flex flex-row justify-start pl-2'>
      <span className='cursor-pointer'>
        {restProps.row.status ? (
          <CheckCircleRoundedIcon fontSize='small' color='success' />
        ) : (
          <CancelRoundedIcon fontSize='small' color='error' />
        )}
      </span>
    </div>
  )
}
