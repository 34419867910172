import React from 'react'

function About() {
    return (
      <div>
        <section id='About' className='about-section mt-80'>
          <div className='container'>
            <div className='row '>
              <div className='col-md-8 offset-md-2'>
                <div className='heading text-center pt-4' data-aos='fade-right'>
                  <div className='about text-white'>
                    <h1 className='text-4xl font-bold'>about us</h1>
                    <div className='divider border-2 border-[#0e877d] block m-auto w-20 mb-5'></div>
                  </div>
                  <p>
                    Lexa revolutionizes legal assistance in Pakistan with AI,
                    offering lawyers and law students direct access to a
                    comprehensive suite of legal resources for simplified
                    research and study, available anytime, anywhere."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

export default About