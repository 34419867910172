// import assets from '../assets'; // Adjust the import path as needed
import { Buffer } from 'buffer';


// TruncateString Function
export function truncateString(text, maxWords) {
  const words = text.split(' ');
  if (words.length <= maxWords) {
    return text;
  }
  const truncatedText = words.slice(0, maxWords).join(' ');
  return truncatedText + '...';
}

// DateFormat Function
export const dateFormate = (date) => {
  const originalDate = new Date(date);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const formattedDate = `${months[originalDate.getMonth()]} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
  return formattedDate;
};

// FormatPrice Function
export const formatePrice = (price, locale = 'en-US', currency = 'USD') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(price);
};

// Create Image File From Base64
export const base64ToImageFile = (base64) => {
  const byteCharacter = atob(base64);
  const byteNumbers = new Array(byteCharacter.length);
  for (let i = 0; i < byteCharacter.length; i++) {
    byteNumbers[i] = byteCharacter.charCodeAt(i);
  }
  const byteArray = new Uint8ClampedArray(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });
  const file = new File([blob], 'image.jpeg', { type: 'image/jpeg' });
  const url = URL.createObjectURL(file);
  return url;
};

// Different base64ToImage Functions for various uses
export const base64ToImageBlog = (base64) => {
  const newImg = new Image();
  newImg.src = `data:image/png;base64,${base64}`;
  newImg.style.width = '100%';
  newImg.style.height = '233px';
  newImg.style.objectFit = 'cover';
  newImg.style.borderRadius = '10px';
  return newImg;
};

export const base64ToImageMeetTheTeam = (base64) => {
  const newImg = new Image();
  newImg.src = `data:image/png;base64,${base64}`;
  newImg.style.width = '100%';
  newImg.style.height = '100%';
  newImg.style.objectFit = 'contain';
  newImg.style.borderRadius = '12px';
  return newImg;
};

export const featureImagebase64ToImage = (base64) => {
  const newImg = new Image();
  newImg.src = `data:image/png;base64,${base64}`;
  newImg.style.width = '100%';
  newImg.style.height = '360px';
  newImg.style.objectFit = 'cover';
  newImg.style.borderRadius = '12px';
  return newImg;
};

// Convert URL to Base64
export const urlToBase64 = async (url) => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const base64Image = Buffer.from(buffer).toString('base64');
  return base64Image;
};

// Function to get public link from Google
export function getPublicLinkFromGoogle(url) {
  if (!url) return null;
  const newURL = new URL(url).searchParams.get('id');
  return `https://lh3.googleusercontent.com/d/${newURL}`;
}
