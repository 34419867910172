import React from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'

const MainSectionThankyou = () => {
  // GET PARAMS
  const [params] = useSearchParams()
  return (
    <React.Fragment>
      {params.get('success') === 'true' ? (
        <PaymentDone payment={params.get('amount_cents')} />
      ) : params.get('success') === 'false' ? (
        <PaymentFailed />
      ) : (
        <NoActivePayments />
      )}
    </React.Fragment>
  )
}

export default MainSectionThankyou

const PaymentDone = ({ payment }) => {
  const trimmedPayment = payment ? payment.slice(0, -2) : '0'; // This will remove the last three characters
  return (
    <div
      class='bg-gray-100 flex m-auto items-center'
      style={{ height: 'calc(100vh - 60px)' }}
    >
      <div class='bg-white p-6  md:mx-auto'>
        <svg viewBox='0 0 24 24' class='text-green-600 w-16 h-16 mx-auto my-6'>
          <path
            fill='currentColor'
            d='M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z'
          ></path>
        </svg>
        <div class='text-center'>
          <h3 class='md:text-2xl text-base text-gray-900 font-semibold text-center'>
            Payment Done!
          </h3>
          <p class='text-gray-600 my-2'>
            Thank you for completing your secure online payment of {trimmedPayment}.
          </p>
          <p> Have a great day! </p>
          <div class='py-10 text-center'>
            <NavLink
              to='/'
              className='text-[20px] p-3 no-underline w-[150px] bg-[#20808D] text-white'
            >
              GO BACK
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
const PaymentFailed = () => {
  return (
    <div
      class='bg-gray-100 flex m-auto items-center'
      style={{ height: 'calc(100vh - 60px)' }}
    >
      <div class='bg-white p-6  md:mx-auto'>
        <svg
          viewBox='-7 -6 30 30'
          class='bg-red-600 w-16 h-16 text-wwhite mx-auto my-6 rounded-full flex justify-center items-center'
        >
          <path
            fill='#fff'
            d='M2.64,1.27L7.5,6.13l4.84-4.84C12.5114,1.1076,12.7497,1.0029,13,1c0.5523,0,1,0.4477,1,1&#xA;&#x9;c0.0047,0.2478-0.093,0.4866-0.27,0.66L8.84,7.5l4.89,4.89c0.1648,0.1612,0.2615,0.3796,0.27,0.61c0,0.5523-0.4477,1-1,1&#xA;&#x9;c-0.2577,0.0107-0.508-0.0873-0.69-0.27L7.5,8.87l-4.85,4.85C2.4793,13.8963,2.2453,13.9971,2,14c-0.5523,0-1-0.4477-1-1&#xA;&#x9;c-0.0047-0.2478,0.093-0.4866,0.27-0.66L6.16,7.5L1.27,2.61C1.1052,2.4488,1.0085,2.2304,1,2c0-0.5523,0.4477-1,1-1&#xA;&#x9;C2.2404,1.0029,2.4701,1.0998,2.64,1.27z'
          />
        </svg>
        <div class='text-center'>
          <h3 class='md:text-2xl text-base text-gray-900 font-semibold text-center'>
            Payment Failed!
          </h3>
          <p class='text-gray-600 my-2'>
            We're sorry, but your payment could not be processed.
          </p>
          <p>Please try again later.</p>
          <div class='py-10 text-center'>
            <NavLink
              to='/payment'
              className='text-[20px] p-3 no-underline w-[150px] bg-[#20808D] text-white'
            >
              GO BACK TO PLANS
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

const NoActivePayments = () => {
  return (
    <div
      className='bg-gray-100 flex m-auto items-center'
      style={{ height: 'calc(100vh - 60px)' }}
    >
      <div className='bg-white p-6 md:mx-auto'>
        <div className='text-center'>
          <h3 className='md:text-2xl text-base text-gray-900 font-semibold text-center'>
            No Active Payments
          </h3>
          <p className='text-gray-600 my-2'>
            There are currently no active payments associated with your account.
          </p>
          <div className='py-10 text-center'>
            <NavLink
              to='/payment'
              className='text-[20px] p-3 no-underline w-[150px] bg-[#20808D] text-white'
            >
              GO TO PAYMENT PAGE
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
