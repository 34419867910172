import React from 'react';
import DashboardCard from "./DashboardCard";
import SubscribersDetails from "./SubscribersDetails";
import AnalyticBarChart from "./AnalyticalChartBar";
import VisitorsOverviewChart from "./VisitorsOverviewChart";
import Sidebar from './Sidebar';
import HeaderPanel from './HeaderPanel';

function Dashboard() {
    return (
        <div className="grid grid-cols-12 gap-6">
            {/* Assuming the sidebar takes up the first 2 columns */}
            <div className="col-span-2">
                <Sidebar />
            </div>
            {/* Main content starting from the 3rd column */}
            <div className="col-span-10">
                <div className="grid grid-cols-12 gap-2 mb-2">
                    <div className="col-span-12 md:col-span-12">
                        <HeaderPanel message="Dashboard"  />
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mb-2">
                    <div className="col-span-12 md:col-span-6">
                        <AnalyticBarChart />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <VisitorsOverviewChart />
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 lg:col-span-8">
                        <SubscribersDetails />
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                        <DashboardCard />
                    </div>
                    {/* Assuming you want to leave the last two columns empty or for other content */}
                </div>
            </div>
        </div>

    );
}

export default Dashboard;
