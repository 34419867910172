import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import assets from '../assets/assets'; // Update the import path as needed
import Button from './Button'; // Update the import path as needed
import TextField from './TextField'; // Update the import path as needed
import { dateFormate } from '../utils/utils'; // Update the import path as needed
import { SingleBlogSkeleton } from './BlogPageSkelton'; // Update the import path as needed
import { primaryMain } from '../theme/GlobalVariables';


const SinglePostCard = ({
  data,
  error,
  password,
  setPassword,
  onClickToCheckPassword,
  loading,
}) => {
  const tags = data?.tags;
  return (
    <React.Fragment>
      {loading ? (
        <SingleBlogSkeleton />
      ) : (
        <React.Fragment>
          {error?.message?.length > 0 ? (
            <Grid container spacing={3} mb='150px' mt='120px' rowGap={'100px'}>
              <Box textAlign='center' width='100%'>
                <Typography variant='h3' color='text.primary'>
                  Please Enter Password
                </Typography>
                <Box
                  display='flex'
                  alignItems='start'
                  gap='20px'
                  width='50%'
                  pt='30px'
                  m='auto'
                >
                  <TextField
                    placeholder='Enter Password'
                    name='password'
                    type='text'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    error={Boolean(error?.message[0]?.Password)}
                    helperText={error?.message[0]?.Password}
                    showAndronment={true}
                  />
                  <Box mt='4px'>
                    <Button
                      variant='contained'
                      onClick={onClickToCheckPassword}
                      disabled={password === '' ? true : false}
                    >
                      Check
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Box>
              <Typography
                variant='subtitle2'
                color='primaryText'
                textAlign='center'
              >
                {dateFormate(data?.date)}
              </Typography>
              <Typography
                variant='h3'
                color='primaryText'
                textAlign='center'
              >
                {data?.title}
              </Typography>
              <Box
                display='flex'
                gap='8px'
                flexWrap='wrap'
                mt='10px'
                justifyContent='center'
              >
                {tags?.map((tag, index) => (
                  <Typography
                    fontSize='13px'
                    color='primaryText'
                    border={`2px solid ${primaryMain}`}
                    width='max-content'
                    padding='2px 10px'
                    borderRadius={'17px'}
                    key={index}
                  >
                    {tag}
                  </Typography>
                ))}
              </Box>
              <Box width='100%' mt='30px'>
                {data?.featuredImage !== null ? (
                  <img
                    src={data?.featuredImage}
                    alt='Blog Thumbnail'
                    style={{
                      width: '100%',
                      height: '360px',
                      objectFit: 'cover',
                      borderRadius: '12px',
                    }}
                  />
                ) : (
                  <img
                    src={assets.dummy} // Ensure correct path
                    alt='Blog Thumbnail'
                    style={{
                      width: '100%',
                      height: '360px',
                      objectFit: 'cover',
                      borderRadius: '12px',
                    }}
                  />
                )}
              </Box>
              <Box
                maxWidth='730px'
                m='auto'
                mt='40px'
                mb='40px'
                dangerouslySetInnerHTML={{ __html: data?.description }}
                sx={{
                  div: {
                    fontSize: '16px',
                    lineHeight: '26px',
                    color: 'primaryText',
                  },
                  img: {
                    width: '100% !important',
                  },
                  button: {
                    display: 'none !important',
                  },
                  a: {
                    color: 'primaryText',
                    textDecoration: 'none',
                  },
                  video: {
                    width: '100%',
                  },
                  '& .heading': {
                    color: 'primaryText',
                  },
                }}
              />
            </Box>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SinglePostCard;
