import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'; 
import {LexaLogo} from "../../staticData/icons";
import rightImg from "../../assets/rightImg.png";



function Header() {
    const [showHome, setShowHome] = useState(true);
    const location = useLocation();
    const [linkBase, setLinkBase] = useState('/');  // State to control the base URL of the links


    useEffect(() => {
        const currentPath = location.pathname;
        const isBlogsView = currentPath.startsWith("/blogs-view") || currentPath.match(/^\/blogs\/.+$/);
    
        if (isBlogsView) {
            setShowHome(false); 
            setLinkBase('/');  // All links will redirect to the home page

        } else {
            setShowHome(true);
            setLinkBase('');  // All links will redirect to the home page

        }
    }, [location.pathname]);
    return (
      <div>
        <div className='header p-3 '>
          <div className='container-fluid'>
            <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between'>
              <div className='col-md-3 mb-2 mb-md-0'>
                <a
                  href='/'
                  className='d-inline-flex link-body-emphasis text-decoration-none'
                >
                  {LexaLogo}
                </a>
              </div>

              <ul className='nav col-12 col-md-auto mb-2 justify-content-center mb-md-0'>
                <li>
                  <a href={linkBase} className='nav-link px-2 link-active'>
                    Home
                  </a>
                </li>
                <li>
                  <a href={`${linkBase}#About`} className='nav-link px-2'>
                    About
                  </a>
                </li>
                <li>
                  <a href={`${linkBase}#Features`} className='nav-link px-2'>
                    Features
                  </a>
                </li>
                <li>
                  <a href={`${linkBase}#Services`} className='nav-link px-2'>
                    Benefits
                  </a>
                </li>
                <li>
                  <a
                    href={`${linkBase}#Testimonials`}
                    className='nav-link px-2'
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <a href={`${linkBase}#Pricing`} className='nav-link px-2'>
                    Pricing
                  </a>
                </li>
                <li>
                  <a href='/blogs-view' className='nav-link px-2'>
                    Blogs
                  </a>
                </li>{' '}
                {/* This will always go to blogs */}
                {/* <li>
                  <a href={`${linkBase}#InfoSection`} className='nav-link px-2'>
                    Contact
                  </a>
                </li> */}
              </ul>

              <div className='col-md-3 main-btn md:text-end xs:text-center'>
                <Link
                  to='/login'
                  type='button'
                  className='btn btn-outline-light me-2'
                >
                  Sign In
                </Link>
                <Link
                  to='/register'
                  type='button'
                  className='btn btn-outline-light me-2'
                >
                  Sign Up
                </Link>
              </div>
            </header>
          </div>
          {showHome && (
            <section id='Home'>
              <div className='intro-section mt-80'>
                <div className='container-fluid px-0'>
                  <div className='row '>
                    <div className='col-md-5'>
                      <div data-aos='fade-right'>
                        <div className='heading'>
                          <h1>
                            PAKISTAN's NO. 1 <br /> AI Legal ASSISTANT
                          </h1>
                          <p>
                            "Lexa is reshaping legal consultation in Pakistan,
                            offering instantaneous AI-assisted guidance.
                            Designed with precision for legal professionals and
                            law students. Embrace the future of legal expertise
                            with Lexa, your 24/7 digital legal assistant.”
                          </p>
                        </div>
                        <div className='my-btn'>
                          <Link
                            to='/login'
                            type='button'
                            className='intro-btn '
                          >
                            Get started
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-7 p-4'>
                      <div className='right-img mt-3'>
                        <img src={rightImg} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    )
}

export default Header