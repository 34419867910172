import React from 'react'

const LeftArrowIcon = ({ className }) => {
  return (
    <React.Fragment>
      <svg
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          d='M9.59814 20.2625L17.0634 12.7972L9.59814 5.33203'
          stroke='black'
          stroke-width='3'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </React.Fragment>
  )
}

export default LeftArrowIcon
