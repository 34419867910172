import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const Modal = ({ open, onClose, title, minWidth, children, padding }) => {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} maxWidth='lg'>
        <Box
          display='flex'
          justifyContent='space-between'
          minWidth={minWidth ? minWidth : '400px'}
          paddingX={padding ? padding : '10px'}
          alignItems='center'
          width='100%'
        >
          <div className='flex gap-1 items-center'>
            <p className='text-[16px] mt-3'>{title}</p>
          </div>
          <IconButton aria-label='close' onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <Box padding='10px'>{children}</Box>
      </Dialog>
    </React.Fragment>
  )
}

export default Modal
