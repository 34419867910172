// Library Imports
import React from 'react';
import { Box, Typography } from '@mui/material';

// Local Imports - Adjust these imports according to your React project's structure
import assets from '../assets/assets'; // Update this path
import { primaryMain } from '../theme/GlobalVariables'; // Update this path
import styles from '../styles/home.module.css'; // Update this path
import { dateFormate } from '../utils/utils'; // Update this path

const BlogCard = ({
  date,
  blogName,
  description,
  image,
  tags,
  className,
  onClick,
  showThumbnail,
}) => {
  return (
    <React.Fragment>
      <Box
        className={className ? className : styles.blog}
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
        style={{
          maxWidth: '68%',
          minWidth: '45%',
        }}
      >
        <Box borderRadius='12px' height='max-content'>
          {image && image !== null ? (
            <img
              src={image}
              alt='Blog Thumbnail'
              style={{ 
                margin:'5px 12px',
                width: '100%',
                objectFit: 'cover',
                height: '210px',
                borderRadius: '14px',
              }}
            />
          ) : showThumbnail ? (
            <img
              src={assets.dummy} // Make sure 'assets.dummy' points to a valid image
              alt={blogName}
              style={{
                width: '100%',
                height: '233px',
                objectFit: 'cover',
                borderRadius: '14px',
                border: '1px solid white',
              }}
            />
          ) : null}
        </Box>
        <Typography fontSize='12px' color='primaryText' mt='4px'>
          {dateFormate(date)}
        </Typography>
        <Typography fontSize='15px' color='primaryText'>
          {blogName}
        </Typography>
        <Box
          fontSize='13px'
          color='primaryText'
          style={{ overflowWrap: 'break-word' }} // To handle text overflow
          dangerouslySetInnerHTML={{ __html: description }} // Be cautious using dangerouslySetInnerHTML due to potential XSS vulnerabilities
        />
        <Box display='flex' gap='8px' flexWrap='wrap' mt='6px'>
          {tags.map((tag, index) => (
            <Typography
              fontSize='13px'
              color='primaryText'
              style={{
                border: `2px solid ${primaryMain}`,
                width: 'max-content',
                padding: '2px 10px',
                borderRadius: '17px',
              }}
              key={index}
            >
              {tag}
            </Typography>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BlogCard;
