import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Changed from useRouter to useNavigate
import BlogCard from './BlogCard';
import styles from '../styles/home.module.css';
import { truncateString } from '../utils/utils';

const RelatedPosts = ({ name, description, blogs }) => {
  const navigate = useNavigate(); // Changed from useRouter to useNavigate

  const onClickToRedirect = (blog) => {
    const endURL = blog?.slug || blog?.end_url; 
    navigate(`/blogs-view/${endURL}`); 
};

  return (
    <React.Fragment>
      <Grid container mb="120px">
        <Box>
          <Typography variant="h6" color="text.primary">
            {name}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </Box>
        <Grid item lg={12} md={12} xs={12}>
          <Box className={styles.blogRow} mt="20px">
            {blogs.map((blog) => {
              let description = blog.short_description.replace(/<[^>]*>/g, '');
              return (
                <BlogCard
                  key={blog.uid}
                  date={blog.created_at}
                  blogName={blog.title}
                  description={truncateString(description, 20)}
                  image={blog.featured_image}
                  tags={blog.tags}
                  onClick={() => onClickToRedirect(blog)}
                  showThumbnail={true}
                />
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RelatedPosts;
