import React from 'react';
import { Button as LButton, CircularProgress } from '@mui/material';
// import { disabledBTNText } from './GlobalVariables'; // Uncomment and update the import path as needed

const Button = ({
  variant,
  children,
  color,
  startIcon,
  width,
  onClick,
  endIcon,
  style,
  padding,
  height,
  radius,
  minHeight,
  minWidth,
  disabled,
  loading,
  className,
  onKeyDown,
  ...restProps
}) => {
  return (
    <React.Fragment>
      <LButton
        sx={{
          borderRadius: radius ? radius : '8px',
          textTransform: 'none',
          height: height ? height : '44px',
          width: width ? width : '120px',
          fontSize: '14px',
          padding: padding ? padding : 'unset',
          minWidth: minWidth ? minWidth : '44px',
          minHeight: minHeight ? minHeight : '44px',
          // cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        size='small'
        variant={variant}
        color={color}
        startIcon={startIcon}
        onClick={onClick}
        endIcon={endIcon}
        style={style}
        disabled={disabled}
        className={className}
        {...restProps}
      >
        {loading ? (
          <CircularProgress
            size={20}
            color={
              disabled
                ? 'primary'
                : variant === 'outlined'
                ? 'primary'
                : 'secondary'
            }
          />
        ) : (
          children
        )}
      </LButton>
    </React.Fragment>
  );
};

export default Button;
