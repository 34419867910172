import * as React from 'react'
const LockIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.99984 10V11.3333M3.99984 14H11.9998C12.7362 14 13.3332 13.403 13.3332 12.6667V8.66667C13.3332 7.93029 12.7362 7.33333 11.9998 7.33333H3.99984C3.26346 7.33333 2.6665 7.93029 2.6665 8.66667V12.6667C2.6665 13.403 3.26346 14 3.99984 14ZM10.6665 7.33333V4.66667C10.6665 3.19391 9.4726 2 7.99984 2C6.52708 2 5.33317 3.19391 5.33317 4.66667V7.33333H10.6665Z'
      stroke='#4F4F4F'
      strokeWidth={1.2}
      strokeLinecap='round'
    />
  </svg>
)
export default LockIcon
