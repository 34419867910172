// Library Imports
import React from 'react';
import { useSelector } from 'react-redux';

//Local Imports
import PreviewLayout from '../blogComponent/PreviewLayout/PreviewLayout';
import BlogPreviewPage from './BlogPreviewPage';

const PagePreview = () => {
  const state = useSelector((state) => state?.previewData?.previewData);
  // console.log("blogs state:",state)
  return (
    <PreviewLayout>
      {state?.from === "blog" ? (
          <BlogPreviewPage data={ state }/>
        ) : null}
    </PreviewLayout>
  );
};
export default PagePreview;
