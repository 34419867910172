const primaryMain = '#21808D';
const secondaryMain = '#1E1F26';
const secondaryLight = '#14141B';
const primaryText = '#FFFFFF';
const secondaryText = '#8E9091';
const dividerClr = '#2F313C';
const bgColor = '#0f1217';
const subtitleClr = '#191A1A'; // Note: Fixed a typo here from 'subitleClr' to 'subtitleClr'
const disabledBTNBackground = 'rgb(27, 27, 35)';
const disabledBTNText = 'rgb(133, 135, 141)';
const faqBgColor = '#22242C';
const positionPage = 'static';
const paginationColor = '#FF0000';
const paginationBgColor = '#21808D';

export {
  primaryMain,
  secondaryMain,
  secondaryLight,
  primaryText,
  secondaryText,
  dividerClr,
  bgColor,
  subtitleClr, // Make sure to update the export if you corrected the typo
  disabledBTNBackground,
  disabledBTNText,
  faqBgColor,
  positionPage,
  paginationColor,
  paginationBgColor,
};
