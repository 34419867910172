// Library Imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box} from '@mui/material';

// Local Imports
import SinglePostCard from '../BlogSite/blogs/shared/SinglePostCard';
import RelatedPostSection from './RelatedPostSection';
import ApiController from '../BlogSite/utils/network/Api/api';
import Header from '../langingPage/Header';
import BlogsFooter from '../langingPage/BlogsFooter';

const SinglePostSection = () => {
  // Use React Router hooks for navigation and parameters
  const navigate = useNavigate();
  const { slug } = useParams(); // The route parameter should match the one defined in your <Route path="/posts/:slug" /> in App.js
  const uid = slug; // Extract the post ID or slug from the URL

  // STATE & METHODS
  const [loading, setLoading] = useState(true);
  const [relatedPostLoading, setRelatedPostLoading] = useState(true);
  const [relatedPostData, setRelatedPostData] = useState([]);
  const [singleBlogData, setSingleBlogData] = useState({});
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});

  function fnToSetURLs(html, originalURls) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const images = doc.querySelectorAll('img');
    const videos = doc.querySelectorAll('video');
    const imgURLs = originalURls?.filter(
      (img) => img.featured !== 'featured' && !img?.is_video
    );
    const videoURLs = originalURls?.filter((video) => video?.is_video);

    images.forEach((img, index) => {
      let url;
      url = imgURLs[index]?.url;
      img.src = url || null;
    });
    videos.forEach((video, index) => {
      let url;
      url = videoURLs[index]?.url;
      video.src = url || '';
      video.id = videoURLs[index]?.id;
      video.autoplay = videoURLs[index]?.autoplay;
      video.loop = videoURLs[index]?.loop;
      video.muted = videoURLs[index]?.muted;
      video.playsInline = videoURLs[index]?.play_inline;
      video.controls = videoURLs[index]?.playback_controls;
    });
    return doc.body.innerHTML;
 }

  
  // FETCH SINGLE BLOG PAGE
  const fetchSingleBlogData = () => {
    setLoading(true);
    ApiController.fetchSingleBlogPageCall({ uid, password }, async (response) => {
      if (response.success) {
        const data = response?.data;
        const featureImageURL = data?.blog_images?.find(
          (img) => img?.featured === 'featured'
        )?.url;
        const description = await fnToSetURLs(
          data?.description,
          data?.blog_images
        );
        setSingleBlogData({
          title: data?.title,
          featuredImage: featureImageURL ? featureImageURL : null,
          tags: data?.tags,
          date: data?.created_at,
          description: description,
        });
        setError({});
        setLoading(false);
      } else {
        setError(response?.data);
        setLoading(false);
      }
    });
  };

  // ... (rest of your code)

  useEffect(() => {
    if (uid) {
      fetchSingleBlogData();
    }
  }, [uid]);

  useEffect(() => {
    if (uid) { // Ensure uid is present before making the API call
      fetchSingleBlogData();
    }
  }, [uid]);
  useEffect(() => {
    if (singleBlogData?.tags?.length > 0) {
      const tags = singleBlogData.tags;
      ApiController.fetchBlogsListPageCall({ tags }, (response) => {
        if (response.success) {
          setRelatedPostData(response.data);
        } else {
          setRelatedPostData([]);
        }
        setRelatedPostLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleBlogData?.tags]);


  return (
    <div className='bg-black'>
      <Header/>
    <React.Fragment>
      <Box sx={{color: 'white', padding: '60px' }}>

        <SinglePostCard
          error={error}
          password={password}
          setPassword={setPassword}
          onClickToCheckPassword={fetchSingleBlogData}
          loading={loading}
          data={singleBlogData}
        />
        {!error.message && (
          <RelatedPostSection
            name="Related Posts"
            loading={relatedPostLoading}
            data={relatedPostData}
          />
        )}
      </Box>


    </React.Fragment>
    <BlogsFooter relatedPosts={relatedPostData} />
    </div>
  );
};

export default SinglePostSection;
