import React, { createContext, useReducer,  } from 'react'
import AppReducer from './AppReducer'

const initialState = {
  userInfo: null,
  chatData: [],
  selectChatID: null,
  chatHistory: [],
  chatLoading: false,
  chatLoadingId: null,
  sQuery: '',
  currentRecievingChat: '',
  recievedChatData:'',
  eocReceived: false,
  nextRecievingChat: '',
  judgementchatLoading: false,
  errorMessage: null,
  judgementErrorMessage: null,
  inputQuery: '',
  judgementInputQuery: null,
  suggestionQuery: '',
  sendBtnLoading: false,
  judgementSendBtnLoading: false,
  showSidebar: false,
  queryType: 'General_Queries',
  paymentPlan: false,
  blurBG: true,
  isAnnualClicked: false,
  selectedTab: 'ChatBot',
  isSelectResponsiveTab: 'Chat',
  judgementChatData: [],
  themeToggle: false,
  isChatViewVisible: true,
  selectedCategory: 'law_gat',
  quizCorrectAnswersCount: 0,
  quizAttemptedQuestions: 0,
  isAwaitingResponse: false,
  finalJudgementData: [],
  selectedDocument: null,
  isLoading: true,
  isResponseJudgmentAi: false,
  activeMcqs: 'MCQs',
  authUser: null,
  previewData: {},
  states: null,
  isSubscriptionActive: true,
  advancedSearchCriteria: null,
  isSearchActive: false,
  lastSearch: null,
  advanceQuery: '',
  isChatDataLoading: false,
  userQuerySubmitted: false,
  totalJudgements: 0,
  pageSize: 10,
  currentPage: 1,
  recaptchaToken: '',
  isOnTrial: true,
  judgementChatData: [],
  isJudgmentSocketConnecting: true, // Add this state
  SOFData: '', // Add this line
    plans: [], // Add this line

}

const GlobalContext = createContext(initialState)

export default GlobalContext

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const setUserInfo = (userInfo) => {
    dispatch({
      type: 'SET_USER_INFO',
      payload: userInfo,
    })
  }
  const setSelectChatID = (selectChatID) => {
    dispatch({
      type: 'SET_SELECT_CHAT_ID',
      payload: selectChatID,
    })
  }
  const setChatHistory = (chatHistory) => {
    dispatch({
      type: 'SET_CHAT_HISTORY',
      payload: chatHistory,
    })
  }
  const setIsAnnualClicked = (isAnnualClicked) => {
    dispatch({
      type: 'SET_ANNUAL_CLICKED',
      payload: isAnnualClicked,
    })
  }
  const setPaymentPlan = (paymentPlan) => {
    dispatch({
      type: 'SET_PAYMENT_PLAN',
      payload: paymentPlan,
    })
  }
  const setBlurBG = (blurBG) => {
    dispatch({
      type: 'SET_BLUR_BG',
      payload: blurBG,
    })
  }
  const setChatData = (chatData) => {
    dispatch({
      type: 'SET_CHAT_DATA',
      payload: chatData,
    })
  }
  const setCurrentReply = (reply) => {
    if (reply)
      dispatch({
        type: 'SET_CURRENT_REPLY',
        payload: reply,
      })
  }
  const setThemeToggle = (themeToggle) => {
    dispatch({
      type: 'SET_THEME_TOGGLE',
      payload: themeToggle,
    })
  }
  const setJudgementChatData = (judgementChatData) => {
    dispatch({
      type: 'SET_JUDGEMENT_CHAT_DATA',
      payload: judgementChatData,
    })
  }

  const setQueryType = (queryType) => {
    dispatch({
      type: 'SET_QUERY_TYPE',
      payload: queryType,
    })
  }
  const setSelectedTab = (selectedTab) => {
    dispatch({
      type: 'SET_SELECTED_TAB',
      payload: selectedTab,
    })
  }
  const setSelectResponsiveTab = (isSelectResponsiveTab) => {
    dispatch({
      type: 'SET_SELECT_RESPONSIVE_TABE',
      payload: isSelectResponsiveTab,
    })
  }
  const setChatLoading = (chatLoading) => {
    dispatch({
      type: 'SET_CHAT_LOADING',
      payload: chatLoading,
    })
  }
  const setChatLoadingId = (chatLoadingId) => {
    dispatch({
      type: 'SET_CHAT_LOADING_ID',
      payload: chatLoadingId,
    })
  }
  const setJudgementChatLoading = (judgementchatLoading) => {
    dispatch({
      type: 'SET_JUDGEMENT_CHAT_LOADING',
      payload: judgementchatLoading,
    })
  }

  const setShowSidebar = (showSidebar) => {
    dispatch({
      type: 'SET_SHOW_SIDEBAR',
      payload: showSidebar,
    })
  }
  const setErrorMessage = (errorMessage) => {
    dispatch({
      type: 'SET_ERROR_MESSAGE',
      payload: errorMessage,
    })
  }
  const setJudgementErrorMessage = (judgementErrorMessage) => {
    dispatch({
      type: 'SET_JUDGEMENT_ERROR_MESSAGE',
      payload: judgementErrorMessage,
    })
  }
  const setInputQuery = (inputQuery) => {
    dispatch({
      type: 'SET_INPUT_QUERY',
      payload: inputQuery,
    })
  }
  const setJudgementInputQuery = (judgementInputQuery) => {
    dispatch({
      type: 'SET_JUDGEMENT_INPUT_QUERY',
      payload: judgementInputQuery,
    })
  }


  const setSuggestionQuery = (suggestionQuery) => {
    dispatch({
      type: 'SET_SUGGESTION_QUERY',
      payload: suggestionQuery,
    })
  }

  const setSendBtnLoading = (sendBtnLoading) => {
    dispatch({
      type: 'SET_SEND_BTN_LOADING',
      payload: sendBtnLoading,
    })
  }
  const setJudgementSendBtnLoading = (judgementSendBtnLoading) => {
    dispatch({
      type: 'SET_JUDGEMENT_SEND_BTN_LOADING',
      payload: judgementSendBtnLoading,
    })
  }
  const clearChatData = () => {
    dispatch({
      type: 'CLEAR_CHAT_DATA',
    })
  }
  const setChatViewVisibility = (isChatViewVisible) => {
    dispatch({
      type: 'SET_CHAT_VIEW_VISIBILITY',
      payload: isChatViewVisible,
    })
  }
  const setSearchPost = (sQuery) => {
    dispatch({
      type: 'SET_SEARCH_QUERY',
      payload: sQuery,
    })
  }
  const setSelectedCategory = (category) => {
    dispatch({
      type: 'SET_SELECTED_CATEGORY',
      payload: category,
    })
  }

  const setCorrectAnswersCount = (count) => {
    dispatch({ type: 'SET_CORRECT_ANSWERS_COUNT', payload: count })
  }

  const setAttemptedQuestions = (count) => {
    dispatch({ type: 'SET_ATTEMPTED_QUESTIONS', payload: count })
  }

  const setActiveMcqs = (activeMcqs) => {
    dispatch({
      type: 'SET_ACTIVE_MCQS',
      payload: activeMcqs,
    })
  }
  const setIsAwaitingResponse = (isAwaiting) => {
    dispatch({
      type: 'SET_AWAITING_RESPONSE',
      payload: isAwaiting,
    })
  }
  const toggleDarkMode = () => {
    dispatch({ type: 'TOGGLE_DARK_MODE' })
  }
  const setSystemTheme = (theme) => {
    dispatch({
      type: 'SET_SYSTEM_THEME',
      payload: theme,
    })
  }
  const setFinalJudgementData = (judgement) => {
    dispatch({
      type: 'SET_FINAL_JUDGMENT_DATA',
      payload: judgement,
    })
  }
  const setSelectedDocument = (document) => {
    dispatch({
      type: 'SET_SELECTED_DOCUMENT',
      payload: document,
    });
  };
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'SET_LOADING',
      payload: isLoading,
    });
  };
  const setIsresponseJudgmentAi = (isResponseJudgmentAi) => {
    dispatch({
      type: 'SET_IS_RESPONSE_JUDGMENT_AI',
      payload: isResponseJudgmentAi,
    });
  };

  const setAuthUser = (user) => {
    dispatch({
      type: 'SET_AUTH_USER',
      payload: user
    });
  };

  const setPreviewData = (previewData) => {
    dispatch({
      type: 'SET_PREVIEW_DATA',
      payload: previewData
    });
  };

  const setStates = (states) => {
    dispatch({
      type: 'SET_STATES',
      payload: states
    });
  };

  const setSubscriptionActive = (isActive) => {
    dispatch({
      type: 'SET_SUBSCRIPTION_ACTIVE',
      payload: isActive,
    });
  };
  const setAdvancedSearchCriteria = (criteria) => {
    dispatch({
      type: 'SET_ADVANCED_SEARCH_CRITERIA',
      payload: criteria,
    });
  };

  const clearAdvancedSearchCriteria = () => {
    dispatch({
      type: 'CLEAR_ADVANCED_SEARCH_CRITERIA',
    });
  };
  const setIsSearchActive = (isActive) => {
    dispatch({
      type: 'SET_IS_SEARCH_ACTIVE',
      payload: isActive,
    });
  };
  const setLastSearch = (isActive) => {
    dispatch({
      type: 'SET_LAST_SEARCH',
      payload: isActive,
    });
  };
  const setAdvanceQuery = (advanceQuery) => {
    dispatch({
      type: 'SET_ADVANCE_QUERY',
      payload: advanceQuery,
    })
  }
  const setIsChatDataLoading = (isChatDataLoading) => {
    dispatch({
      type: 'SET_IS_CHAT_DATA_LOADING',
      payload: isChatDataLoading,
    });
  };
  const setQuerySubmission = (submitted) => {
    dispatch({
      type: 'SET_USER_QUERY_SUBMITTED',
      payload: submitted,
    });
  };
  const setTotalJudgements = (total) => {
    dispatch({
      type: 'SET_TOTAL_JUDGEMENTS',
      payload: total,
    });
  }

  const setPageSize = (size) => {
    dispatch({
      type: 'SET_PAGE_SIZE',
      payload: size,
    });
  }

  const setCurrentPage = (page) => {
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: page
    });
  };
  const setRecaptchaToken = (token) => {
    dispatch({
      type: 'SET_RECAPTCHA_TOKEN',
      payload: token,
    });
  };

  const clearRecaptchaToken = () => {
    dispatch({
      type: 'CLEAR_RECAPTCHA_TOKEN',
    });
  };
  const setIsOnTrial = (isTrial) => {
    dispatch({
      type: 'SET_ON_TRIAL',
      payload: isTrial,
    });
  };
  const clearJudgementChatData = () => {
    dispatch({
      type: 'CLEAR_JUDGEMENT_CHAT_DATA',
    });
  };

  const setJudgmentSocketConnecting = (isConnecting) => {
    dispatch({
      type: 'SET_JUDGMENT_SOCKET_CONNECTING',
      payload: isConnecting,
    });
  };
   const setEOCReceived = (isEOCReceived) => {
     dispatch({
       type: 'SET_EOC_RECEIVED',
       payload: isEOCReceived,
     })
   }
   const setSOFData = (data) => {
    dispatch({
      type: 'SET_SOF_DATA',
      payload: data,
    });
  };
    const handleNewMessage = (newMessage) => {
      dispatch({ type: 'SET_CURRENT_REPLY', payload: newMessage })
    }
    const setPlans = (plans) => {
      dispatch({
        type: 'SET_PLANS',
        payload: plans,
      })
    }
  return (
    <GlobalContext.Provider
      value={{
        setEOCReceived, // Add this line
        userInfo: state.userInfo,
        setUserInfo,
        chatData: state.chatData,
        setChatData,
        judgementChatData: state.judgementChatData,
        setJudgementChatData,

        clearChatData,
        chatLoading: state.chatLoading,
        setChatLoading,
        chatLoadingId: state.chatLoadingId,
        setChatLoadingId,
        judgementchatLoading: state.judgementchatLoading,
        setJudgementChatLoading,

        errorMessage: state.errorMessage,
        setErrorMessage,

        judgementErrorMessage: state.judgementErrorMessage,
        setJudgementErrorMessage,

        inputQuery: state.inputQuery,
        setInputQuery,
        suggestionQuery: state.suggestionQuery,
        setJudgementInputQuery,
        judgementInputQuery: state.judgementInputQuery,
        setSuggestionQuery,
        sendBtnLoading: state.sendBtnLoading,
        setSendBtnLoading,
        judgementSendBtnLoading: state.judgementSendBtnLoading,
        setJudgementSendBtnLoading,
        showSidebar: state.showSidebar,
        setShowSidebar,
        queryType: state.queryType,
        setQueryType,
        paymentPlan: state.paymentPlan,
        setPaymentPlan,
        selectedTab: state.selectedTab,
        setSelectedTab,
        blurBG: state.blurBG,
        setBlurBG,
        isAnnualClicked: state.isAnnualClicked,
        setIsAnnualClicked,
        themeToggle: state.themeToggle,
        setThemeToggle,
        setChatViewVisibility,
        isChatViewVisible: state.isChatViewVisible,
        setSelectResponsiveTab,
        isSelectResponsiveTab: state.isSelectResponsiveTab,
        setSearchPost,
        sQuery: state.sQuery,
        setSelectedCategory,
        selectedCategory: state.selectedCategory,
        quizCorrectAnswersCount: state.quizCorrectAnswersCount,
        setCorrectAnswersCount,
        quizAttemptedQuestions: state.quizAttemptedQuestions,
        setAttemptedQuestions,
        activeMcqs: state.activeMcqs,
        setActiveMcqs,
        chatHistory: state.chatHistory,
        setChatHistory,
        selectChatID: state.selectChatID,
        setSelectChatID,
        currentRecievingChat: state.currentRecievingChat,
        recievedChatData: state.recievedChatData,
        setCurrentReply,
        setIsAwaitingResponse,
        isAwaitingResponse: state.isAwaitingResponse,
        toggleDarkMode,
        setSystemTheme,
        setFinalJudgementData,
        finalJudgementData: state.finalJudgementData,
        selectedDocument: state.selectedDocument,
        setSelectedDocument,
        setIsLoading,
        isLoading: state.isLoading,
        setIsresponseJudgmentAi,
        isResponseJudgmentAi: state.isResponseJudgmentAi,
        user: state.user,
        setAuthUser,
        previewData: state.previewData,
        setPreviewData,
        states: state.states,
        setStates,
        isSubscriptionActive: state.isSubscriptionActive,
        setSubscriptionActive,
        setAdvancedSearchCriteria,
        advancedSearchCriteria: state.advancedSearchCriteria,
        clearAdvancedSearchCriteria,
        setIsSearchActive,
        isSearchActive: state.isSearchActive,
        setLastSearch,
        lastSearch: state.lastSearch,
        setAdvanceQuery,
        advanceQuery: state.advanceQuery,
        isChatDataLoading: state.isChatDataLoading,
        setIsChatDataLoading,
        userQuerySubmitted: state.userQuerySubmitted,
        setQuerySubmission,
        totalJudgements: state.totalJudgements,
        pageSize: state.pageSize,
        setTotalJudgements,
        setPageSize,
        setCurrentPage,
        currentPage: state.currentPage,
        recaptchaToken: state.recaptchaToken,
        setRecaptchaToken,
        clearRecaptchaToken,
        isOnTrial: state.isOnTrial,
        setIsOnTrial,
        judgementChatData: state.judgementChatData,
        clearJudgementChatData,
        isJudgmentSocketConnecting: state.isJudgmentSocketConnecting,
        setJudgmentSocketConnecting,
        SOFData: state.SOFData,
        setSOFData, // Add this line
        ...state, handleNewMessage,
        setPlans,
        plans:state.plans,

      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
