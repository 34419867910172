export const summaryKeyWords = [
  'Rulings',
  'Decisions',
  'Verdicts',
  'Decrees',
  'Orders',
  'Findings',
  'Resolutions',
  'Adjudications',
  'Opinions',
  'Precedents',
  'Jurisprudence',
  'Legal determinations',
  'Judicial pronouncements',
  'Magisterial orders',
  'Court rulings',
  'Judicial decisions',
  'Legal judgments',
  'Adjudged cases',
  'Judicial findings',
  'Court determinations',
  'judgements',
  'case laws',
  'case',
  'cases',
]