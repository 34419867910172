import React from "react";

function FormErrors(props) {
  return (
    <>
      <p className="text-red-600 mb-0 md:text-[10px] 2xl:text-[14px] 3xl:text-xl 4xl:text-[35px] mt-[5px]">
        {props.error}
      </p>
    </>
  );
}

export default FormErrors;
