import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('token')

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

function bearerAuth(token) {
  return `Bearer ${token}`
}

const setupInterceptors = (navigate, dispatch) => {
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { message } = error?.response?.data
        if (message === 'Given token not valid for any token type') {
          dispatch({ type: 'LOGOUT' })
          navigate('/login')
        }
      }
      return Promise.reject(error)
    }
  )
}

function login(values) {
  return API.post(`/user/login`, {
    email: values.email,
    password: values.password,
  })
}
function register(values) {
  return API.post(`/user/register`, {
    first_name: values.firstname,
    last_name: values.lastname,
    email: values.email,
    password: values.password,
  })
}
function forgotPassword(values) {
  return API.post(`/user/send-reset-password-email`, {
    email: values.email,
  })
}
function resetPassword(email) {
  return API.post(`/user/reset-password`, {
    email: email,
  })
}
function otpVerify(email, otp) {
  return API.post(`/user/register/validate-otp`, { email: email, otp: otp })
}
function profile(token) {
  return API.get(`/user/profile`, {
    headers: { Authorization: bearerAuth(token) },
  })
}
function resetEmailPassword(values, token, uid) {
  return API.post(`/user/reset-password/${uid}/${token}`, {
    password: values.password,
    confirm_password: values.confirm_password,
  })
}
function googleLogin(access_token) {
  return API.post(`/user/google-login`, {
    access_token: access_token,
  })
}
function resendOTP(values) {
  return API.post(`/user/register/resend-otp`, {
    email: values.email,
  })
}

function quiz(access_token, value) {
  return API.get(`/quiz/${value}/`, {
    headers: { Authorization: bearerAuth(access_token) },
  })
}
function statute(access_token, value) {
  return API.get(`/bot/get-statues`, {
    // Ensure there's a slash before the query parameter
    headers: { Authorization: bearerAuth(access_token) },
  })
}
function draft(access_token, value) {
  return API.get(`/bot/get-drafts`, {
    // Ensure there's a slash before the query parameter
    headers: { Authorization: bearerAuth(access_token) },
  })
}
function statuteSearch(access_token, query) {
  // Default to an empty string if value is not provided
  return API.get(`/bot/get-statues?search=${query}`, {
    // Ensure there's a slash before the query parameter
    headers: { Authorization: bearerAuth(access_token) },
  })
}

function statutePagination(access_token, url) {
  // Default to an empty string if value is not provided
  return API.get(`${url}`, {
    // Ensure there's a slash before the query parameter
    headers: { Authorization: bearerAuth(access_token) },
  })
}
function paymentHistory(access_token) {
  // Default to an empty string if value is not provided
  return API.get(`/payment/history/`, {
    // Ensure there's a slash before the query parameter
    headers: { Authorization: bearerAuth(access_token) },
  })
}
function cardPayment(access_token) {
  // Default to an empty string if value is not provided
  return API.get(`/payment/cards/`, {
    // Ensure there's a slash before the query parameter
    headers: { Authorization: bearerAuth(access_token) },
  })
}

// FETCH PLANS
function fetchPlans() {
  return API.get('/payment/get-plans/')
}

// APPLY PROMOCODE
function applyPromoCode(payload) {
  return API.post(`/payment/apply_promocode/`, payload, {
    headers: { Authorization: bearerAuth(token) },
  })
}

// APPLY PROMOCODE
function applyPaymentByPaymob(payload) {
  return API.post(`/payment/paymob/`, payload, {
    headers: { Authorization: bearerAuth(token) },
  })
}

// ADD ORGANIZATION USERS
function getOrganizationUsers() {
  return API.get('/user/org/users', {
    headers: { Authorization: bearerAuth(token) },
  })
}

// ADD NEW USER
function addNewOrganizationUser(payload) {
  return API.post('/user/org/users', payload, {
    headers: { Authorization: bearerAuth(token) },
  })
}

// DELET USER
function deleteOrganizationUser(payload) {
  return API.delete(`/user/org/delete/${payload?.user_id}`, {
    headers: { Authorization: bearerAuth(token) },
  })
}

export const Api = {
  login,
  register,
  forgotPassword,
  resetPassword,
  otpVerify,
  profile,
  resetEmailPassword,
  googleLogin,
  resendOTP,
  quiz,
  statute,
  draft,
  statuteSearch,
  statutePagination,
  paymentHistory,
  cardPayment,
  fetchPlans,
  applyPromoCode,
  applyPaymentByPaymob,
  getOrganizationUsers,
  addNewOrganizationUser,
  deleteOrganizationUser,
}

export { setupInterceptors, API }
