import React from 'react'
import benfiteImg from '../../assets/landingPage-imgs/clientDenning.jpg'
import clientBehriaUni from '../../assets/landingPage-imgs/clientBehriaUni.jpg'
import clientLawStudentConcile from '../../assets/landingPage-imgs/clientLawStudentConcile.jpg'
import clientUCP from '../../assets/landingPage-imgs/clientUCP.jpg'
import bgImage from '../../assets/landingPage-imgs/bg.jpg'
const Mission = () => {
  return (
    <div
      className='bg-cover bg-no-repeat py-16 text-center'
      style={{ backgroundImage: `url(${bgImage})` }}
      id='Vision'
    >
      <div className='container mx-auto px-4 text-center'>
        <h2 className='text-4xl font-bold mb-2'>Our Mission</h2>
        <div className='divider border-2 border-[#0e877d] block m-auto w-20 mb-5'></div>
        <p className='text-lg mb-8'>
          Our mission at Lexa is to revolutionize legal assistance in Pakistan
          through cutting-edge AI technology. We empower lawyers and law
          students with direct access to a comprehensive suite of legal
          resources, facilitating simplified research and study that's available
          anytime, anywhere. By harnessing AI's capabilities, we aim to enhance
          efficiency, accuracy, and accessibility in legal practices, ultimately
          reshaping the landscape of legal education and consultation in
          Pakistan.
        </p>
      </div>
      <div className='container mx-auto px-4 text-center '>
        <h2 className='text-4xl font-bold mb-2 mt-36'>Our Corporate Clients</h2>
        <div className='divider border-2 border-[#0e877d] block m-auto w-20 mb-5'></div>
        <div className='lg:flex xs:block justify-center lg:space-x-8 xs:space-x-0'>
          <img src={benfiteImg} alt='Client 1' className=' mb-4' />
          <img src={clientBehriaUni} alt='Client 2' className=' mb-4' />
          <img src={clientLawStudentConcile} alt='Client 3' className='mb-4' />
          <img src={clientUCP} alt='Client 4' className='mb-4' />
        </div>
      </div>
    </div>
  )
}

export default Mission
