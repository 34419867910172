import React from 'react'
import '../../styles/CustomModal.css'

function CustomModal({ isOpen, onClose, children,handleDownload }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-statutes " onClick={onClose}>
      <div className="modal-content-statutes" onClick={e => e.stopPropagation()}>
        {children}
        <div className=' flex justify-center content-center items-center'>

        <button className='mt-4 bg-red-500 px-3 py-2 rounded-md text-white' onClick={onClose}>Close</button>
        <button className='mt-4 bg-green-500 px-3 py-2 rounded-md ml-3 text-white' onClick={handleDownload}>Download</button>
        </div>
      </div>
    </div>
  );
}

export default CustomModal
