import React from 'react';
import RelatedPost from '../BlogSite/blogs/shared/RelatedPosts';
import { Box,Typography } from '@mui/material';
import { RelatedPostSkeleton } from '../BlogSite/blogs/shared/BlogPageSkelton';

const RelatedPostSection = ({ name, loading, data }) => {
  return (
      <React.Fragment>
        {loading ? (
          <RelatedPostSkeleton />
        ) : (
          <React.Fragment>
            {data?.length > 0 ? (
              <RelatedPost name={name} blogs={data} />
            ) : (
              <Box>
                <Typography color='text.primary'>No Related Post</Typography>
              </Box>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
  );
};

export default RelatedPostSection;
