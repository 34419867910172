/* eslint-disable no-useless-escape */
import React, { useState, useContext, useEffect } from 'react'
// import bg_image from "../../assets/background_image.png";
import bg_image from '../../assets/bg-hero.jpg'

import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import ErrorMessages from '../../staticData/errors.json'
import Input from '../../components/shared/Input'
import SubmitButton from '../../components/shared/SubmitButton'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import FormErrors from '../../components/shared/FormErrors'
import { LexaLogo } from '../../staticData/icons'
import { Api } from '../../api'
import Cookies from 'js-cookie'
import ReCAPTCHA from 'react-google-recaptcha'
import GlobalContext from '../../context/GlobalContext'

let EMAIL_REGX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/

const LoginSchema = Yup.object({
  firstname: Yup.string()
    .matches(
      /^[a-zA-Z\s]*[a-zA-Z]+[a-zA-Z\s]*$/,
      'First name must contain only English letters'
    )
    .required('Please enter your first name')
    .max(50, 'First name must not exceed 50 characters'),
  lastname: Yup.string()
    .matches(
      /^[a-zA-Z\s]*[a-zA-Z]+[a-zA-Z\s]*$/,
      'Last name must contain only English letters'
    )
    .max(50, 'Last name must not exceed 50 characters'),
  email: Yup.string()
    .required('Please enter your email')
    .matches(EMAIL_REGX, 'Invalid email address')
    .max(50, 'Email must not exceed 50 characters'),
  password: Yup.string()
    .required('Please enter your password')
    .min(6, 'Password length must be at least 6 characters long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      'Password must contain at least one alphabetic character and one special character'
    )
    .max(50, 'password must not exceed 50 characters'),
  confirm_password: Yup.string()
    .required('Please enter your confirm password')
    .oneOf([Yup.ref('password'), null], 'Password must match'),
  check: Yup.boolean().oneOf(
    [true],
    'Please accept the terms and privacy policy before get started!'
  ),
})

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirm_password: '',
  check: false,
}

function Register() {
  const Navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const { recaptchaToken, setRecaptchaToken } = useContext(GlobalContext)
  const key = process.env.REACT_APP_RECAPTCHA_KEY
  // const key = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
  const { values, errors, touched, handleBlur, handleChange, handleSubmit,resetForm } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: async (values, action) => {
        if (!recaptchaToken) {
          Swal.fire({
            icon: 'error',
            title: 'Please verify you are not a robot',
          })
          return
        }
        setLoading(true)
        await Api.register(values)
          .then((response) => {
            setLoading(false)
            Cookies.set('email', values.email)
            action.resetForm()
            Navigate(`/verify-otp/${values.email}`)
          })
          .catch((error) => {
            setLoading(false)
            const errorMessage = getErrorMessage(error.response.status)
            if (error.response.status === 400) {
              setEmailError(true)
            } else if (errorMessage) {
              Swal.fire({
                icon: 'error',
                text: errorMessage,
                customClass: {
                  confirmButton: 'custom-confirm-button',
                },
              })
            }
          })
      },
      
    })
    useEffect(() => {
      resetForm({
          values: { 
              ...initialValues, // spread existing initial values
              email: '',         // reset email to empty
              password: ''       // reset password to empty
          }
      });
  }, [resetForm]);
  
  const onChangeRecaptcha = (token) => {
    setRecaptchaToken(token)
  }
  return (
    <>
      <div
        className='h-[100vh] bg-no-repeat bg-cover bg-center flex justify-center items-center'
        style={{ backgroundImage: `url(${bg_image})` }}
      >
        <div
          className='login_box xs:w-[95%] sm:w-[75%] md:w-[60%] lg:w-[45%] xl:w-[35%] 2xl:w-[40%] 3xl:w-[40%] flex flex-col md:py-[10px] 2xl:py-[10px] justify-between items-center
           rounded-[55px] border-r-2 border-l-2 border-[#0c1c1e] shadow-[0_0px_4px_0px_rgba(0,0,0,0.25)] overflow-auto'
        >
          <div className='w-full flex flex-col items-center '>
            <div className='  md:w-[100%] mt-[15px] gap-3 flex flex-col items-center justify-center md:h-[75px] 2xl:w-auto 2xl:h-auto 3xl:w-[500px] 4xl:w-[800px] 4xl:mt-10'>
              {LexaLogo}
            </div>
            <form
              onSubmit={handleSubmit}
              className='xs:w-[90%] sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%] flex flex-col gap-[20px] md:gap-[10px]
              '
              style={{
                gap: errors.check ? '5px' : '10px',
              }}
            >
              <h1 className='text-center text-white mt-[3vh] mb-[2vh] md:text-[3.5vh] 2xl:text-[3vh] font-[Poppins]'>
                Create Account
              </h1>
              <div className='mb-2 md:mt-2 2xl:mt-3 3xl:mb-5'>
                <Input
                  type='text'
                  placeholder='Enter Your First Name'
                  name='firstname'
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.firstname}
                  text='First Name'
                />
                {errors.firstname && touched.firstname ? (
                  <FormErrors error={errors.firstname} />
                ) : null}
              </div>
              <div className='mb-2 md:mt-2 2xl:mt-3 3xl:mb-5 '>
                <Input
                  type='text'
                  placeholder='Enter Your Last Name'
                  name='lastname'
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.lastname}
                  text='Last Name'
                />
                {errors.lastname && touched.lastname ? (
                  <FormErrors error={errors.lastname} />
                ) : null}
              </div>
              <div className='mb-2 md:mt-2 2xl:mt-3 3xl:mb-5'>
                <Input
                  type='text'
                  placeholder='Enter your email'
                  name='email'
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e)
                    setEmailError(false)
                  }}
                  onBlur={handleBlur}
                  errors={errors.email}
                  text='Email'
                  autocomplete="off"
                  />
                {errors.email && touched.email ? (
                  <FormErrors error={errors.email} />
                ) : null}

                {emailError && values.email ? (
                  <FormErrors error='User with this email already exists' />
                ) : (
                  ''
                )}
              </div>
              <div className='mb-2 md:mt-2 2xl:mt-3 3xl:mb-5'>
                <div className='w-full relative'>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter Your Password'
                    name='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password}
                    text='Password'
                    autocomplete="off"

                  />
                    {showPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowPassword(false)}
                        className='absolute right-3 cursor-pointer text-gray-400 text-sm'
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowPassword(true)}
                        className='absolute right-3 cursor-pointer text-gray-400 text-sm'
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      />
                    )}
                  </div>
                {errors.password && touched.password ? (
                  <FormErrors error={errors.password} />
                ) : null}
              </div>
              <div className='md:mt-2 2xl:mt-3 3xl:mb-5'>
                <div className='w-full relative'>
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Enter Your Password'
                    name='confirm_password'
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.confirm_password}
                    text='Confirm Password'
                  />
                  {/* <div className='absolute md:top-[55px] xs:mt-[-36px] 2xl:top-[58px] right-3'>
                    {showConfirmPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowConfirmPassword(false)}
                        className='cursor-pointer text-[15px] text-gray-400'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowConfirmPassword(true)}
                        className='cursor-pointer text-[15px] text-gray-400 xs:mt-[7px] md:mt-[-6px]'
                      />
                    )}
                  </div> */}
                     {showConfirmPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowConfirmPassword(false)}
                        className='absolute right-3 cursor-pointer text-gray-400 text-sm'
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowConfirmPassword(true)}
                        className='absolute right-3 cursor-pointer text-gray-400 text-sm'
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      />
                    )}
                </div>
                {errors.confirm_password && touched.confirm_password ? (
                  <FormErrors error={errors.confirm_password} />
                ) : null}
              </div>
              <div className='flex items-start md:mt-2 2xl:mt-3 4xl:py-[30px]'>
                <input
                  type='checkbox'
                  name='check'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.check}
                  className='cursor-pointer w-[17px] xs:h-[26px] md:h-[17px] 3xl:w-[20px] 3xl:h-[20px] 4xl:w-[35px] 4xl:h-[35px]'
                />
                <span className=' text-white font-roboto xs:px-[30px] xs:py-[4px] md:px-[4px] md:ms-2 4xl:ms-3 4xl:mt-1 w-full xs:text-[9px] md:text-[9px] 2xl:text-[12px] 3xl:text-[12px] 4xl:text-[12px] -mt-[6px]'>
                  By signing up you acknowledge that content is generated by AI
                  and agree to our
                  <Link to='/terms-and-conditions' className='mx-1'>
                    terms of use
                  </Link>
                  &
                  <Link to='/privacy-policy' className='mx-1'>
                    privacy policy.
                  </Link>
                </span>
              </div>
              {errors.check && touched.check ? (
                <FormErrors error={errors.check} />
              ) : null}
              <div className='mt-2'>
                <ReCAPTCHA sitekey={key} onChange={onChangeRecaptcha} />
              </div>
              <div className='flex justify-end my-2 4xl:pt-[50px] '>
                <SubmitButton
                  title='Sign Up with Password'
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              </div>
            </form>
          </div>
          <div className='xs:p-[12px] md:p-[2px] text-[9px] md:text-[10px]  4xl:mb-5 text-white '>
            Already have an account?{' '}
            <Link
              to='/login'
              className='font-semibold text-decoration-none text-[#1f88fe]'
            >
              Log in
            </Link>{' '}
            to get started!
          </div>
        </div>
      </div>
    </>
  )
}

export default Register

function getErrorMessage(statusCode) {
  const errorItem = ErrorMessages.find((item) => item[statusCode.toString()])
  return errorItem
    ? errorItem[statusCode.toString()]
    : 'An unknown error occurred.'
}
