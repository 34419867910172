import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
// Local Import
import GlobalContext from "../../../context/GlobalContext";
import InfoIcon from '../../../assets/icons/InfoIcon'
import LeftArrowIcon from '../../../assets/icons/LeftArrowIcon'
import BankTransferIconMobile from '../../../assets/icons/BankTransferIconMobile'

const BankTansferMethod = ({ onClickPayNow, loading, error }) => {
  // NAVIGATION
  const navigate = useNavigate()
  const { themeToggle, } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <Box
        component='div'
        padding='40px'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        minHeight='50vh'
        flexWrap='wrap'
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap='30px'
        >
          <div className={`flex items-start gap-2 ${themeToggle ? "text-white" : "text-[#333333]"}`}>
            <InfoIcon />
            <p className='text-[14px] leading-4 font-[400]'>
              Experience easy payments – save your Bank account as default
              method to pay! <br />
              <br />
              Please ensure your Bank account is Active and has sufficient
              balance.
            </p>
          </div>
        </Box>
        <Box display='flex' alignItems='start' flexDirection='column'>
          <div className='flex items-center gap-[12px]'>
            <button
              className='bg-[#20808D] hover:bg-[#20808D] px-6 py-2 text-white rounded-full'
              onClick={() => onClickPayNow('card')}
            >
              {loading ? (
                <CircularProgress
                  color='inherit'
                  size={18}
                  sx={{ marginTop: '3px' }}
                />
              ) : (
                'Pay Now'
              )}
            </button>
            <button
              className={`hover:bg-slate-300 px-6 py-2 rounded-full ${themeToggle ? "text-white" : "text-black"}`}
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
          <div className='w-full mt-[auto] pt-2 '>
            <p className={`text-[#FF0000] text-[14px]`}>
              {error?.type === 'wrong' ? error?.message : ''}
            </p>
          </div>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default BankTansferMethod

export const BankTransferethodMobile = ({ onClickPayNow, loading, error }) => {
  // NAVIGATION
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Box
        component='div'
        padding='0px 40px 40px 40px '
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        minHeight='50vh'
        flexWrap='wrap'
      >
        {/* Back Icon */}
        <div
          className='flex items-center pt-3 cursor-pointer hover:text-[#676565]'
          onClick={() => navigate(-1)}
        >
          <div>
            <LeftArrowIcon className={'rotate-180'} />
          </div>
          <div className='flex items-center gap-1'>
            <p className={`font-[600] mb-0`}>Payment Details</p>
          </div>
        </div>

        {/* Payment Logo  */}
        <div className='flex items-center gap-4 py-3 '>
          <BankTransferIconMobile width={100} height={80} />
          <p
            className={` font-[600] text-[20px] font-['ui-sans-serif'] translate-y-2`}
          >
            Credit/Debit Card
          </p>
        </div>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap='30px'
        >
          <div className='flex flex-col gap-2 '>
            <div className='flex items-start gap-2'>
              <p className='text-[14px] leading-4 text-[#333333] font-[400]'>
                Experience easy payments – save your Bank Transfer account as
                default method to pay! <br />
                <br />
                Please ensure your Visa account is Active and has sufficient
                balance.
              </p>
            </div>
          </div>
        </Box>
        <Box display='flex' alignItems='center' gap='10px' mt='40px'>
          <button
            className='bg-[#20808D] hover:bg-[#20808D] px-6 py-2 text-white rounded-full'
            onClick={() => onClickPayNow('card')}
          >
            {loading ? (
              <CircularProgress
                color='inherit'
                size={18}
                sx={{ marginTop: '3px' }}
              />
            ) : (
              'Pay Now'
            )}
          </button>
          <button
            className='hover:bg-gray-200 px-6 py-2 rounded-full'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </Box>
        <div className='w-full '>
          <p className={`text-[#FF0000] text-[14px]`}>
            {error?.type === 'wrong' ? error?.message : ''}
          </p>
        </div>
      </Box>
    </React.Fragment>
  )
}
