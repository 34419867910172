import React from "react";

function Modal({ show, onClose, children }) {
  if (!show) {
    return null;
  }

  return (
    <>
      <div className="modal-backdrop" onClick={onClose} />
      <div className="modal-content">
        {children}
        <button onClick={onClose}>Close</button>
      </div>
    </>
  );
}

export default Modal;
