import React, { useContext,useEffect } from 'react';
import GlobalContext from '../context/GlobalContext';
import "../styles/Layout.css";
import Navbar from "./Navbar";



function Layout({ children }) {
  const {setSystemTheme,themeToggle} = useContext(GlobalContext);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', themeToggle);
  }, [themeToggle]);
  return (
    <div id="layout" className={` ${themeToggle ? 'dark' : ''}`}>
    <div className="navbar">
      <Navbar />
    </div>
    <main>{children}</main>
  </div>
  );
}

export default Layout;
