import * as React from 'react'
const UnCheckedCheckboxIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    viewBox='0 0 20 20'
    {...props}
  >
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth={1}>
      <g transform='translate(-165 -717)'>
        <g transform='translate(165 620)'>
          <g transform='translate(0 61)'>
            <g transform='translate(0 36)'>
              <path d='M0 0H20V20H0z' />
              <path
                stroke='grey'
                d='M13.255 2.5c2.542 0 4.245 1.785 4.245 4.44h0v6.128c0 2.647-1.703 4.432-4.245 4.432h0-6.503C4.21 17.5 2.5 15.715 2.5 13.068h0V6.94c0-2.655 1.71-4.44 4.253-4.44h6.502z'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default UnCheckedCheckboxIcon
