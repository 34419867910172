import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { TextField, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

// Local Imports
import CustomTable from '../../shared/Table'
import { usersData } from '../../../staticData/tableData'
import { Action, Status } from '../../shared/Table/utils'
import Modal from '../../shared/Modal'
import { Api } from '../../../api'

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#13343B', // Default border color
      },
      '&:hover fieldset': {
        borderColor: '#13343B', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#13343B', // Border color when focused
      },
    },
    '& .MuiInputLabel-root': {
      color: '#13343B', // Label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#13343B', // Label color when the input is focused
    },
  },
})

//FORM INITIAL VALUES
const formValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const UsersList = () => {
  // STATES
  const [colmnSetting1] = useState(['action'])
  const [colmnSetting2] = useState(['status'])
  const [rows, setNewRows] = useState([])
  const [addUserModal, setAddUserModal] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [deleteUserDetails, setDeleteUserDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [addUserLoading, setAddUserLoading] = useState(false)
  const [errors, setErrors] = useState({})

  // ADD NEW USER STATES
  const [formData, setFormData] = useState(formValues)
  const [isEmpty, setIsEmpty] = useState({})

  // DATA PROVIDERS
  const dataProviders = [
    {
      columnName: colmnSetting1,
      func: (restProps) => Action(restProps, onActionClick),
    },

    {
      columnName: colmnSetting2,
      func: (restProps) => Status(restProps),
    },
  ]

  // HANDLER ONCHANGE
  const handleOnChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
    setIsEmpty({
      ...isEmpty,
      [name]: false,
    })
  }

  //IS EMPTY
  const validateAllFields = () => {
    const emptyFields = {}
    let emtpyFields = {}
    // For Other Fields
    Object.keys(formData).forEach((key) => {
      if (formData[key] === null || formData[key] === '') {
        emtpyFields[key] = true
      } else {
        emtpyFields[key] = false
      }
    })
    setIsEmpty(emtpyFields)

    return Object.values(emtpyFields)?.includes(true) ? true : false
  }

  // HANDLE EDIT & DELETE
  const onActionClick = (type, row) => {
    if (type === 'edit') {
      setAddUserModal(true)
    }
    if (type === 'delete') {
      setIsDeleteModal(true)
      setDeleteUserDetails(row)
    }
  }

  // PAYLOAD ADD NEW USER & DELETE USER
  const payload = {
    newUserPayload: {
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
    },
    deleteUserPayload: {
      user_id: deleteUserDetails?.userId,
    },
  }

  // HANDLE NEW USER
  const handleAddNewUser = async () => {
    const userPayload = payload.newUserPayload
    setAddUserLoading(true)
    setErrors({})
    try {
      const response = await Api.addNewOrganizationUser(userPayload)
      setAddUserLoading(false)
      setAddUserModal(false)
      setFormData(formValues)
      fetchUsersList()
    } catch (error) {
      setAddUserLoading(false)
      setErrors({ message: error?.response?.data?.message })
    }
  }

  // HANDLE DELETE USER

  const handleDeleteUser = async () => {
    const userPayload = payload.deleteUserPayload
    setDeleteLoading(true)
    try {
      const response = await Api.deleteOrganizationUser(userPayload)
      setDeleteLoading(false)
      fetchUsersList()
      setIsDeleteModal(false)
    } catch (error) {
      setDeleteLoading(false)
    }
  }

  // HANDLE ON ADD USER SUBMIT
  const handleOnSubmit = (event) => {
    event.preventDefault()
    if (!validateAllFields()) {
      handleAddNewUser()
    }
  }

  // FETCH USERSLIST
  const fetchUsersList = async () => {
    setLoading(true)
    try {
      const response = await Api.getOrganizationUsers()
      const results = await response?.data
      const rows = setNewRows(() => {
        return results?.data?.map((item, index) => ({
          id: index + 1,
          userId: item?.id,
          firstName: item?.first_name,
          lastName: item?.last_name,
          email: item?.email,
        }))
      })

      setLoading(false)
    } catch (error) {}
  }

  // GETUSERS
  useEffect(() => {
    fetchUsersList()
  }, [])

  const classes = useStyles()

  return (
    <React.Fragment>
      <Box
        component='div'
        width='100%'
        padding='20px'
        display='flex'
        flexDirection='column'
        gap='20px'
      >
        <div className='flex justify-between w-full items-center'>
          <h1 className='font-roboto 2xl:text-[26px] text-[20px]  font-[500]'>
            Users
          </h1>
          <Button
            variant='contained'
            size='small'
            className='px-4 !rounded-md !bg-[#13343B] !capitalize !py-[0.8rem]'
            startIcon={<AddRoundedIcon />}
            onClick={() => setAddUserModal(true)}
          >
            Add New User
          </Button>
        </div>

        {/* Table */}
        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='60vh'
          >
            <CircularProgress size={40} color='inherit' />
          </Box>
        ) : (
          <CustomTable
            columns={usersData.columns}
            rows={rows?.length > 0 ? rows : []}
            tableColumnExtensions={usersData.usersColumnExtensionsData}
            dataProviders={dataProviders}
          />
        )}
      </Box>

      {/* ADD NEW USER MODAL */}

      <Modal
        open={addUserModal}
        onClose={() => {
          setAddUserModal(false)
          setDeleteUserDetails({})
        }}
        title='Add New User'
        padding={'20px'}
        minWidth={'500px'}
      >
        <Box component='div'>
          <div className='px-4 pt-2 pb-0 grid grid-cols-1 gap-4'>
            <TextField
              size='medium'
              className={`${classes.root}`}
              autoComplete='off'
              label='First Name'
              value={formData.firstName}
              onChange={handleOnChange}
              name='firstName'
              error={isEmpty?.firstName}
            />
            <TextField
              size='medium'
              className={`${classes.root}`}
              autoComplete='off'
              label='Last Name'
              value={formData.lastName}
              onChange={handleOnChange}
              name='lastName'
              error={isEmpty?.lastName}
            />
            <TextField
              size='medium'
              variant='outlined'
              className={`${classes.root}`}
              autoComplete='off'
              label='Email'
              value={formData.email}
              onChange={handleOnChange}
              name='email'
              error={isEmpty?.email}
            />

            {errors?.message !== '' && (
              <p className='text-[14px] text-red-600'>
                {errors?.message !== '' ? errors?.message : null}
              </p>
            )}
          </div>
        </Box>
        <Box
          component='div'
          display='flex'
          justifyContent='flex-start'
          flexDirection='row-reverse'
          gap='6px'
          paddingX='16px'
        >
          <Button
            variant='outlined'
            size='small'
            className='px-4 !rounded-md !bg-[#13343B] !text-white !capitalize !py-[0.8rem]'
            onClick={handleOnSubmit}
          >
            {addUserLoading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Add New User'
            )}
          </Button>

          <Button
            variant='outlined'
            size='small'
            color='inherit'
            className='px-4 !rounded-md  !capitalize !py-[0.8rem]'
            onClick={() => {
              setAddUserModal(false)
              setDeleteUserDetails({})
              setFormData({})
            }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      {/* DELETE USER MODAL */}
      <Modal
        open={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false)
          setDeleteUserDetails({})
        }}
        title='Delete User'
      >
        <Box component='div'>
          <p>
            Are you sure you want to delete this user{' '}
            <b>{deleteUserDetails?.userName}</b>?
          </p>
        </Box>
        <Box component='div' display='flex' justifyContent='flex-end' gap='6px'>
          <Button
            variant='outlined'
            size='small'
            color='info'
            className='px-2 !rounded-md  !capitalize !py-1 '
            onClick={() => {
              setIsDeleteModal(false)
              setDeleteUserDetails({})
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='small'
            color='error'
            className='px-2 !rounded-md !capitalize !py-3'
            onClick={handleDeleteUser}
          >
            {deleteLoading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Delete'
            )}
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default UsersList
