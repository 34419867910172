import React from 'react'
import Layout from '../components/Layout'
import MainSectionThankyou from '../components/ThankYou/MainSection'

const ThankYouPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <MainSectionThankyou />
      </Layout>
    </React.Fragment>
  )
}

export default ThankYouPage
