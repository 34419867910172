import React from 'react'
import Lexa from '../../assets/avatar_2.png'
import Marquee from 'react-fast-marquee'


const testimonials = [
  {
    name: 'Ali Nawaz',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'The AI-driven analytics from Lexa have completely changed the way I handle my caseload, offering profound insights that were previously inaccessible.',
  },
  {
    name: 'Adeel Haider',
    profession: 'Law Student',
    rating: 5,
    body: "Lexa's real-time feedback on mock exams and interactive MCQs have dramatically improved my understanding of complex legal theories.",
  },
  {
    name: 'Sarah Chudhary',
    profession: ' Law Student',
    rating: 5,
    body: 'Thanks to Lexa, my drafting assignments are now faster and more accurate, ensuring I stay ahead in my studies and practical training.',
  },
  {
    name: 'Arsla ',
    profession: ' Legal Consultant',
    rating: 5,
    body: 'Lexa’s instant responses and reliable legal solutions have empowered me to offer better advice to my clients, boosting my consultancy’s reputation.',
  },
  {
    name: 'Fatima Haider',
    profession: 'Corporate Lawyer',
    rating: 5,
    body: 'Using Lexa has allowed me to develop more effective legal strategies by providing access to a wide range of cases and precedents with detailed analyses.',
  },
  {
    name: 'Zainab Adeel',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'Lexa’s seamless integration into our practice has enhanced our productivity by automating routine tasks and analysis, giving us a competitive edge.',
  },
  {
    name: 'Arslana Nawaz',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'With Lexa, I’ve been able to reduce the turnaround time on cases by efficiently navigating through the vast legal databases and extracting pertinent information.',
  },
  {
    name: 'Iqra Kamal',
    profession: 'Law Student',
    rating: 5,
    body: 'Lexa has been instrumental in my exam preparation, providing customized quizzes and real-world case studies that enhance my legal reasoning.',
  },
  {
    name: 'Sawera Nadeem',
    profession: 'Legal Consultant',
    rating: 5,
    body: "Lexa’s drafting tools have streamlined my document creation process, significantly reducing errors and improving the delivery time of legal documents.",
  },
  {
    name: 'Aqeel Ahmad',
    profession: 'Corporate Lawyer',
    rating: 5,
    body: 'The responsiveness of Lexa’s AI to urgent legal inquiries has made it an invaluable tool in my daily legal practice, ensuring I can always rely on it for immediate assistance.',
  },
  {
    name: 'Mariyam Fatima',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'Lexa has not only enhanced my research capabilities but also enriched my understanding of complex legal frameworks through its detailed and insightful analyses.',
  },
  {
    name: 'Hira Tahir',
    profession: 'Corporate Lawyer',
    rating: 5,
    body: 'Lexa’s cutting-edge technology has revolutionized how we approach legal consultations, making complex legal advice accessible and immediate.',
  },
];


const firstRow = testimonials.slice(0, testimonials.length / 2)
const secondRow = testimonials.slice(testimonials.length / 2)

const ReviewCard = ({name, profession,rating, body }) => {
  return (
    <figure className='review-card text-white' id='Testimonials'>
      <div className='review-header'>
        <img className='review-avatar w-12 h-12 -mt-[14px] border-2 border-[#0D9488] rounded-full' src={Lexa} alt={`${name}'s avatar`} />
        <div className='review-user-info'>
          <figcaption className='review-name text-white'>{name}</figcaption>
          <p className='review-username text-white'>{profession}</p>
          <span className='-mt-4'> {'⭐'.repeat(rating)}</span>
        </div>
      </div>
      <blockquote className='review-body text-white'>{body}</blockquote>
    </figure>
  )
}

const Testimonials = () => {
    return (
      <div className='mt-32'>
        <div className='text-center'>
          <h1 className='text-center text-4xl font-bold'>Testimonials</h1>
          <div className='divider border-2 border-[#0D9488] m-auto block w-28'></div>
        </div>
        <div className='marquee-container'>
          <Marquee pauseOnHover gradient={false} speed={80}>
            {firstRow.map((review,index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </Marquee>
          <Marquee
            pauseOnHover
            gradient={false}
            speed={80}
            direction='right'
            style={{ marginTop: '25px' }}
          >
            {secondRow.map((review,index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </Marquee>
        </div>
      </div>
    )
}


export default Testimonials
