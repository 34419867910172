import React, { useContext } from 'react'
import { CircularProgress } from '@mui/material'
import { diamond } from '../../../staticData/icons'
import JazzCashIconMobile from '../../../assets/icons/JazzCashIconMobile'
import LeftArrowIcon from '../../../assets/icons/LeftArrowIcon'
import EasyPaiseIconMobile from '../../../assets/icons/EasyPaiseIconMobile'
import VisaIconMobile from '../../../assets/icons/VisaIconMobile'
import BankTransferIconMobile from '../../../assets/icons/BankTransferIconMobile'
import GlobalContext from "../../../context/GlobalContext";

const mobilePaymentDetails = [
  {
    icon: <JazzCashIconMobile />,
    title: 'Jazz Cash',
    paymentType: 'jazzcash',
    translate: 'translate-y-2',
  },
  {
    icon: <EasyPaiseIconMobile />,
    title: 'Easy Paisa',
    paymentType: 'easypaisa',
    translate: 'translate-y-2 py-1',
  },
  {
    icon: <VisaIconMobile />,
    title: 'Visitors International Stay Admission',
    paymentType: 'visaCard',
    translate: 'translate-y-2 py-2 text-[14px]',
  },
  {
    icon: <BankTransferIconMobile />,
    title: 'Credit/Debit Card',
    paymentType: 'debitCard',
    translate: 'translate-y-2',
  },
]

const PaymentSummary = ({
  selectedPlan,
  promoCode,
  setPromoCode,
  onClickApplyCoupon,
  loading,
  message,
}) => {
  // PROPS
  const { themeToggle, } = useContext(GlobalContext);

  return (
    <React.Fragment>
      <div className='w-full h-full rounded border-1 border-[#CEE9FF] flex flex-col justify-between'>
        {/* Top Plan Details */}
        <div className='bg-[#13343B] h-[30%] text-white p-4'>
          <div className='flex flex-col gap-3'>
            <div className='flex items-center  gap-2.5'>
              <div className='bg-[#FEC67B2B] w-[50px] h-[50px] flex items-center justify-center rounded-full'>
                {diamond}
              </div>
              <h1 className={`font-[500] text-[1.4rem] mt-2`}>
                {selectedPlan?.name}
              </h1>
            </div>
            <div>
              <h1 className='font-[500] text-[1.5rem]'>
                RS {selectedPlan?.amount}
                <span className='text-[10px] font-normal text-[#A6A5A8] ml-3 uppercase'>
                  / {selectedPlan?.interval}
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/* Plan Amount Details */}
        <div className='mb-[auto] p-4'>
          <h2 className={`font-[400] text-[18px] ${themeToggle ? "text-white" : "text-black"}`}>Payment summary</h2>
          <div className='flex items-baseline justify-between'>
            <h3 className={`text-[16px] font-[300]  leading-[24px] ${themeToggle ? "text-white" : "text-[#615F5F]"}`}>
              Subtotal
            </h3>
            <p className={`font-[500] text-[16px] ${themeToggle ? "text-white" : "text-[#333333]"} leading-[24px]`}>
              RS {selectedPlan?.amount}
            </p>
          </div>
        </div>

        {/* Apply coupan */}
        <div className='mt-[auto] px-4 pt-4 flex gap-2 w-[100%]'>
          <input
            type='text'
            placeholder='Enter Coupan'
            className='placeholder:text-[#C2C2C2] w-[100%] p-2 outline-none border-1 border-[#13343B] focus:border-2 focus:border-[#13343B]'
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <button
            className='text-[10px] w-[150px] bg-[#20808D] text-white'
            onClick={onClickApplyCoupon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress color='inherit' size={16} />
            ) : (
              'Apply Coupon'
            )}
          </button>
        </div>
        <div className='w-full mt-[auto] px-4 '>
          <p
            className={`${message?.type === 'success' ? 'text-[#219653]' : 'text-[#FF0000]'
              } text-[14px]`}
          >
            {message?.type === 'success'
              ? message?.message
              : message?.type === 'error'
                ? message?.message
                : ''}
          </p>
        </div>

        {/* Plan Total Details */}
        <div className='h-[12%] p-4 border-t border-[#CEE9FF] flex items-baseline justify-between'>
          <h3 className={`text-[16px] font-[300]  leading-[24px] ${themeToggle ? "text-white" : "text-[#615F5F]"}`}>
            Subtotal
          </h3>
          <p className='text-[#219653] font-[500] text-[16px] leading-[24px]'>
            RS {selectedPlan?.amount}/-
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaymentSummary

export const PaymentSummaryMobile = ({
  selectedPlan,
  promoCode,
  setSelectedPlanId,
  setPromoCode,
  onClickApplyCoupon,
  loading,
  message,
}) => {
  const handleManagePaymentMethods = (type) => {
    setSelectedPlanId({
      planId: selectedPlan?.id,
      planName: selectedPlan?.name,
      type: 'mobile',
      method: type,
    })
  }
  const { themeToggle, } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <div className='w-full h-full rounded border-1 border-[#CEE9FF] flex flex-col justify-between'>
        {/* Top Plan Details */}
        <div className='px-4 py-2'>
          <div className='flex flex-col gap-3'>
            <div className='flex items-center  gap-2.5'>
              <div className='bg-[#FEC67B2B] w-[50px] h-[50px] flex items-center justify-center rounded-full'>
                {diamond}
              </div>
              <h1 className='font-[500] text-[1.4rem] mt-2'>
                {selectedPlan?.name}
              </h1>
            </div>
          </div>
        </div>
        {/* Plan Amount Details */}
        <div className='mb-[auto] px-4 py-2'>
          <h2 className={`font-[400] text-[18px] ${themeToggle ? "text-white" : "text-black"}`}>Payment summary</h2>
          <div className='flex items-baseline justify-between pt-3'>
            <h3 className={`text-[16px] font-[300]  leading-[24px] ${themeToggle ? "text-white" : "text-[#615F5F]"}`}>
              Subtotal
            </h3>
            <p className={`font-[500] text-[16px] ${themeToggle ? "text-white" : "text-[#333333]"} leading-[24px]`}>
              PKR {selectedPlan?.amount}
            </p>
          </div>
        </div>

        {/* Apply coupan */}
        <div className='mt-[auto] px-4 pt-4 flex gap-2 w-[100%]'>
          <input
            type='text'
            placeholder='Enter Coupan'
            className='placeholder:text-[#C2C2C2] w-[100%] p-2 outline-none border-1 border-[#13343B] focus:border-2 focus:border-[#13343B]'
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <button
            className='text-[10px] w-[150px] bg-[#20808D] text-white'
            onClick={onClickApplyCoupon}
          >
            {loading ? (
              <CircularProgress color='inherit' size={16} />
            ) : (
              'Apply Coupon'
            )}
          </button>
        </div>

        <div className='w-full mt-[auto] px-4 pt-2'>
          <p
            className={`${message?.type === 'success' ? 'text-[#219653]' : 'text-[#FF0000]'
              } text-[14px]`}
          >
            {message?.type === 'success'
              ? message?.message
              : message?.type === 'error'
                ? message?.message
                : ''}
          </p>
        </div>

        {/* Plan Total Details */}
        <div className='px-4 pb-2 pt-3  border-t border-[#CEE9FF] flex items-baseline justify-between'>
          <h3 className={`text-[24px] font-[300]  leading-[24px] ${themeToggle ? "text-white" : "text-[#615F5F]"}`}>
            Total
          </h3>
          <p className={`font-[500] text-[24px] ${themeToggle ? "text-white" : "text-[#219653]"} leading-[24px]`}>
            PKR {selectedPlan?.amount}/-
          </p>
        </div>

        {/* Payment Details */}
        <div className='mb-[auto] px-4 py-3 border-t border-[#CEE9FF] '>
          <h2 className={`font-[400] text-[17px] ${themeToggle ? "text-white" : "text-[#615F5F]"}`}>Payment Details</h2>
        </div>

        {/* Payment Details Methods */}
        <div className='bg-[#F8F8F8]'>
          {mobilePaymentDetails.map((item, index) => {
            return (
              <div
                key={index}
                className='flex items-center justify-between  px-3 py-1 border-t border-[#CEE9FF] cursor-pointer hover:bg-[#e9e8e8]'
                onClick={() => handleManagePaymentMethods(item.paymentType)}
              >
                <div className='flex items-center gap-2'>
                  <div className=''>{item.icon}</div>
                  <p
                    className={`${item.translate}  font-[600] font-['ui-sans-serif']`}
                  >
                    {item.title}
                  </p>
                </div>
                <LeftArrowIcon />
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
