import React, { useContext, useState, useEffect } from 'react';
import GlobalContext from '../context/GlobalContext';
import { historyIcon, newChatIcon } from '../staticData/icons';
import '../styles/ResponsiveNav.css';
import { useNavigate, useLocation } from "react-router-dom";
import Catagories from './quiz/Catagories'
import ChatComponent from './ChatComponent'

import tabs from "../staticData/navbarTabs.json";

function ResponsiveNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    clearChatData,
    setQueryType,
    setSelectedTab,
    selectedTab,
    sendBtnLoading,
    setChatViewVisibility,
    isChatViewVisible,
    activeMcqs,
    setActiveMcqs,
    themeToggle
  } = useContext(GlobalContext);

  useEffect(() => {
    const currentPath = location.pathname.slice(1).toLowerCase(); // Normalize the path
    const foundTab = tabs.find(tab => tab.tab_ref.replace(/\s+/g, '').toLowerCase() === currentPath);
    if (foundTab && selectedTab !== foundTab.tab_ref) {
      setSelectedTab(foundTab.tab_ref);
    }
  }, [location, selectedTab, setSelectedTab]);

  const handleTabSelection = (tabName) => {
    if (!sendBtnLoading) {
      clearChatData();
      setQueryType(tabName === 'ChatBot' ? 'General_Queries' : tabName);
      setSelectedTab(tabName);
      navigate(`/${tabName.replace(/\s+/g, '').toLowerCase()}`); 
      if (tabName !== 'ChatBot') {
        setActiveMcqs('');
        setChatViewVisibility(true); 
      }
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setChatViewVisibility(buttonName !== 'chat-history');
    if (buttonName === 'chat-history') {
      setSelectedTab('ChatBot');
    }
  };

  const [activeButton, setActiveButton] = useState('new-chat');

  const handleButtonMcqs = (buttonName) => {
    setActiveMcqs(buttonName);
    if (buttonName === 'Categories') {
      setChatViewVisibility(false);
    } else {
      setChatViewVisibility(true);
    }
  }

  return (
    <div className="navbar-res">
      <div className="navbar-wrapper bg-[#F3F3EE] dark:bg-[#1d1b1d]">
        <nav className={`navbar bg-[#F3F3EE] dark:bg-[#1d1b1d]`}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${selectedTab === tab.tab_ref ? 'active' : ''} ${themeToggle && selectedTab === tab.tab_ref ? 'dark-selected' : ''}`}
              onClick={() => handleTabSelection(tab.tab_ref)}
            >
              <span className={`${themeToggle ? 'dark-selected' : ''}`}>{tab.tab_ref}</span>
            </div>
          ))}
        </nav>
        {selectedTab === "ChatBot" && (
          <div className="navbar-actions bg-[#d1d1c4] dark:bg-[#1d1b1d]">
            <button
              className={`action-link new-chat flex ${activeButton === 'new-chat' ? 'active-button' : ''}`}
              onClick={() => handleButtonClick('new-chat')}
            >
              <div className="mr-1">{newChatIcon}</div>
              New Chat
            </button>
            <button
              className={`action-link chat-history flex ${activeButton === 'chat-history' ? 'active-button' : ''}`}
              onClick={() => handleButtonClick('chat-history')}
            >
              <div className="mr-1">{historyIcon}</div>
              Chat History
            </button>
          </div>
        )}
        {selectedTab === 'MCQs' && (
          <div className='navbar-actions bg-[#d1d1c4] dark:bg-[#1d1b1d]'>
            <button
              className={`action-link mcqs flex ${activeMcqs === 'mcqs' ? 'active-button' : ''
                }`}
              onClick={() => handleButtonMcqs('mcqs')}
            >
              <div className='mr-1'>{newChatIcon}</div>
              MCQs
            </button>
            <button
              className={`action-link categories flex ${activeMcqs === 'Categories' ? 'active-button' : ''
                }`}
              onClick={() => handleButtonMcqs('Categories')}
            >
              <div className='mr-1'>{historyIcon}</div>
              Categories
            </button>
          </div>
        )}
      </div>
      {selectedTab === 'MCQs' && activeMcqs === 'Categories' && <Catagories />}
      {!isChatViewVisible && activeButton === 'chat-history' ? <ChatComponent /> : ''}
    </div>
  )
}

export default ResponsiveNav;
