// Library Imports
import React, { useState } from 'react';
import { Grid, Box, Typography, Pagination, styled } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';

// Local Imports - Adjust these imports according to your React project's structure
import BlogCard from '../shared/BlogCard'; // Update this path
import Styles from '../styles/home.module.css'; // Update this path
import { primaryMain, secondaryMain } from '../theme/GlobalVariables'; // Update this path
import { truncateString } from '../utils/utils'; // Update this path
import BlogsFooter from '../../../langingPage/BlogsFooter';
import Header from '../../../langingPage/Header';

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-textPrimary.Mui-selected': {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: '#cda950',
    color: theme.palette.secondary.main,
    borderRadius: '10px',
    width: 34,
    height: 34,
  },
  '& .MuiPaginationItem-textPrimary': {
    backgroundColor: '#21808D',
    color: 'white',
    borderRadius: '10px',
    padding: '6px',
  },
  '& .MuiPaginationItem-icon': {
    color: theme.palette.primarytext,
    backgroundColor: 'transparent',
  },
}));


const itemsPerPage = 6;


const BlogsListSection = ({
  blogs,
  tags,
  tagfilter,
  handleAddTagFilter,
  handleRemoveTagFilter,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(blogs.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentBlogs = blogs.slice(startIndex, endIndex);

  const latestBlogs = blogs
  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  .slice(0, 5);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  

  const onClickToRedirect = (blog) => {
    const endURL = blog?.slug || blog?.end_url; 
    navigate(`/blogs-view/${endURL}`); 
  };


  return (
    <div>
      <Header/>
      <Grid container mt='20px' p='30px'>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          {currentBlogs.length > 0?
          <Typography variant="h5" color="primarytext">Latest Posts</Typography>:null}
          <Box display="flex" gap="10px" flexWrap="wrap" mt={tagfilter.length > 0 ? '10px' : '0px'}>
            {tagfilter.map((tag, index) => (
              <Typography
                key={index}
                fontSize="13px"
                color="primaryText"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="6px"
                bgcolor={primaryMain}
                width="max-content"
                padding="2px 10px"
                borderRadius="17px"
                rowGap="5px"
                sx={{ cursor: 'pointer', userSelect: 'none' }} // Add cursor style for clickable tags
                onClick={() => handleRemoveTagFilter(tag)}
              >
                {tag}
                <CloseRoundedIcon sx={{ fontSize: '16px' }} />
              </Typography>
            ))}
          </Box>
          <Box display="flex" flexWrap="wrap" rowGap="30px" gap="30px" mt="20px">
            {currentBlogs.map((blog, index) => (
              <BlogCard
                key={index}
                date={blog.created_at}
                blogName={blog.title}
                description={truncateString(blog.short_description.replace(/<[^>]*>/g, ''), 26)}
                image={blog.featured_image}
                tags={blog.tags}
                className={Styles.blogsPageBlogs}
                onClick={() => onClickToRedirect(blog)}
                showThumbnail={true}
              />
            ))}
            {currentBlogs.length === 0 && (
              <Grid container height="50vh" alignItems="center" justifyContent="center">
                <Box textAlign="center">
                  <DescriptionIcon fontSize="large" />
                  <Typography color="primaryText">No Data Found</Typography>
                </Box>
              </Grid>
            )}
          </Box>
          {blogs.length > 0 && (
            <Box mt="60px" display="flex" justifyContent="left" mb="20px" marginTop="20px">
              <StyledPagination
                position='static'
                className={Styles.paginationDesign}
                count={totalPages}
                color="primary"
                shape="rounded"
                variant="text"
                boundaryCount={1}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </Grid>
        {blogs.length > 0 && (
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="h5" color="primarytext">Popular Posts</Typography>
            <Box display="block" flexWrap="wrap" rowGap="30px" my={10} mt="20px">
              {blogs.sort((a, b) => b.popular_count - a.popular_count).slice(0, 3).map((blog, index) => (
                <BlogCard
                  key={index}
                  date={blog.created_at}
                  blogName={blog.title}
                  image={blog.featured_image}
                  description={truncateString(blog.short_description.replace(/<[^>]*>/g, ''), 26)}
                  dating={blog.created_at}
                  tags={blog.tags}
                  className={Styles.blogsPopularPost}
                  onClick={() => onClickToRedirect(blog)}
                  showThumbnail={false}
                  
                />
              ))}
            </Box>
            <Typography variant="h5" color="primarytext" mt="30px">Tags</Typography>
            <Box display="flex" gap="8px" flexWrap="wrap" mt="20px">
              {tags.map((tag, index) => (
                <Typography
                  key={index}
                  fontSize="13px"
                  color={tagfilter.includes(tag) ? 'text.secondary' : 'primarytext'}
                  bgcolor={tagfilter.includes(tag) ? secondaryMain : 'transparent'}
                  width="max-content"
                  padding="2px 10px"
                  borderRadius="17px"
                  sx={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={() => handleAddTagFilter(tag)}
                >
                  {tag}
                </Typography>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
      <BlogsFooter latestBlogs={latestBlogs}/>
    </div>
  );
};


export default BlogsListSection;
