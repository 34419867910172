// In your Dashboard component or wherever you need these boxes
import React from 'react';
import InfoBox from './InfoBox';
import earningIcon from '../../assets/earningIcon.png'
import subscriberIcon from '../../assets/subscriberIcon.png'
import customerIcon from '../../assets/customerIcon.png'
import balanceIcon from '../../assets/balanceIcon.png'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const ReportBox = () => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <InfoBox
                title="TOTAL EARNING"
                amount="$1,234.56"
                percentage="+10.5"
                icon={faCaretUp}
                iconImg={earningIcon}
                color="bg-[#cce3ff]"
            />
            <InfoBox
                title="TOTAL SUBSCRIPTION"
                amount="$94,182"
                percentage="-10.5"
                icon={faCaretDown}
                iconImg={subscriberIcon}
                color="bg-[#ffebcc]"
            />
            <InfoBox
                title="CUSTOMERS"
                amount="$94,182"
                percentage="-10.5"
                icon={faCaretDown}
                iconImg={customerIcon}
                color="bg-[#d7f3ff]"
            />
            <InfoBox
                title="TOTAL BALANCE"
                amount="$94,182"
                percentage="-10.5"
                icon={faCaretDown}
                iconImg={balanceIcon}
                color="bg-[#ffd9d4]"
            />
            {/* ... more InfoBox components */}
        </div>
    );
};

export default ReportBox;
