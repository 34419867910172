import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const VisitorsOverviewChart = () => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep'],
    datasets: [
      {
        label: 'New',
        data: [120, 190, 170, 0, 200, 195, 20, 220, 250], // Replace with your actual data
        fill: false,
        borderColor: '#8E44AD', // Purple color
        backgroundColor: '#8E44AD',
        pointBackgroundColor: '#8E44AD',
        pointBorderColor: '#8E44AD',
        pointHoverBackgroundColor: '#8E44AD',
        pointHoverBorderColor: '#8E44AD',
        tension: 0.4,
      },
      {
        label: 'Registered',
        data: [80, 0, 100, 150, 200, 170, 50, 60, 220], // Replace with your actual data
        fill: false,
        borderColor: '#3498DB', // Blue color
        backgroundColor: '#3498DB',
        pointBackgroundColor: '#3498DB',
        pointBorderColor: '#3498DB',
        pointHoverBackgroundColor: '#3498DB',
        pointHoverBorderColor: '#3498DB',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          padding: 20, 
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#E5E5E5"
        },
        beginAtZero: true,
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
      line: {
        borderWidth: 3,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="pl-2 pr-2 pt-2 pb-12 bg-white shadow-md rounded-lg relative">
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-lg font-semibold text-gray-800">VISITORS OVERVIEW</h2>
        <button
          className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
          onClick={() => { /* handler for your menu icon click */ }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </button>
      </div>
      <div className="h-64 relative">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default VisitorsOverviewChart;