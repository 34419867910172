// Library Imports
import axios from 'axios';
import Cookies from 'js-cookie'; // Make sure this is installed.



// Local Imports
import { baseUrl } from '../constant/constants';

class apiService {
  //************************* Class Private Functions *******************************//
 
  getAuthorization() {
    const token = Cookies.get('token'); // Ensure this correctly retrieves your token
    const headers = {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    };
   
    return axios.create({
       baseURL: baseUrl,
       headers,
    });
   }
  
  #processResponse = (status, endPoint, response) => {
    if (status) {
      let data = {
        success: status,
        data: Array.isArray(response?.data)
          ? response?.data
          : Array.isArray(response?.data?.results)
          ? response?.data?.results
          : response?.data?.data
          ? response?.data?.data
          : response?.data,
      };
      // console.log(
      //   `%c ${endPoint}`,
      //   'color: green; font-family:sans-serif; font-size: 20px; font-weight: 700',
      //   data
      // );
      return data;
    } else {
      if (response?.message === 'Network Error') {
        let data = {
          success: status,
          data: response?.message,
        };
        // console.log(
        //   `%c ${endPoint}`,
        //   'color: red; font-family:sans-serif; font-size: 20px; font-weight: 700',
        //   data
        // );
        return data;
      } else {
        let data = {
          success: status,
          data: response?.response?.data?.message,
        };
        // console.log(
        //   `%c ${endPoint}`,
        //   'color: red; font-family:sans-serif; font-size: 20px; font-weight: 700',
        //   data
        // );
        return data;
      }
    }
  };

  //************************* Get Request Functions *******************************//

  sendGetWithAuth = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .get(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

// sendGetWithoutAuth = (params, endPoint, callback) => {
//     // Assuming params is an object { tags: "tag1,tag2,tag3" }
//     const query = params.tags ? `?tag=${encodeURIComponent(params.tags)}` : '';
//     const url = `${baseUrl}/${endPoint}${query}`;

//     axios.get(url)
//          .then(response => callback(true, response))
//          .catch(error => callback(false, error));
// };
  sendGetWithoutAuth = (payload, endPoint, callback) => {
    axios
      .get(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

 


  //************************* Post Request Functions *******************************//

  sendPostWithAuth = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .post(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  sendPostWithAuthFormData = (formData, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();
    
    // Make sure headers such as Content-Type are not manually set for this request
    const config = {
        headers: {
            'Authorization': axiosWithAuth.defaults.headers['Authorization']
        }
    };

    axios.post(`${baseUrl}/${endPoint}`, formData, config)
    .then((response) => {
            callback(this.#processResponse(true, endPoint, response));
      })
         .catch((response) => {
              callback(this.#processResponse(false, endPoint, response));
              });
};

  sendPostWithoutAuth = (payload, endPoint, callback) => {
    axios
      .post(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  //************************* Delete Request Functions *******************************//

  sendDeleteWithAuth = (endPoint, payload, callback) => {
    const axiosWithAuth = this.getAuthorization();
  
    // Correctly constructed URL should not append `/` + payload again because endpoint is already complete
    axiosWithAuth.delete(`${baseUrl}/${endPoint}`, { data: payload })
      .then(response => callback(this.#processResponse(true, endPoint, response)))
      .catch(response => callback(this.#processResponse(false, endPoint, response)));
  };

  sendDeleteWithAuthAndPayloadInBody = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .delete(`${baseUrl}/${endPoint}/`, { data: payload })
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  //************************* Update Request Functions *******************************//

  sendPutWithAuth = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .put(`${baseUrl}/${endPoint}/`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  sendPutWithAuthFormData = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .put(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };
}

const ApiServices = new apiService();
export default ApiServices;
