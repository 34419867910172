// Library Imports
import React, { useEffect, useState, useContext } from 'react'
import { Box, Tab, Grid, Typography, Button } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useSearchParams } from 'react-router-dom'
import GlobalContext from "../../../context/GlobalContext";

import UpArrowRound from '../../../assets/icons/UpArrowRound'
import PaymentCard, { PaymentCardMobileStart } from '../../shared/PaymentCard'
import PaymentDetailsForDesktopAndTab, {
  PaymentDetailsForMobile,
} from '../PaymentDetailsTab'
import LockIcon from '../../../assets/icons/LockIcon'

// Local Imports
const MainSectionPaymentPlans = ({ plans }) => {
  // PARAMs
  const { themeToggle, } = useContext(GlobalContext);

  const [selectedPlanId, setSelectedPlanId] = useSearchParams()
  const [selectedPlan, setSelectedPlan] = useState({})
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([])
  // console.log('plans', plans)
  // STATES
  const [value, setValue] = useState('1')
  // HANDLE TAB CHANGE
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (selectedPlanId.get('planId')) {
      setSelectedPlan(
        plans.find((plan) => plan.id === +selectedPlanId.get('planId'))
      )
    }
  }, [selectedPlanId])

  // const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    // Function to update selectedPlanId based on screen width
    const handleResize = () => {
      if (window.innerWidth >= 580 && window.innerWidth <= 600) {
        setSelectedPlanId({})
      }
    }

    // Initial call to handleResize
    handleResize()

    // Event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup function
    return () => window.removeEventListener('resize', handleResize)
  }, [setSelectedPlanId])

  // SET PLANS ACCORDING TO INTERVEL
  useEffect(() => {
    let monthPlans = [];
    let yearPlans = [];
    [...plans]?.map((plan) => {
      if (/month/.test(plan?.interval)) {
        monthPlans.push(plan)
      } else if (/year/.test(plan?.interval)) {
        yearPlans.push(plan)
      }
    })
    setMonthlyPlans(monthPlans)
    setYearlyPlans(yearPlans)
  }, [plans])



  // console.log('monthlyPlans', monthlyPlans)
  // console.log('yearlyplans', yearlyPlans)
  return (
    <Box
      component='div'
      className={` ${themeToggle ? "bg-[#1d1b1d]" : "bg-[#fffff]"}`}
    // sx={{ backgroundColor: 'black', height: '100vh !important' }}
    >
      {/* Top Content */}
      <div className='flex justify-center items-center  text-[#9FACAD] gap-[10px] py-3 border-b border-[#EEEEEE]'>
        <UpArrowRound />
        <p className={`text-[18px] font-[500] mb-0 ${themeToggle ? "text-white" : "text-black"}`}>
          Upgrade Plan / Pick a Plan
        </p>
        <Button href="/" variant="outlined">
          Skip
        </Button>
      </div>


      {/* Tabs */}
      {selectedPlanId.get('type') !== 'mobile' && (
        <TabContext value={value}>
          {/* Top Tabs */}
          {selectedPlanId.get('planId') === ('' || null) && (
            <Box
              sx={{
                borderBottom: 0,
                borderColor: 'transparent',
                backgroundColor: '#F3F3EE',
                width: 'max-content',
                borderRadius: '30px',
                padding: '5px 5px',
                margin: 'auto',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
                indicatorColor='transparent'
                textColor='inherit'
              >
                <Tab
                  label='Monthly'
                  value='1'
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: value === '1' ? '#fff' : 'unset',
                    fontWeight: '600',
                    fontSize: 14,
                    borderBottom: 'none',
                    color: value === '1' ? '#13343B' : '#99A7A6',
                    borderRadius: '46px',
                    padding: '10px 50px',
                  }}
                />
                <Tab
                  label='Annual'
                  value='2'
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: value === '2' ? '#fff' : 'unset',
                    fontWeight: '600',
                    fontSize: 14,
                    borderBottom: 'none',
                    color: value === '2' ? '#13343B' : '#99A7A6',
                    borderRadius: '46px',
                    padding: '10px 50px',
                  }}
                />
              </TabList>
            </Box>
          )}

          {/* For Desktop */}
          <Box padding={{ lg: '20px', xs: '0px', sm: '10px', md: '20px' }}>
            {/* MOHTHLY PLANS */}
            <TabPanel
              value='1'
              sx={{
                padding: '0px',
                maxWidth: '1440px',
                margin: 'auto',
              }}
            >
              <Box
                component='div'
                maxWidth={
                  selectedPlanId.get('planId') === ('' || null) ? 'xl' : 'lg'
                }
                mx='auto'
              >
                <Typography
                  fontSize='19px'
                  fontWeight='500'
                  className={` ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}
                  paddingLeft={'20px'}
                  paddingY={'20px'}
                  display={
                    selectedPlanId.get('planId') === ('' || null)
                      ? 'block'
                      : 'none'
                  }
                >
                  You are on Bronze Plan
                </Typography>

                <Typography
                  component='div'
                  fontSize='24px'
                  fontWeight='400'
                  className={`${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}
                  paddingY={'20px'}
                  display={
                    selectedPlanId.get('planId') !== ('' || null)
                      ? 'block'
                      : 'none'
                  }
                  sx={{ maxWidth: 'lg' }}
                >
                  Payment Details
                  <div className={`flex items-center gap-2 text-[18px] text-[#4F4F4F] font-[400] ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}>
                    <LockIcon />
                    This form is encrypted and your information is always
                    guaranteed secure
                  </div>
                </Typography>
              </Box>
              {/* For Desktop */}

              <Grid
                container
                rowGap='20px'
                alignItems='end'
                display={{ xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }}
              >
                {selectedPlanId.get('planId') === ('' || null) ? (
                  monthlyPlans?.length > 0 ? monthlyPlans?.map((plan) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={monthlyPlans?.length < 3 ? 3 : 4}
                    >
                      <PaymentCard
                        {...plan}
                        key={plan?.id}
                        setSelectedPlanId={setSelectedPlanId}
                      />
                    </Grid>
                  )) :
                    (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='50vh'
                        width='100%'
                      >
                        <h1 className={`text-2xl ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}>NO PLANS AVIALABLE</h1>
                      </Box>
                    )

                ) : (
                  <PaymentDetailsForDesktopAndTab
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  /> 
                )}
              </Grid>

              {/* For Mobile */}
              <Grid
                container
                rowGap='0px'
                alignItems='end'
                display={{ xs: 'flex', sm: 'none', md: 'none', lg: 'none' }}
              >
                {selectedPlanId.get('planId') === ('' || null)
                  ? monthlyPlans?.length > 0 ? monthlyPlans?.map((plan) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}

                    >
                      <PaymentCardMobileStart
                        {...plan}
                        key={plan?.id}
                        setSelectedPlanId={setSelectedPlanId}
                      />
                    </Grid>
                  ))
                    : (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='50vh'
                        width='100%'
                      >
                        <h1 className={`text-2xl ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}>NO PLANS AVIALABLE</h1>
                      </Box>
                    ) : null}
              </Grid>
            </TabPanel>

            {/* // YEARLY  PLANS */}
            <TabPanel
              value='2'
              sx={{
                padding: '0px',
                maxWidth: '1440px',
                margin: 'auto',
              }}
            >
              <Box
                component='div'
                maxWidth={
                  selectedPlanId.get('planId') === ('' || null) ? 'xl' : 'lg'
                }
                mx='auto'
              >
                <Typography
                  fontSize='19px'
                  fontWeight='500'
                  className={` ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}
                  paddingLeft={'20px'}
                  paddingY={'20px'}
                  display={
                    selectedPlanId.get('planId') === ('' || null)
                      ? 'block'
                      : 'none'
                  }
                >
                  You are on Bronze Plan
                </Typography>

                <Typography
                  component='div'
                  fontSize='24px'
                  fontWeight='400'
                  className={`${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}
                  paddingY={'20px'}
                  display={
                    selectedPlanId.get('planId') !== ('' || null)
                      ? 'block'
                      : 'none'
                  }
                  sx={{ maxWidth: 'lg' }}
                >
                  Payment Details
                  <div className={`flex items-center gap-2 text-[18px] text-[#4F4F4F] font-[400] ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}>
                    <LockIcon />
                    This form is encrypted and your information is always
                    guaranteed secure
                  </div>
                </Typography>
              </Box>
              {/* For Desktop */}

              <Grid
                container
                rowGap='20px'
                alignItems='end'
                display={{ xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }}
              >
                {selectedPlanId.get('planId') === ('' || null) ? (
                  yearlyPlans?.length > 0 ? yearlyPlans?.map((plan) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <PaymentCard
                        {...plan}
                        key={plan?.id}
                        setSelectedPlanId={setSelectedPlanId}
                      />
                    </Grid>
                  )) : (
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      height='50vh'
                      width='100%'
                    >
                      <h1 className={`text-2xl ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}>NO PLANS AVIALABLE</h1>
                    </Box>
                  )
                ) : (
                  <PaymentDetailsForDesktopAndTab
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                )}
              </Grid>

              {/* For Mobile */}
              <Grid
                container
                rowGap='0px'
                alignItems='end'
                display={{ xs: 'flex', sm: 'none', md: 'none', lg: 'none' }}
              >
                {selectedPlanId.get('planId') === ('' || null)
                  ? yearlyPlans?.length > 0 ? yearlyPlans?.map((plan) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={yearlyPlans?.length < 3 ? 3 : 4}
                    >
                      <PaymentCardMobileStart
                        {...plan}
                        key={plan?.id}
                        setSelectedPlanId={setSelectedPlanId}
                      />
                    </Grid>
                  ))
                    : (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='50vh'
                        width='100%'
                      >
                        <h1 className={`text-2xl ${themeToggle ? "text-white" : "text-[#4F4F4F]"}`}>NO PLANS AVIALABLE</h1>
                      </Box>
                    ) : null}
              </Grid>
            </TabPanel>
          </Box>
        </TabContext>
      )}
      {/* For  Mobile Selcted Plan */}
      <Box display={{ xs: 'block', sm: 'none', md: 'none', lg: 'none' }}>
        <PaymentDetailsForMobile
          selectedPlanId={selectedPlanId}
          setSelectedPlanId={setSelectedPlanId}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      </Box>
    </Box>
  )
}

export default MainSectionPaymentPlans
