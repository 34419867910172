/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import bg_image from "../../assets/bg-hero.jpg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Input from "../../components/shared/Input";
import { LexaLogo } from "../../staticData/icons";

import { RotatingLines } from "react-loader-spinner";
import { Api } from "../../api";
let EMAIL_REGX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/;
const ForgotSchema = Yup.object({
  email: Yup.string()
    .required("Please enter your email")
    .max(50, "Email must not exceed 50 characters")
    .matches(EMAIL_REGX, "Invalid email address"),
});

function ForgotPassword() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: ForgotSchema,
      onSubmit: async (values, action) => {
        setLoading(true);
        await Api.forgotPassword(values)
          .then((response) => {
            action.resetForm();
            setLoading(false);
            Navigate(`/check-email/${values.email}`);
          })
          .catch((error) => {
            setLoading(false);

            Swal.fire({
              icon: "error",
              text: error.response.data.errors.non_field_errors[0],
              customClass: {
                confirmButton: "custom-confirm-button",
              },
            });
          });
      },
    });

  return (
    <>
      <div
        className="max-w-screen  bg-no-repeat bg-cover flex justify-center items-center"
        style={{ backgroundImage: `url(${bg_image})` }}
      >
        <div
          className="bg-transparent xs:w-[95%] sm:w-[75%] md:w-[60%] lg:w-[45%] xl:w-[35%] 2xl:w-[40%] 3xl:w-[40%] h-[100vh] flex flex-col md:py-[10px] 2xl:py-[30px] justify-between items-center
       
          rounded-[54px] border-r-2 border-l-2 border-[#0c1c1e] shadow-[0_0px_4px_0px_rgba(0,0,0,0.25)]"
        >
          <div className="w-full flex flex-col items-center gap-[35px]">
            <div className="  md:w-[100%] mt-[15px] gap-3 flex flex-col items-center justify-center md:h-[75px] 2xl:w-auto 2xl:h-auto 3xl:w-[500px] 4xl:w-[800px] 4xl:mt-10">
              {LexaLogo}
            </div>
          </div>

          <div className="mt-2 w-[60%] h-[45%]  flex flex-col items-center justify-start">
            <span className=" mb-7 text-[25px] 3xl:text-2xl 4xl:text-[35px]  font-[500] font-[Poppins] text-white">
              Forgot Password
            </span>
            <Input
              type="text"
              placeholder="Enter Your Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.email}
              text="Email"
            />
            <p className="text-left flex items-start justify-start w-full">
              {errors.email && touched.email ? (
                <small className="text-red-600 3xl:text-xl 4xl:text-[35px] ">
                  {errors.email}
                </small>
              ) : null}
            </p>
            <div className="flex w-full items-end justify-end py-3">
              <button
                type="button"
                onClick={handleSubmit}
                className=" 3xl:text-2xl
                4xl:text-[35px]
       text-[#FCFCF9]  text-[16px] 2xl:text-[17px] md:text-[15px] flex 
       justify-center items-center bg-[#20808d] md:w-[170px] 2xl:w-[180px]
        h-[40px] 2xl:h-[50px] 3xl:w-[190px] 3xl:h-[65px] 
        4xl:w-[280px] 4xl:h-[120px]  rounded-[150px] font-[500]"
              >
                {loading ? (
                  <RotatingLines
                    strokeColor="white"
                    strokeWidth="2"
                    animationDuration="0.75"
                    width="25"
                    visible={true}
                  />
                ) : (
                  <>Reset Password</>
                )}
              </button>
            </div>
          </div>
          <span className="font-roboto text-[15px] 3xl:text-2xl 4xl:text-[35px] text-white">
            Back to,{" "}
            <span
              className="text-[#1F88FE] font-[700] cursor-pointer"
              onClick={() => Navigate("/login")}
            >
              {" "}
              Sign in
            </span>
          </span>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
