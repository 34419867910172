import React from 'react';
import Modal from './Modal';
import '../../styles/quizResult.css'
import reward from '../../assets/reward.png';
import fail from '../../assets/fail.png';

function ResultQuiz(props) {
  const isCongratulations = props.resultPercentage >= 80;
  const resultMessage = isCongratulations ? "CONGRATULATIONS" : "BETTER LUCK NEXT TIME";
  const resultImage = isCongratulations ? reward : fail; // Corrected this line

  return (
<Modal show={props.showModal} onClose={props.handleCloseModal}>
  <div className="result-popup">
    <img src={resultImage} alt={resultMessage} />
    <h2>{resultMessage}</h2>
    <div className="result-details">
      <div className="grade">
        <span className="receive-grade">Receive Grade</span>
        <span className="percentage">{props.resultPercentage}%</span>
      </div>
      <div className="correct-answer">Correct Answers: {props.correctAnswer}</div>
      <div className="wrong-answer">Wrong Answers: {props.attemptedQuestions - props.correctAnswer}</div>
      <div className="wrong-answer">Attempted Questions: {props.attemptedQuestions}</div>
    </div>
    <button className="feedback-button mt-2 mb-2" onClick={props.handleViewFeedback}>View Results</button>
    <button className="close-button" onClick={props.handleCloseModal}>×</button>
  </div>
</Modal>

  );
}

export default ResultQuiz;
