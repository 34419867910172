import React from 'react'
import Marquee from 'react-fast-marquee'
import benifitClock from '../../assets/landingPage-imgs/benifitClock.png'
import benefitJudgments from '../../assets/landingPage-imgs/benefitJudgments.png'
import benefitQuestion from '../../assets/landingPage-imgs/benefitQuestion.png'
import benifitService from '../../assets/landingPage-imgs/benifitService.png'
import benifitDrafting from '../../assets/landingPage-imgs/benifitDrafting.png'
import costEffective from '../../assets/landingPage-imgs/cost_effective.svg'
const benefits = [
  {
    title: 'Relevant Answers',
    icon: benifitClock, // replace with actual icon path
    description: 'Get accurate and timely legal answers.',
  },
  {
    title: 'Comprehensive Judgments',
    icon: benefitJudgments, // replace with actual icon path
    description: 'Access a vast database of legal judgments.',
  },
  {
    title: 'Efficient Drafting',
    icon: benifitDrafting, // replace with actual icon path
    description: 'Streamline your legal document drafting.',
  },
  {
    title: 'Interactive Learning',
    icon: benefitQuestion, // replace with actual icon path
    description: 'Engage in interactive legal learning.',
  },
  {
    title: '24/7 Support',
    icon: benifitService, // replace with actual icon path
    description: 'Receive support anytime you need it.',
  },
  {
    title: 'Cost Effective',
    icon: costEffective, // replace with actual icon path
    description: 'Get accurate and timely legal answers.',
  },
  {
    title: 'Relevant Answers',
    icon: benifitClock, // replace with actual icon path
    description: 'Get accurate and timely legal answers.',
  },
  {
    title: 'Comprehensive Judgments',
    icon: benefitJudgments, // replace with actual icon path
    description: 'Access a vast database of legal judgments.',
  },
  {
    title: 'Efficient Drafting',
    icon: benifitDrafting, // replace with actual icon path
    description: 'Streamline your legal document drafting.',
  },
  {
    title: 'Interactive Learning',
    icon: benefitQuestion, // replace with actual icon path
    description: 'Engage in interactive legal learning.',
  },
  {
    title: '24/7 Support',
    icon: benifitService, // replace with actual icon path
    description: 'Receive support anytime you need it.',
  },
]

const BenefitCard = ({ icon, title, description }) => {
  return (
    <div className='mx-8 flex flex-col items-center text-center cursor-pointer '>
      <img className=' mb-2 w-20 h-20' src={icon} alt={title} />
      <h3 className='text-lg font-medium text-white mb-1'>{title}</h3>
    </div>
  )
}

const Benefits = () => {
  return (
    <div className='mt-32' id='Services'>
      <div className='text-center'>
        <h1 className='text-center text-4xl font-bold'>Benefits</h1>
        <div className='divider border-2 border-[#0D9488] m-auto block w-28 mb-5'></div>
      </div>
      <div className=' xs:hidden md:flex  relative items-center w-full overflow-hidden py-5'>
        <Marquee pauseOnHover gradient={false} speed={80}>
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} />
          ))}
        </Marquee>
      </div>
      <div className='mobile-responsive xs:flex md:hidden'>
        <div className='col-xs-12'>
          <div className='grid  xs:grid-cols-2 gap-4 mt-24 py-5'>
            {benefits.slice(0, 6).map((item, index) => (
              <BenefitCard key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits
