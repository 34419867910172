import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { reporting, feedbackIcon, promoIcon, settingIcon, logoutIcon } from '../../staticData/icons';

function Sidebar() {
    const location = useLocation();

    const getLinkClassName = (path) => {
        const baseClasses = "flex items-center p-2 text-base font-normal rounded-lg transition-colors duration-150";
        if (location.pathname === path) {
            return `${baseClasses} bg-[#13343B] text-white no-underline`;
        }
        return `${baseClasses} text-gray-900 hover:bg-[#13343B] hover:text-white no-underline`;
    };

    const getIconClassName = (path) => {
        if (location.pathname === path) {
            return "w-6 h-6 text-white mr-3";
        }
        return "w-6 h-6 text-gray-500 mr-3 group-hover:text-white";
    };

    return (
        <div className="flex flex-col w-60 py-8 bg-[#ffffff] ">
            <h2 className="text-3xl font-semibold text-gray-800 ml-12">Brand</h2>
            <div className="flex flex-col justify-between mt-6">
                <ul className='space-y-2'>
                    <li>
                        <NavLink to="/dashboard" className={getLinkClassName('/dashboard')}>
                            <span className={getIconClassName('/dashboard')}>{reporting}</span>
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/reporting" className={getLinkClassName('/reporting')}>
                            <span className={getIconClassName('/reporting')}>{reporting}</span>
                            Reporting
                            <span className="px-2 py-1 ml-auto text-xs font-medium text-gray-800 bg-gray-200 rounded-full">3</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/feedback" className={getLinkClassName('/feedback')}>
                            <span className={getIconClassName('/feedback')}>{feedbackIcon}</span>
                            Feedback
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/promo" className={getLinkClassName('/promo')}>
                            <span className={getIconClassName('/promo')}>{promoIcon}</span>
                            Promo
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/user-management" className={getLinkClassName('/user-management')}>
                            <span className={getIconClassName('/user-management')}>{settingIcon}</span>
                            User Management
                        </NavLink>
                    </li>
                </ul>
                <ul>
                    <li className='mt-6'>
                        <NavLink to="/logout" className={getLinkClassName('/logout')}>
                            <span className={getIconClassName('/logout')}>{logoutIcon}</span>
                            Log out
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
