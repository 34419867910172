import React,{useState} from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faCircle } from '@fortawesome/free-solid-svg-icons';

const DashboardCard = () => {
    const [activeChart, setActiveChart] = useState('Pie Chart');

    // Function to set the active chart type
    const selectChart = (chartType) => {
      setActiveChart(chartType);
    };
  const data = {
    labels: ['Goals', 'Expenses', 'Revenue', 'Profits'],
    datasets: [
      {
        label: 'Overview',
        data: [12500, 98733, 36500, 98733],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverOffset: 4,
        cutout: '70%',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="bg-white p-5 rounded-lg shadow-lg max-w-sm w-full relative h-fit mt-4">
      <div className="flex justify-between items-center mb-5">
        <div>
          <h2 className="text-xl font-semibold">Overview</h2>
          <p className="text-gray-500 text-sm">Today at 4:20pm</p>
          <p className="text-gray-500 text-sm mt-2">Top companies integrate from their ERP systems and other sources</p>
        </div>
        <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
      </div>
      <div className="flex gap-4 mb-5 mx-2 py-2 px-2 bg-gray-500 rounded-xl">
               {['Pie Chart', 'Line Chart', 'Bar Chart'].map((chartType) => (
          <button
            key={chartType}
            onClick={() => selectChart(chartType)}
            className={`text-[12px] font-semibold py-1 px-1 rounded-lg shadow  ${
              activeChart === chartType ? 'bg-white' : 'bg-gray-500 text-gray-700'
            }`}
          >
            {chartType}
          </button>
        ))}
      </div>
      <div className="relative mb-5">
        <Doughnut data={data} options={options} />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <p className="text-3xl font-bold text-gray-800">$3.2M</p>
          <p className="text-sm text-gray-500">Profit</p>
        </div>
      </div>
      <div className="flex justify-between text-sm text-gray-700">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCircle} className="text-red-500 mr-2" />
          <span>Goals</span>
          <span className="ml-2">12 500</span>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCircle} className="text-blue-500 mr-2" />
          <span>Expenses</span>
          <span className="ml-2">98 733</span>
        </div>
      </div>
      <div className="flex justify-between text-sm text-gray-700 mt-2">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCircle} className="text-yellow-500 mr-2" />
          <span>Revenue</span>
          <span className="ml-2">36 500</span>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCircle} className="text-green-500 mr-2" />
          <span>Profits</span>
          <span className="ml-2">98 733</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
