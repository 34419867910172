import React from "react";
import '../../styles/inputautofil.css'
function Input(props) {
  return (
    <>
      <div className="relative w-full">
        <input
          type={props.type}
          autoComplete="off"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          id={props.id}
          style={{ color: 'white' }}
          className={`rounded-[8px] w-[100%] placeholder:text-[12px] placeholder:text-white placeholder:font-[400] py-2 4xl:mb-2 text-[2vh] 3xl:border-[2px] bg-transparent pl-3 pr-9 text-white border-[1px] border-[#13343b] outline-none focus:border-[#20808d]`}
        />
        <label
          htmlFor={props.id}
          className="absolute  text-white font-roboto duration-300 transform -translate-y-3 scale-75 top-0 z-10 origin-[0] 2xl:text-[16px] md:text-sm bg-[#0c2426]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
        >
          {props.text}
        </label>
      </div>
    </>
  );
}

export default Input;
