// Library Imports
import React from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import styles from '../styles/home.module.css'; // Adjust the import path as needed

function BlogPageSkelton() {
  return (
    <React.Fragment>
      <Grid container spacing={4} mt='20px' mb='40px'>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          {/* <Typography variant='h5' color='white'>
            Latest Posts
          </Typography> */}
          <Box display='flex' flexWrap='wrap' gap='30px' rowGap='30px' mt='20px'>
            {Array.from({ length: 6 }, (_, index) => (
              <SingleSkeltonCard isImage={true} key={index} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant='h5' color='white'>
            Popular Posts
          </Typography>
          <Box display='flex' flexDirection='column' rowGap='30px' mt='20px' width='100%'>
            {Array.from({ length: 4 }, (_, index) => (
              <SingleSkeltonCard isImage={false} key={index} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function SingleSkeltonCard({ isImage }) {
  return (
    <React.Fragment>
      <Box
        maxWidth={{
          lg: isImage ? '45%' : '100%',
          md: isImage ? '45%' : '100%',
          sm: '100%',
          xs: '100%',
        }}
        minWidth='40%'
        className={styles.blog}
      >
        {isImage && (
          <Skeleton variant='rectangular' height='233px' style={{ borderRadius: '14px' }} />
        )}
        <Skeleton width={80} style={{ borderRadius: '6px' }} />
        <Skeleton width='90%' style={{ borderRadius: '6px' }} />
        {Array.from({ length: 2 }, (_, index) => (
          <Skeleton key={index} width={index === 1 ? '80%' : '100%'} style={{ borderRadius: '6px' }} />
        ))}
        <Box display='flex' gap='8px' flexWrap='wrap'>
          {Array.from({ length: 2 }, (_, index) => (
            <Skeleton key={index} width={80} style={{ padding: '2px 10px', borderRadius: '6px' }} />
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default BlogPageSkelton;

export function SingleBlogSkeleton() {
  return (
    <Box>
      <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
        <Skeleton width='20%' />
        <Skeleton width='80%' height={80} />
      </Box>

      <Box display='flex' justifyContent='center' alignItems='center' gap='8px' mb='20px'>
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton key={index} width={80} height={45} style={{ borderRadius: '23px' }} />
        ))}
      </Box>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Skeleton
          width='100%'
          height={360}
          variant='rectangular'
          style={{ textAlign: 'center', borderRadius: '12px' }}
        />
      </Box>
      <Box maxWidth='730px' margin='auto' mt='40px' mb='40px'>
        <Box display='flex' flexDirection='column' gap='15px'>
          <Skeleton width={250} />
          {Array.from({ length: 10 }, (_, index) => (
            <Skeleton
              key={index}
              width={index === 9 ? '70%' : index === 8 ? '85%' : '100%'}
            />
          ))}
        </Box>
        <Box display='flex' flexDirection='column' gap='15px' mt='40px'>
          <Skeleton width={250} />
          {Array.from({ length: 10 }, (_, index) => (
            <Skeleton
              key={index}
              width={index === 9 ? '70%' : index === 8 ? '85%' : '100%'}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export function RelatedPostSkeleton({ tagline, showTitle }) {
  return (
    <Grid container mb='120px'>
      <Box>
        <Typography variant='h6' color='text.primary'>
          {showTitle ? 'Related Posts' : null}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {tagline}
        </Typography>
      </Box>
      <Grid item lg={12} md={12} xs={12}>
        <Box className={styles.blogRow} mt='20px'>
          {Array.from({ length: 3 }, (_, index) => (
            <SingleSkeltonCard isImage={true} key={index} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
