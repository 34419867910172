import React, { useContext, useEffect, useState } from "react";
import { downloadIcon, textFile } from "../staticData/icons";
import SearchQuery from "./SearchQuery";
import GlobalContext from '../context/GlobalContext';
import '../styles/statute.css'
import { Api } from "../api";
import Cookies from "js-cookie";
import Pagination from "./Pagination";
import Loader from "./quiz/Loader";
import CustomModal from './shared/CustomModal'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import FileViewer from 'react-file-viewer';

const Drafts = () => {
  const token = Cookies.get("token");
  const { sQuery, themeToggle } = useContext(GlobalContext);
  const [drafts, setDrafts] = useState([]);
  const [filteredDrafts, setFilteredDrafts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextUrl, setNextUrl] = useState(null); // For the next page URL
  const [previousUrl, setPreviousUrl] = useState(null); // For the previous page URL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [documentUrl, setDocumentUrl] = useState(''); // State variable to hold the document URL

  const [totalPages, setTotalPages] = useState(1);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      Api.draft(token).then(res => {
        if (res?.status === 201 && res?.data?.data) {
          setDrafts(res?.data?.data);
          setIsLoading(false);
        }
      }).catch(error => {
        console.error("Error fetching draft:", error);
      });
    }
  }, [token])

  useEffect(() => {
    const lowercasedQuery = sQuery.toLowerCase();
    const filtered = drafts?.filter(draft =>
      draft?.title?.toLowerCase()?.includes(lowercasedQuery)
    );
    setFilteredDrafts(filtered);
  }, [drafts, sQuery]);

  const openModalWithFile = (url) => {
    const convertedUrl = convertS3UrlToHttps(url);
    const detectedFileType = getFileTypeFromUrl(convertedUrl);
    setDocumentUrl(convertedUrl);
    setFileType(detectedFileType);
    setIsModalOpen(true);
  };

  const convertS3UrlToHttps = (s3Url) => {
    return s3Url.replace(/^s3:\/\/([^\/]+)\/(.*)$/, 'https://$1.s3.amazonaws.com/$2');
  };

  const getFileTypeFromUrl = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (extension === 'docx') {
      return 'docx'; // Support .docx viewer
    } else if (extension === 'doc') {
      return 'doc'; // Support .doc viewer
    } else {
      return 'unsupported'; // Show unsupported format text
    }
  };
  
  return (
    <div>
      <div className={`main-statute xs:pl-0 pr-[40px] xs:pr-0 mt mt-10 mr-11 ${drafts?.length !== 0 ? 'center-content' : ''} ${themeToggle ? 'dark-mode' : ''} dark:bg-[#232123]`}>
        <div className="search-container">
          <div className="heading">
            <p className="font-semibold text-center text-2xl capitalize text-black">
              Find drafts here!
            </p>
            <div className="flex items-center justify-center ">
              <SearchQuery />
            </div>
          </div>
        </div>
        {sQuery && (
          <div className=" text-center text-white mt-3">
            {drafts?.length === 0 && drafts?.length !== sQuery  ? (
              <h5>Sorry, no results found</h5>
            ) : (
              <h5>Search Results for "{sQuery}"</h5>
            )}
          </div>
        )}

        <div className="statute-content grid lg:grid-cols-4 md:grid-cols-2 xs:grid-cols-1 gap-4">
          {isLoading ? (
            <Loader />
          ) : (
            filteredDrafts?.map((item, index) => (
              <div
                key={index}
                className="flex flex-col border bg-white shadow rounded-lg overflow-hidden my-4">
                <div className="p-4 flex flex-col flex-grow cursor-pointer h-[150px]" onClick={() => openModalWithFile(item?.url)}>
                  <h3 className="text-lg font-semibold mt-2">{item?.title}</h3>
                </div>
                <a
                  href={convertS3UrlToHttps(item?.url)}
                  download
                  className="flex justify-between items-center bg-[#20808D] border-t-2 w-full text-white"
                >
                  <div className="p-4">
                    <span className="text-base text-white font-semibold flex items-center">
                      {textFile}
                      <span className="ml-2 xs:ml-0">Download PDF</span>
                    </span>
                  </div>

                  <div className="p-[18px] bg-white ">
                    {downloadIcon}
                  </div>
                </a>
              </div>
            ))
          )}
        </div>
        <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleDownload={() => {
          const link = document.createElement("a");
          link.href = documentUrl;
          link.download = "DownloadedFile." + fileType;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}>
          <FileViewer
            fileType={fileType}
            filePath={documentUrl}
            onError={(e) => {
              console.error('Error loading file:', e);
              alert('Failed to load the document. Please try again.');
            }}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default Drafts;
