import React,{useContext} from 'react';
import {masterCard} from '../staticData/icons'
import {visaCard} from '../staticData/icons'
import GlobalContext from "../context/GlobalContext";


const BillingContact = ({ data }) => {
  const {themeToggle} = useContext(GlobalContext);
  // console.log("Billing Data:", data);

  const cardIcon = data.paymentCard === 'Mastercard' ? masterCard : visaCard;

  return (
    <div className={`p-6 rounded-lg shadow-md mb-4 ${themeToggle? 'bg-black':'bg-white'}`}>
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Billing Contact</h2>
       
      </div>
      <div className="mt-4">
        <div className="text-sm text-gray-600">
          <div className='dark:text-white'>COMPANY</div>
          <div className="font-medium text-gray-900 dark:text-white">{data.company}</div>
        </div>
        <div className="text-sm text-gray-600 mt-3">
          <div className='dark:text-white'>NAME</div>
          <div className="font-medium text-gray-900 dark:text-white">{data.name}</div>
        </div>
        <div className="text-sm text-gray-600 mt-3">
          <div className='dark:text-white '>EMAIL</div>
          <div className="font-medium text-gray-900 dark:text-white ">{data.email}</div>
        </div>
        <div className="text-sm text-gray-600 mt-3">
        <div className='dark:text-white'>PAYMENT INFORMATION</div>
        <div className="flex items-center justify-between rounded-lg mt-2">
          <div className="flex items-center ">
           <span className='w-6 h-6'>{cardIcon}</span> 
            {/* <MastercardIcon className="h-6 w-6" />  */}
            <div className="ml-2">
              <div className="text-gray-900 font-medium dark:text-white">{data.type} ending in {data.paymentData}</div>
              <div className="text-gray-600 dark:text-white">Expiry {data.card_no}</div>
            </div>
          </div>
        </div>
      </div>
        <div className="text-sm text-gray-600 mt-3 ">
        <div className='dark:text-white'>BILLING ADDRESS</div>
        <div className="font-medium text-gray-900 dark:text-white">{data.billingAddress}</div>
      </div>
      </div>
    </div>
  );
};

export default BillingContact;
