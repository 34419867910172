// assets.js
import logo from './newlogo.png';
import heroImg from './hero1.png';
import videoImg from './video.jpg';
import aboutImg from './about.jpg';
import unit1Img from './unit1.png';
import unit2Img from './unit2.png';
import unit3Img from './unit3.png';
import unit4Img from './unit4.png';
import formImg from './form.jpg';
import phoneImg from './getstartedphone.png';
import blogImg from './blogthumbnail.jpg';
import pin from './location.png';
import meet1 from './team1.webp';
import meet2 from './team2.webp';
import meet3 from './team3.webp';
import meet4 from './team4.webp';
import meet5 from './team5.webp';
import meet6 from './team6.webp';
import meet7 from './team7.webp';
import meet8 from './team8.webp';
import homeabout from './homeabout.jpg';
import carasul1 from './1.jpg';
import carasul2 from './2.jpg';
import carasul3 from './3.jpg';
import carasul4 from './4.jpg';
import carasul5 from './5.jpg';
import carasul6 from './6.jpg';
import dummy from './dummy.png';

const assets = {
  logo,
  heroImg,
  videoImg,
  aboutImg,
  unit1Img,
  unit2Img,
  unit3Img,
  unit4Img,
  formImg,
  phoneImg,
  blogImg,
  pin,
  meet1,
  meet2,
  meet3,
  meet4,
  meet5,
  meet6,
  meet7,
  meet8,
  homeabout,
  carasul1,
  carasul2,
  carasul3,
  carasul4,
  carasul5,
  carasul6,
  dummy,
};

export default assets;
