import React from 'react';
import Sidebar from './Sidebar'; // Your sidebar component
import HeaderPanel from './HeaderPanel'; // Your header component
import ReportBox from './ReportBox'; // Your reporting boxes component
import ReportingDetails from './ReportingDetails'; // Your reporting details component

const Reporting = () => {
  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
      <Sidebar /> {/* Sidebar component - shown on the left side */}
      <div className="flex-1"> {/* This div wraps the main content area */}
        <HeaderPanel message="Reporting" /> {/* Header component at the top */}
        <div className="p-4">
          <ReportBox /> {/* Reporting boxes component - includes the top stats boxes */}
          <ReportingDetails /> {/* Reporting details component - includes the table of reporting details */}
        </div>
      </div>
    </div>
  );
};

export default Reporting;
