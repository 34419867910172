import React, { useState } from 'react';

const PromoForm = () => {
  const [promoName, setPromoName] = useState('');
  const [promoDescription, setPromoDescription] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submit action
    const promoData = {
      promoName,
      promoDescription,
      promoCode,
      startDate,
      endDate,
    };
    // Here you would typically send the promoData to your server
    // console.log(promoData);

    // Optionally reset the form fields
    setPromoName('');
    setPromoDescription('');
    setPromoCode('');
    setStartDate('');
    setEndDate('');
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-8">
    
      <div className="mb-4">
        <label htmlFor="promoName" className="block text-sm font-medium text-gray-700">
          Promo Name
        </label>
        <input
          type="text"
          id="promoName"
          value={promoName}
          onChange={(e) => setPromoName(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-8 py-2"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="promoDescription" className="block text-sm font-medium text-gray-700">
          Promo Description
        </label>
        <textarea
          id="promoDescription"
          value={promoDescription}
          onChange={(e) => setPromoDescription(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-8 py-2"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="promoCode" className="block text-sm font-medium text-gray-700">
          Set Promo Code
        </label>
        <input
          type="text"
          id="promoCode"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-8 py-2"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
          Start Date
        </label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-8 py-2"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
          End Date
        </label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-8 py-2"
          required
        />
      </div>

      <button
        type="submit"
        className="w-full bg-[#13343B] text-white p-2 rounded hover:bg-[#13343A] transition duration-300"
      >
        SAVE PROMO CODE
      </button>
    </form>
  );
};

export default PromoForm;
