
import React, { useState } from 'react';
import QuizData from './QuizData';
import Catagories from './Catagories';
import '../../styles/quiz.css';
import PopupModal from './PopupModel'; // Import your PopupModal component

function QuizApp() {
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(true);
  const [isQuizActive, setIsQuizActive] = useState(false);

  // Handles the confirmation from the popup modal
  const handleConfirm = () => {
    setIsPopupModalOpen(false);
    setIsQuizActive(true);
  };

  // Handles closing the popup modal
  const handleClose = () => {
    setIsPopupModalOpen(false);
  };

  return (
    <div className="app">
      <div className="sidebar md:block">
        <Catagories />
      </div>
      <div className="quiz-data">
        {isQuizActive && <QuizData />}
      </div>
      <PopupModal
        isOpen={isPopupModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message="Do you want to attempt the MCQs?"
      />
    </div>
  );
}

export default QuizApp;