import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

// import {
import CustomCheckBox from '../CheckBox'

const Cell = styled(TableCell)(({ theme }) => ({
  color: 'grey',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: '12px',
  padding: '20px 10px',
  // borderBottom: `1px solid red`,
  // borderTop: `1px solid grey`,
  '&:last-child': {
    // borderBottom: 'none !important',
  },
}))

const SelectionCell = styled(TableCell)(({ theme }) => ({
  // color: 'grey',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: '12px',
  // borderBottom: 'none',
  padding: '18px 0px',
  width: '50px',
  '&:last-child': {
    // borderBottom: `1px solid ${gray}`,
  },
}))

const Row = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  borderBottom: `1px solid grey`,
}))

const TableHeader = ({
  rows,
  columns,
  selectedIds,
  setSelectedIds,
  tableColumnExtensions,
}) => {
  return (
    <React.Fragment>
      <TableHead
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: '#f1f1f2',
          zIndex: 1,
        }}
      >
        <Row>
          {selectedIds?.length > 0 ? (
            <SelectionCell>
              <div
                style={{ backgroundColor: '#f1f1f1' }}
                className='flex flex-row  justify-content-between p-1 align-items-center rounded'
              >
                <div className='flex flex-row justify-content-between align-items-center rounded p-1 px-2 bg-[#F3F3EE]'>
                  <div
                    onClick={(event) => {
                      event.stopPropagation()
                      setSelectedIds([])
                    }}
                    className='cursor-pointer'
                    style={{
                      width: 16,
                      height: 16,
                      borderStyle: 'solid',
                      borderRadius: 4,
                      borderWidth: 1,
                      borderColor: 'black',
                      textAlign: 'center',
                      // backgroundColor: 'gray',
                    }}
                  >
                    <div
                      style={{ position: 'relative', top: -5 }}
                      className='text-black'
                    >
                      -
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 13,
                      marginLeft: 10,
                    }}
                    className='w-max absolute left-[35px] top-[18px] p-1 rounded pr-2'
                  >
                    {selectedIds?.length} Selected
                  </div>
                </div>
              </div>
            </SelectionCell>
          ) : (
            <Cell width='40px'>
              <CustomCheckBox
                checked={Boolean(selectedIds?.length > 0 ? true : false)}
                onChange={() => {
                  if (selectedIds?.length === 0) {
                    setSelectedIds(() => rows?.map((row) => row?.id))
                  } else {
                    setSelectedIds([])
                  }
                }}
              />
            </Cell>
          )}

          {columns?.map((col, colIndex) => {
            // GET COLUMN EXTENSION
            const columnExtension = tableColumnExtensions?.find(
              (item) => item?.columnName === col?.name
            )
            const width = columnExtension?.width || 100
            return (
              <Cell
                key={colIndex}
                color={'grey'}
                width={width || 100}
                style={{ minWidth: width }}
              >
                {selectedIds?.length === 0 ? col?.title : ''}
              </Cell>
            )
          })}
        </Row>
      </TableHead>
    </React.Fragment>
  )
}

export default TableHeader
