import React from 'react';
import loadingGif from '../../assets/loader.gif';

function Loader() {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-opacity-50  z-50">
      <img src={loadingGif} alt="Loading..."className="w-20 h-20 md:w-24 md:h-24"  />
    </div>

  );
}

export default Loader;
