/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles/ConversationView.css'
import Avatar from '@mui/material/Avatar'
import GlobalContext from '../context/GlobalContext'
import JudgementInput from '../components/JudgementInput'
import JudgementModel from './shared/JudgementModel'
import ActsModel from './ActsModel'
import { ai_img, advancedSearch } from '../staticData/icons'
import { SocketContext } from '../context/SocketContext' // Update the path accordingly
import AdvancedSearchComponent from './AdvancedSearchComponent'
import Pagination from './Pagination'
import Highlighter from 'react-highlight-words'

const Judgements = (props) => {
  const messageEndRef = useRef(null)
  const [openModal, setOpenModal] = useState(false)
  const [openSecondModal, setOpenSecondModal] = useState(false)
  const [showFullContent, setShowFullContent] = useState({})
  const [judgementStopGeneration, setJudgementStopGeneration] = useState(false)
  const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(false)
  const [storedQuery, setStoredQuery] = React.useState(null)
  const [summaryLoading, setSummaryLoading] = useState(false)

  const { sendJudgmentAdvancedSearch } = useContext(SocketContext)
  const [judgementcancelTokenSource, setJudgementCancelTokenSource] =
    useState(null)
  const {
    advancedSearchCriteria,
    isJudgmentSocketConnecting,
    judgementChatData,
    judgementchatLoading,
    judgementErrorMessage,
    judgementSendBtnLoading,
    selectedDocument,
    setSelectedDocument,
    sQuery,
    isResponseJudgmentAi,
    isSearchActive,
    totalJudgements,
    pageSize,
    currentPage,
    setCurrentPage,
  } = useContext(GlobalContext)

  const toggleAdvancedSearch = () => {
    setIsAdvancedSearchVisible(!isAdvancedSearchVisible)
  }
  const shouldDisplayLoader = judgementchatLoading || isSearchActive

  const characterLimit = 190

  const toggleContent = (index) => {
    setShowFullContent((prevShowFullContent) => {
      return { ...prevShowFullContent, [index]: !prevShowFullContent[index] }
    })
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    const searchDetails = {
      ...advancedSearchCriteria,
      page: newPage,
      page_size: pageSize,
    }
    sendJudgmentAdvancedSearch(searchDetails)
  }

  return (
    <div
      className=' flex flex-col w-full items-start justify-between h-[100%]'
      id='document-content-id'
    >
      <ActsModel
        open={openModal}
        onClose={() => setOpenModal(false)}
        judgement_data={selectedDocument}
      />

      <JudgementModel
        open={openSecondModal}
        onClose={() => {
          setOpenSecondModal(false)
          setSelectedDocument(selectedDocument)
        }}
        storedQuery={storedQuery}
        judgement_data={selectedDocument}
      />
      <div className='input-box '>
        {!isAdvancedSearchVisible && (
          <JudgementInput
            cancelTokenSource={judgementcancelTokenSource}
            setCancelTokenSource={setJudgementCancelTokenSource}
            stopGeneration={judgementStopGeneration}
            setStopGeneration={setJudgementStopGeneration}
            storedQuery={storedQuery}
            setStoredQuery={setStoredQuery}
          />
        )}
        {!isResponseJudgmentAi && (
          <p className='mb-0'>
            The information provided by this chatbot is for general
            informational purposes only and does not constitute legal advice.
          </p>
        )}
      </div>
      {isAdvancedSearchVisible && (
        <AdvancedSearchComponent
          storedQuery={storedQuery}
          setStoredQuery={setStoredQuery}
        />
      )}

      <button
        onClick={toggleAdvancedSearch}
        className={` flex relative md:left-[78%] xs:left-[44%] top-[10px] cursor-pointer pb-1 text-sm px-4 font-thin focus:outline-none transition-colors duration-300 ${
          isAdvancedSearchVisible ? ' text-teal-500' : 'text-teal-500'
        }`}
      >
        <span className='w-[14px] mr-2 '>{advancedSearch}</span>
        {isAdvancedSearchVisible ? 'Ai_Search' : 'Advanced Search'}
      </button>

      <div key={sQuery} className='conversation-box flex flex-col '>
        {isJudgmentSocketConnecting && (
          <div className='flex justify-center items-center'>
            <div
              className='inline-block justify-center items-center h-8 w-8 animate-spin rounded-full border-4 border-solid border-[#21808d] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
              role='status'
            >
              <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                Loading...
              </span>
            </div>
          </div>
        )}
        {judgementChatData?.length > 0 ? (
          judgementChatData?.map((data, chatIndex) => {
            const itemsToRender = Array.isArray(data?.content?.data)
              ? data.content.data
              : data?.content?.data?.judgements
            const keywords = storedQuery
              ? storedQuery.split(/\s+/).filter((word) => word.trim() !== '')
              : []

            return (
              <div key={chatIndex}>
                {data.role === 'user' && (
                  <div className='sender-div'>
                    <Avatar
                      src='/broken-image.jpg'
                      sx={{
                        borderRadius: 0,
                        width: '45px',
                        height: '45px',
                      }}
                    />
                    <div className='sender-message'>
                      {data?.content?.split('\n')?.map((paragraph, index) => (
                        <p
                          key={index}
                          className='mb-2 text-[#333] text-[18px] xl:text-[17px] font-[500] font-roboto '
                        >
                          {paragraph}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
                {!judgementchatLoading &&
                  !judgementSendBtnLoading &&
                  data.role === 'message' && (
                    <div className='sender-div'>
                      <div className='img-box'>{ai_img}</div>
                      <div className='sender-message'>
                        <p className='text-[#333] text-[18px] xl:text-[17px] font-roboto m-0'>
                          {data.content}
                        </p>
                      </div>
                    </div>
                  )}

                {data?.role === 'ai' &&
                  Array.isArray(itemsToRender) &&
                  itemsToRender?.map((item, index) => {
                    // console.log('item', item)
                    return (
                      <div>
                        {item?.summary && (
                          <div className='receiver-div' key={index}>
                            <div className='img-box'>{ai_img}</div>
                            <div className='receiver-message position-relative overflow-hidden cursor-pointer'>
                              <div className='flex flex-col items-start justify-start w-full'>
                                <div className='flex w-full items-center justify-between border-b-[1px] border-[#CFCFCF] h-[65px] 2xl:h-[] '>
                                  <div className='flex items-center justify-start  w-[85%] 2xl:w-[87%] pt-[25px] pb-[10px] md:pr-[28px] xs:pr-0 pl-[25px] h-full '>
                                    <p className='mb-2 text-[#20808D] md:text-[18px] xs:text-[14px] 2xl:text-[24px] font-[600] leading-normal font-roboto'>
                                      {item?.document_number}
                                    </p>
                                  </div>
                                  <div className=' my-btn flex items-center justify-center  h-full mg:w-[24%] lg:w-[15%] xs:w-[72%] 2xl:w-[13%] border-l-[1px] border-[#CFCFCF]'>
                                    <button
                                      onClick={() => {
                                        setOpenSecondModal(true)
                                        setSelectedDocument(item)
                                      }}
                                      className=' position-absolute right-4 rounded-[38px] text-center items-center justify-center bg-[#20808D] inline-flex xs:text-[12px] 2xl:text-[13px] text-[#fff] xs:px-[6px] xs:py-[4px] md:px-[17px] md:py-[5px]'
                                    >
                                      Judgement
                                    </button>
                                  </div>
                                </div>
                                <div className='lg:flex xs:block items-center justify-start gap-2 pt-[15px] pl-[20px]'>
                                  <div className='flex items-center justify-start gap-2 '>
                                    <span className='mb-2 text-[#333] text-[13px] 2xl:text-[16px] font-[500]  leading-normal font-roboto'>
                                      Date:
                                    </span>
                                    <span className='mb-2 text-[#4F4F4F] text-[13px] 2xl:text-[16px] font-[400]  leading-normal font-roboto'>
                                      {item.date_of_hearing}
                                    </span>
                                  </div>
                                  <span className='h-[14px] border-[1px] border-[#000] mb-2 mx-1 opacity-[0.34] xs:hidden lg:block'></span>
                                  <div className='flex items-center justify-start gap-2 '>
                                    <span className='mb-2 text-[#333] text-[13px] 2xl:text-[16px] font-[500]  leading-normal font-roboto'>
                                      Citation:
                                    </span>
                                    <span className='mb-2 text-[#4F4F4F] text-[13px] 2xl:text-[16px] font-[400]  leading-normal font-roboto'>
                                      {item?.document_number}
                                    </span>
                                  </div>
                                  <span className='h-[14px] border-[1px] border-[#000] mb-2 mx-1 opacity-[0.34] xs:hidden lg:block'></span>
                                  <div className='md:flex xs:block items-center justify-start gap-2 '>
                                    <span className='mb-2 text-[#333] text-[13px] 2xl:text-[16px] font-[500]  leading-normal font-roboto xs:pr-2 md:pr-0'>
                                      Judges:
                                    </span>
                                    <span className='mb-2 text-[#4F4F4F] text-[13px] 2xl:text-[16px] font-[400]  leading-normal font-roboto'>
                                      {item?.present}
                                    </span>
                                  </div>
                                </div>
                                <div className='block items-center justify-start gap-2 pt-[5px] pl-[20px] pb-[15px] '>
                                  <span className='mb-2 text-[#333] text-[13px] 2xl:text-[16px] font-[500]  leading-normal font-roboto'>
                                    Statues:
                                  </span>
                                  {Array.isArray(item?.judgement) &&
                                    item?.judgement?.map((act, i) => (
                                      <span
                                        className='mb-2 pl-2 cursor-pointer text-[#418EDA] text-[13px] 2xl:text-[16px] font-[400]  leading-normal font-roboto'
                                        key={i}
                                        id='document-content-id'
                                        onClick={() => {
                                          setOpenModal(true)
                                          setSelectedDocument(item)
                                        }}
                                      >
                                        <Highlighter
                                          highlightClassName='highlightKeywords'
                                          searchWords={keywords}
                                          autoEscape={true}
                                          textToHighlight={
                                            act.act &&
                                            (act.section || act.article)
                                              ? `${act.act} - ${
                                                  act.section
                                                    ? `Section: ${act.section}`
                                                    : `Article: ${act.article}`
                                                }`
                                              : act.act || JSON.stringify(act)
                                          }
                                        />
                                      </span>
                                    ))}
                                </div>
                                <div className='w-full pl-[20px] pt-[15px] pb-[5px] flex item-center justify-between min-h-[60px] bg-[#13343B21] rounded-[0px 0px 30px 30px] pr-[15px]'>
                                  <p className='text-[#4F4F4F] text-justify text-[14px] 2xl:text-[16px] font-[500] leading-[28px] w-[95%]'>
                                    <span className='mr-1 text-[#333] text-[13px] 2xl:text-[16px] font-[500] leading-normal font-roboto'>
                                      Description:
                                    </span>
                                    <Highlighter
                                      highlightClassName='highlightKeywords'
                                      searchWords={keywords}
                                      autoEscape={true}
                                      textToHighlight={
                                        showFullContent[index]
                                          ? item?.summary
                                              ?.replace(/\*[^*]+\*/g, '')
                                              .trim()
                                          : item?.summary
                                              ?.replace(/\*[^*]+\*/g, '')
                                              .slice(0, characterLimit)
                                              .trim()
                                      }
                                    />
                                    {item?.summary?.replace(/\*[^*]+\*/g, '')
                                      .length > characterLimit && (
                                      <span>
                                        {!showFullContent[index] ? '...' : ''}
                                        <span
                                          onClick={() => toggleContent(index)}
                                          className='underline text-[#418EDA] text-[12px] 2xl:text-[16px] font-[500] font-roboto'
                                        >
                                          {showFullContent[index]
                                            ? 'View less'
                                            : 'View more'}
                                        </span>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            )
          })
        ) : (
          <div className='text-center mt-3'></div>
        )}

        {shouldDisplayLoader && (
          <div className='receiver-div d-flex align-items-center'>
            <div className='img-box'>{ai_img}</div>
            <JudgementLoader />
          </div>
        )}

        {summaryLoading && (
          <div className='receiver-div d-flex align-items-center'>
            <div className='img-box'>{ai_img}</div>
            <SummaryLoader />
          </div>
        )}

        {judgementErrorMessage && (
          <div className='receiver-div d-flex align-items-center'>
            <div className='img-box'>{ai_img}</div>
            <div className='error-box'>{judgementErrorMessage}</div>
          </div>
        )}
        {!shouldDisplayLoader &&
          isAdvancedSearchVisible &&
          totalJudgements > 10 && (
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalJudgements / pageSize)}
              onPageChange={handlePageChange}
            />
          )}
        <div ref={messageEndRef} />
      </div>
    </div>
  )
}

export default Judgements

const JudgementLoader = ({ responseTime = 30000 }) => {
  const { themeToggle, toggleDarkMode } = useContext(GlobalContext)
  const [progress, setProgress] = useState(0)
  const [textIndex, setTextIndex] = useState(0)
  const texts = [
    'Analyzing query...',
    'Processing...',
    'Matching results...',
    'Performing actions...',
    'Finalizing result...',
    'Generating response...',
  ]

  const circumference = 2 * Math.PI * 20
  useEffect(() => {
    const totalSteps = texts.length
    const updateInterval = responseTime / totalSteps

    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length)
      const newProgress = ((textIndex + 1) / totalSteps) * 100
      setProgress(newProgress)
    }, updateInterval)

    return () => clearInterval(interval)
  }, [textIndex, texts.length, responseTime])

  return (
    <div className='ms-4 flex items-center gap-3'>
      <svg className='transform -rotate-90 w-[50px] h-[50px]'>
        <circle
          cx='25'
          cy='25'
          r='20'
          stroke='currentColor'
          strokeWidth='5'
          fill='transparent'
          className='text-[#F3F3EE]'
        />
        <circle
          cx='25'
          cy='25'
          r='20'
          stroke='currentColor'
          strokeWidth='5'
          fill='transparent'
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          className='text-[black]'
        />
      </svg>
      <p
        className={`m-0 text-lg font-[500] ${
          themeToggle ? 'text-white' : 'text-black'
        }`}
      >
        {texts[textIndex]}
      </p>
    </div>
  )
}
const SummaryLoader = () => {
  const [progress, setProgress] = useState(0)
  const [textIndex, setTextIndex] = useState(0)
  const texts = [
    'Analyzing query...',
    'Processing...',
    'Matching results...',
    'Performing actions...',
    'Finalyzing result...',
    'Generating response...',
  ]
  const circumference = 2 * Math.PI * 20
  useEffect(() => {
    const updateInterval = 10000
    const fillTime = 60000
    const steps = fillTime / updateInterval

    const interval = setInterval(() => {
      const newProgress = (progress + 100 / steps) % 100
      setProgress(newProgress)
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, updateInterval)

    return () => clearInterval(interval)
  }, [progress, texts.length])

  return (
    <div className='ms-4 flex items-center gap-3'>
      <svg className='transform -rotate-90 w-[50px] h-[50px]'>
        <circle
          cx='25'
          cy='25'
          r='20'
          stroke='currentColor'
          strokeWidth='5'
          fill='transparent'
          className='text-[#F3F3EE]'
        />
        <circle
          cx='25'
          cy='25'
          r='20'
          stroke='currentColor'
          strokeWidth='5'
          fill='transparent'
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          className='text-[black]'
        />
      </svg>
      <p className='m-0 text-lg font-[500] text-white'>{texts[textIndex]}</p>
    </div>
  )
}
