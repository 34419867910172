import Cookies from 'js-cookie'
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket'
import GlobalContext from './GlobalContext.jsx'


export const SocketContext = createContext()

export const socketMessageCommands = {
  allChats: 'all_chats',
  newMessage: 'new_message',
  newChat: 'new_chat',
  updateChatName: 'update_chat_name',
  deleteChat: 'delete_chat',
  fetchMessages: 'fetch_messages',
  messages: 'messages',
  judgmentSocketCommand: 'ai_response',
  advancedSearch: 'advance_search',
}
const SocketContextProvider = ({ children }) => {
  const {
    setChatData,
    setChatLoading,
    setChatLoadingId,
    setCurrentReply,
    chatData,
    setChatHistory,
    setIsAwaitingResponse, //adding this state
    setSelectChatID,
    setJudgementChatData,
    setJudgementChatLoading,
    setJudgementSendBtnLoading,
    setIsLoading,
    setIsresponseJudgmentAi,
    setSubscriptionActive,
    setIsSearchActive,
    setIsChatDataLoading,
    setTotalJudgements,
    setJudgmentSocketConnecting,
    isEOCReceived,
    setEOCReceived,
    selectedTab,
  } = useContext(GlobalContext)
  const socket = useRef(null)
  const judgmentSocket = useRef(null)
  const socketForHistory = useRef(null) // State to track the current active chat ID
  const navigate = useNavigate()
  const location = useLocation(); // Get the current location

  const [socketState, setSocketState] = useState(socket.current)
  const [judgmentSocketState, setJudgmentSocketState] = useState(
    judgmentSocket.current
  )
  const socketsInitialized = useRef(false)

  const socketPaths = ['/ChatBot', '/Judgement', '/Drafts', '/Statutes','/MCQs'];

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase(); // Convert current path to lowercase

    const isSocketPath = socketPaths.some((path) =>
      currentPath.startsWith(path.toLowerCase()) // Compare in lowercase
    );

    if (isSocketPath && !socketsInitialized.current) {
      connectAllSockets();
      socketsInitialized.current = true;
    } else if (!isSocketPath && socketsInitialized.current) {
      closeSocket();
      socketsInitialized.current = false;
    }
  }, [location.pathname]);

  const connectAllSockets = () => {
    connectSocket();
    connectJudgmentSocket();
  };

  const connectSocket = () => {
    const token = Cookies.get('token');
    if (!token) {
      console.error('Token is undefined. Cannot establish WebSocket connection.');
      return;
    }
    const webSocketUrl = `${process.env.REACT_APP_BASE_URL_SOCKET}?token=${token}`
    socket.current = new ReconnectingWebSocket(webSocketUrl)
    socketForHistory.current = new ReconnectingWebSocket(webSocketUrl)

    setSocketState(socket.current)

    socket.current.onopen = () => {
      sendSocketCommand({ command: socketMessageCommands.allChats })
    }

    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data)

      if (data.type === 'websocket.send') {
        const payload = JSON.parse(data.text)

        if (payload.error === 'Your trial period has ended.') {
          setSubscriptionActive(false)
        }
      }
      if (data.type === 'judgment') {
        if (event.type === 'debug') {
        }
      }
      if (typeof data === 'object' && 'judgements' in data) {
        handleNewJudgement(data)
      }
      if (event.type === 'message') {
        const response = JSON.parse(event.data)
        handleSocketMessageResponse(response)
      }
    }

    socketForHistory.current.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.type === 'judgment')
        if (event.type === 'debug') {
        }
      if (event.type === 'message') {
        const response = JSON.parse(event.data)
        handleSocketMessageResponseForHistory(response)
      }
    }
  }

  const connectJudgmentSocket = () => {
    if (
      judgmentSocket.current &&
      judgmentSocket.current.readyState === WebSocket.OPEN
    ) {
      console.log('Judgment WebSocket is already connected.')
      return
    }

    const token = Cookies.get('token')
    const judgmentWebSocketUrl = `${process.env.REACT_APP_BASE_URL_JUDGMENT_SOCKET}?token=${token}`
    judgmentSocket.current = new ReconnectingWebSocket(judgmentWebSocketUrl)

    judgmentSocket.current.onopen = () => {
      sendJudgmentSocketCommand({
        command: socketMessageCommands.judgmentSocketCommand,
      })
      console.log('Judgment WebSocket connected.')
      setJudgmentSocketConnecting(false)
    }

    setJudgmentSocketState(judgmentSocket.current)
    judgmentSocket.current.onmessage = null

    judgmentSocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const ai_judgments = data.data ?? []
      const advance_judgments = data.data?.judgements ?? []
      if (ai_judgments.length === 0) {
        setJudgementChatData([
          { role: 'message', content: 'No Documents found against this query' },
        ])
        setTotalJudgements(0)
      }
      if (
        Array.isArray(data?.data?.judgements) &&
        data?.data?.judgements?.length === 0
      ) {
        setJudgementChatData([
          { role: 'message', content: 'No Documents found against this query' },
        ])
        setTotalJudgements(0)
      } else {
        setJudgementChatData([{ role: 'ai', content: data }])
        setTotalJudgements(data?.data?.total_judgements)
      }

      setJudgementChatLoading(false)
      setJudgementSendBtnLoading(false)
      setIsSearchActive(false)
      setIsresponseJudgmentAi(true)
    }
  }

  const handleNewJudgement = (data) => {
    const newMessage = {
      role: 'judgement',
      content: JSON.stringify({ judgements: data?.judgements }),
    }
    setChatData([...chatData, newMessage])
  }

  const handleSocketMessageResponse = (data) => {
    switch (data.command) {
      case socketMessageCommands.allChats:
        setChatHistory(data.all_chats)
        setChatLoading(false)
        setIsLoading(false)
        setIsChatDataLoading(false)
        break

      case socketMessageCommands.newMessage:
        setChatLoading(false)
        setChatLoadingId(data.chat_id)
        if (data.new_message === '<EOC>') {
          setEOCReceived(true)
        }

        if (isEOCReceived) {
          setCurrentReply(data.new_message)
          setEOCReceived(false)
          setIsAwaitingResponse(false)
        }
        setCurrentReply(data.new_message)
        break
      case socketMessageCommands.newChat:
        const newMessage = { role: 'ai', content: data.new_chat.output }
        setChatData([newMessage])
        setChatLoading(false)
        break
      default:
        break
    }

    if (data.new_message === '<EOC>') {
      navigate(`ChatBot/${data?.chat_id}`, { replace: true })
      setIsAwaitingResponse(false)
    }
  }

  const handleSocketMessageResponseForHistory = (data) => {
    switch (data.command) {
      case socketMessageCommands.messages:
        setChatData(
          data.messages?.map((el) => ({
            ...el,
            role: el.sender?.toLowerCase(),
            content: el.content,
          }))
        )
        setIsChatDataLoading(false)
        setChatLoading(false)
        setIsLoading(false)
      default:
        break
    }
    if (data.new_message === '<EOC>') {
      setIsAwaitingResponse(false)
    }
  }

  const closeSocket = () => {
    try {
      socket.current?.close()
      judgmentSocket.current?.close()
      socketForHistory.current?.close()
    } catch (error) {
      console.log(error)
    }
  }

  const getSocket = () => {
    if (socket?.current) {
      return socket.current
    }
  }
  const getJudgmentSocket = () => judgmentSocket.current

  const sendJudgmentSocketCommand = (params) => {
    setIsAwaitingResponse(true)
    setIsresponseJudgmentAi(false)
    const payload = {
      command: 'ai_response',
      query: params?.query,
    }
    judgmentSocket.current?.send(JSON.stringify(payload))
  }
  const sendJudgmentAdvancedSearch = (searchParams) => {
    const { query, journal, year, command, page, page_size } = searchParams

    const payload = {
      command: command,
      query: query,
      journal: journal,
      year: year,
      page: page,
      page_size: page_size,
    }

    setIsSearchActive(true)
    judgmentSocket.current?.send(JSON.stringify(payload))
  }

  const sendSocketCommand = (payload) => {
    setIsAwaitingResponse(true)
    socket?.current?.send(JSON.stringify(payload))
  }

  const sendSocketCommandForHistory = (payload) => {
    setIsAwaitingResponse(true)
    setSelectChatID(payload?.chat_id)
    socketForHistory?.current?.send(JSON.stringify(payload))
  }
  return (
    <SocketContext.Provider
      value={{
        socket: socketState,
        judgmentSocket: judgmentSocketState,
        getSocket,
        getJudgmentSocket,
        closeSocket,
        sendSocketCommand: sendSocketCommand,
        sendJudgmentSocketCommand,
        sendSocketCommandForHistory: sendSocketCommandForHistory,
        sendJudgmentAdvancedSearch,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export default SocketContextProvider
