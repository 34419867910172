import React from 'react'
import { Link } from 'react-router-dom';
import { returnArrow } from '../staticData/icons'
import { lexa_logo_home } from "../staticData/icons";

function RefundPolicy() {
    return (
        <div>
            <div className="container mx-auto px-4 py-6">
            <a href="/"> {lexa_logo_home}</a>
                <div className="max-w-3xl mx-auto bg-white p-6 shadow rounded">
                    <div className="mt-8 justify-start flex">
                        <span className='mt-1'>  {returnArrow}</span>
                        <Link to="/" className="text-black hover:underline">Go back</Link>
                    </div>
                    <h1 className="text-2xl font-semibold mb-4 text-center underline">Refund Policy</h1>
                    <p className="mb-4">
                        At LEXA, we strive to provide our customers with the best legal tech solutions and services. We understand that there may be situations where you might need to cancel your subscription or request a refund. Please review our refund policy carefully to understand the terms and conditions.
                    </p>
                    <h2 className="text-xl font-semibold my-2 underline">Cancellation and Refund</h2>
                    <ol className="list-decimal list-inside mb-4">
                        <li>
                            <strong>No Refunds After Deduction:</strong> Once your subscription payment has been deducted at the beginning of your billing cycle, we do not offer refunds. This means that if you choose to cancel your subscription after the deduction has taken place, you will not be eligible for a refund for that billing cycle.
                        </li>
                        <li className='mt-1'>
                            <strong>Cancellation Before Deduction:</strong> If you wish to cancel your subscription and prevent the next deduction, you must do so before the deduction date. You can cancel your subscription by visiting your account settings on our website.
                        </li>
                        <li className='mt-1'>
                            <strong >Email Response to Stop Deduction:</strong> If your credit/debit card has already been charged, but you wish to prevent future deductions, you can respond to the reminder email sent at the end of every month with the subject "Deduction Reminder." By replying to this email and requesting to stop the deduction, we will ensure that your billing is halted from the next month.
                        </li>
                        <li className='mt-1'>
                            <strong>Failure to Respond:</strong> If, for any reason, you do not reply to the "Deduction Reminder" email, ignore it, or do not take action to stop the deduction, we will consider it as your consent to continue with the billing for the subsequent month.
                        </li>
                    </ol>
                    <div className="contact-info">
                        <p className="mb-2 font-bold text-xl underline">
                            Contact Us
                        </p>
                        <p>
                            If you have any questions or concerns regarding our refund policy or need assistance with your subscription, please contact our customer support team at <a href="mailto:contact@lexa.lawyer" className="text-blue-600 hover:underline">contact@lexa.lawyer</a>. By using LEXA's services, you agree to adhere to our refund policy and terms of service.
                        </p>
                    </div>
                    <p className="mt-4">
                        Thank you for choosing LEXA for your legal tech needs.
                    </p>

                </div>
            </div>
        </div>
    )
}

export default RefundPolicy
