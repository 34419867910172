import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const AnalyticBarChart = () => {
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
        {
          label: 'Profit',
          data: [50, 70, 110, 81, 56, 55, 125],
          backgroundColor: 'rgb(27,51,58)', // Custom color for Profit
          borderColor: 'rgba(27,51,58, 1)', // Custom border color for Profit
          borderWidth: 1,
          barPercentage: 0.75,
          borderRadius:20
        },
        {
          label: 'Losses',
          data: [100, 48, 40, 19, 86, 27, 80],
          backgroundColor: 'rgb(79, 79, 79)', // Custom color for Losses
          borderColor: 'rgba(79,79,79, 1)', // Custom border color for Losses
          borderWidth: 1,
          barPercentage: 0.75,
          borderRadius:20
        },
      ],
  };

  const options = {
    indexAxis: 'y', // Change the axis to horizontal bars
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          boxWidth: 6, // Adjust legend marker size
        },
      },
      tooltip: {
        mode: 'index',
        intersect: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div className="flex flex-col w-full pt-3 pb-4 px-3 bg-white rounded-lg shadow">
      <h2 className="text-lg font-medium text-gray-700 mb-4 ml-8">Analytics</h2>
      <div className="w-full">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default AnalyticBarChart;

