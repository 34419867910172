import React from "react"
import '../style.css'
const PreviewLayout = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col main-container ">
            <div className="flex-1 mt-[82px] max-w-[1200px] items-center justify-center mx-auto">
                {children}
            </div>
        </div>
    )
}

export default PreviewLayout