import React, { useState } from 'react'
import { Box, Tab, Grid } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

// Local Imports
import JazzCashIcon from '../../../assets/icons/JazzCashIcon'
import EasyPaisaIcon from '../../../assets/icons/EasyPaisaIcon'
import VisaIcon from '../../../assets/icons/VisaIcon'
import BankTransferIcon from '../../../assets/icons/BankTransferIcon'
import JazzCashMethod, {
  JazzCashMethodMobile,
} from '../PaymentMethods/JazzCashMethod'
import PaymentSummary, {
  PaymentSummaryMobile,
} from '../PaymentMethods/PaymentSummary'
import { Api } from '../../../api'
import EasyPaisaMethod, {
  EasyPaisaMethodMobile,
} from '../PaymentMethods/EasyPaisaMethod'
import VisaMethod, { VisaMethodMobile } from '../PaymentMethods/VisaMethod'
import BankTansferMethod, {
  BankTransferethodMobile,
} from '../PaymentMethods/BrankTransferMethod'
import { PaymentCardMobile } from '../../shared/PaymentCard'

const PaymentDetailsForDesktopAndTab = ({ selectedPlan, setSelectedPlan }) => {
  // STATES & METHODS
  const [value, setValue] = useState('1')
  const [promoCode, setPromoCode] = useState('')
  const [validPromoCode, setValidPromoCode] = useState('')
  const [promoLoading, setPromoLoading] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [backendMessage, setBackendMessage] = useState({
    type: '',
    message: '',
  })
  const [paymentError, setPaymentError] = useState({
    type: '',
    message: '',
  })

  // HANDLE TAB CHANGE
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // PAYLOAD
  const payload = {
    promo_code: `${promoCode}`,
    payment_plan_id: selectedPlan?.id,
  }

  // APPLY PROMOCODE
  const handleApplyPromoCode = async () => {
    if (promoCode !== '') {
      try {
        setPromoLoading(true)
        const response = await Api.applyPromoCode(payload)
        const data = response?.data?.data
        setSelectedPlan((prev) => ({
          ...prev,
          amount: data?.amount_after_discount,
        }))
        setPromoLoading(false)
        setBackendMessage({
          type: 'success',
          message: `Successfully you have got ${data?.discount_in_percent}% discount`,
        })
        setValidPromoCode(promoCode)
      } catch (error) {
        const data = error?.response?.data
        setPromoLoading(false)
        setValidPromoCode(promoCode)
        setBackendMessage({ type: 'error', message: data?.message })
        setValidPromoCode('')
      }
    }
  }

  // PAYMENT PAYLOAD
  const paymentPayload = (method) => {
    return {
      payment_plan_id: selectedPlan?.id,
      payment_method: method,
      ...(validPromoCode !== '' && {
        promo_code: validPromoCode,
      }),
    }
  }

  // ADD PAYMENT METHOD PAYMOB
  const handleAddPaymentByPaymob = async (method) => {
    setPaymentLoading(true)
    setPaymentError({})
    try {
      const response = await Api.applyPaymentByPaymob(paymentPayload(method))
      if (response?.data?.data?.url) {
        window.open(response?.data?.data?.url, '_self')
      }
    } catch (error) {
      setPaymentLoading(false)
      setPaymentError({
        type: 'wrong',
        message: 'Something went wrong. Please try again later!',
      })
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        maxWidth='lg'
        width='100%'
        mx='auto'
        gap='10px'
      >
        <Grid item component='div' lg={8.8} md={8}>
          <TabContext value={value}>
            {/* Top Tabs */}
            <Box>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
                indicatorColor='transparent'
                textColor='inherit'
                sx={{
                  '& .MuiTabs-flexContainer': {
                    gap: '15px !important',
                  },
                }}
              >
                <Tab
                  value='1'
                  icon={<JazzCashIcon className='mt-[-20px]' />}
                  sx={{
                    padding: '18px 12px',
                    backgroundColor: value === '1' ? '#ffff' : '#F3F3EE',
                    opacity: 1,
                    border: `1px solid ${value === '1' ? '#CEE9FF' : '#fff'} `,
                    borderBottom: `1px solid ${
                      value === '1' ? '#fff' : '#fff'
                    } `,
                    marginBottom: value === '1' ? '0px' : '10px',
                    borderRadius: '4px',
                  }}
                />
                <Tab
                  value='2'
                  icon={<EasyPaisaIcon />}
                  sx={{
                    padding: '10px 24px',
                    backgroundColor: value === '2' ? '#ffff' : '#F3F3EE',
                    opacity: 1,
                    border: `1px solid ${value === '2' ? '#CEE9FF' : '#fff'} `,
                    borderBottom: `1px solid ${
                      value === '2' ? '#fff' : '#fff'
                    } `,
                    marginBottom: value === '2' ? '0px' : '10px',
                    borderRadius: '4px',
                  }}
                />
                {/* <Tab
                  value='3'
                  icon={<VisaIcon />}
                  sx={{
                    padding: '10px 12px',
                    backgroundColor: value === '3' ? '#ffff' : '#F3F3EE',
                    opacity: 1,
                    border: `1px solid ${value === '3' ? '#CEE9FF' : '#fff'} `,
                    borderBottom: `1px solid ${
                      value === '3' ? '#fff' : '#fff'
                    } `,
                    marginBottom: value === '3' ? '0px' : '10px',
                    borderRadius: '4px',
                  }}
                /> */}
                {/* <Tab
                  value='4'
                  icon={<BankTransferIcon className='mt-[-20px]' />}
                  sx={{
                    padding: '10px 20px',
                    backgroundColor: value === '4' ? '#ffff' : '#F3F3EE',
                    opacity: 1,
                    border: `1px solid ${value === '4' ? '#CEE9FF' : '#fff'} `,
                    borderBottom: `1px solid ${
                      value === '4' ? '#fff' : '#fff'
                    } `,
                    marginBottom: value === '4' ? '0px' : '10px',
                    borderRadius: '4px',
                  }}
                /> */}
              </TabList>
            </Box>

            {/* For Desktop */}
            <Box
              container
              padding={'0px'}
              mt='-2px'
              borderRadius='4px'
              gap='10px'
              border='1px solid #CEE9FF'
            >
              <TabPanel value='1' sx={{ padding: '0px' }}>
                <JazzCashMethod
                  onClickPayNow={handleAddPaymentByPaymob}
                  loading={paymentLoading}
                  error={paymentError}
                />
              </TabPanel>
              <TabPanel value='2' sx={{ padding: '0px' }}>
                <EasyPaisaMethod
                  onClickPayNow={handleAddPaymentByPaymob}
                  loading={paymentLoading}
                  error={paymentError}
                />
              </TabPanel>
              <TabPanel value='3' sx={{ padding: '0px' }}>
                <VisaMethod
                  onClickPayNow={handleAddPaymentByPaymob}
                  loading={paymentLoading}
                  error={paymentError}
                />
              </TabPanel>
              {/* <TabPanel value='4' sx={{ padding: '0px' }}>
                <BankTansferMethod
                  onClickPayNow={handleAddPaymentByPaymob}
                  loading={paymentLoading}
                  error={paymentError}
                />
              </TabPanel> */}
            </Box>
          </TabContext>
        </Grid>
        <Grid item lg={3} md={3.7} sm={12}>
          <PaymentSummary
            selectedPlan={selectedPlan}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            onClickApplyCoupon={handleApplyPromoCode}
            loading={promoLoading}
            message={backendMessage}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PaymentDetailsForDesktopAndTab

export const PaymentDetailsForMobile = ({
  selectedPlanId,
  setSelectedPlanId,
  selectedPlan,
  setSelectedPlan,
}) => {
  // STATES
  const [promoCode, setPromoCode] = useState('')
  const [validPromoCode, setValidPromoCode] = useState('')
  const [promoLoading, setPromoLoading] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [backendMessage, setBackendMessage] = useState({
    type: '',
    message: '',
  })
  const [paymentError, setPaymentError] = useState({
    type: '',
    message: '',
  })

  // PAYLOAD
  const payload = {
    promo_code: `${promoCode}`,
    payment_plan_id: selectedPlan?.id,
  }

  // PAYMENT PAYLOAD
  const paymentPayload = (method) => {
    return {
      payment_plan_id: selectedPlan?.id,
      payment_method: method,
      ...(validPromoCode !== '' && {
        promo_code: validPromoCode,
      }),
    }
  }

  // APPLY PROMOCODE
  const handleApplyPromoCode = async () => {
    if (promoCode !== '') {
      try {
        setPromoLoading(true)
        const response = await Api.applyPromoCode(payload)
        const data = response?.data?.data
        setSelectedPlan((prev) => ({
          ...prev,
          amount: data?.amount_after_discount,
        }))
        setPromoLoading(false)
        setBackendMessage({
          type: 'success',
          message: `Successfully you have got ${data?.discount_in_percent}% discount`,
        })
        setValidPromoCode(promoCode)
      } catch (error) {
        setValidPromoCode('')
        const data = error?.response?.data
        setPromoLoading(false)
        setBackendMessage({ type: 'error', message: data?.message })
      }
    }
  }

  // ADD PAYMENT METHOD PAYMOB
  const handleAddPaymentByPaymob = async (method) => {
    setPaymentLoading(true)
    setPaymentError({})
    try {
      const response = await Api.applyPaymentByPaymob(paymentPayload(method))
      if (response?.data?.data?.url) {
        window.open(response?.data?.data?.url, '_self')
      }
    } catch (error) {
      setPaymentLoading(false)
      setPaymentError({
        type: 'wrong',
        message: 'Something went wrong. Please try again later!',
      })
    }
  }

  return (
    <React.Fragment>
      <Box>
        {selectedPlanId.get('planId') !== null &&
          selectedPlanId.get('planId') !== '' && (
            <React.Fragment>
              {selectedPlanId.get('type') === 'mobile' &&
              selectedPlanId.get('planName') === ('' || null) ? (
                <PaymentCardMobile
                  {...selectedPlan}
                  setSelectedPlanId={setSelectedPlanId}
                />
              ) : (
                selectedPlanId.get('type') === 'mobile' &&
                selectedPlanId.get('planName') !== ('' || null) &&
                selectedPlanId.get('method') === ('' || null) && (
                  <PaymentSummaryMobile
                    selectedPlan={selectedPlan}
                    setSelectedPlanId={setSelectedPlanId}
                    promoCode={promoCode}
                    setPromoCode={setPromoCode}
                    onClickApplyCoupon={handleApplyPromoCode}
                    loading={promoLoading}
                    message={backendMessage}
                  />
                )
              )}

              {/* JazzCash Method */}
              {selectedPlanId.get('type') === 'mobile' &&
                selectedPlanId.get('planName') !== ('' || null) &&
                selectedPlanId.get('method') === ('jazzcash' || null) && (
                  <JazzCashMethodMobile
                    onClickPayNow={handleAddPaymentByPaymob}
                    loading={paymentLoading}
                    error={paymentError}
                  />
                )}
              {/* EasyPaisa Method */}
              {selectedPlanId.get('type') === 'mobile' &&
                selectedPlanId.get('planName') !== ('' || null) &&
                selectedPlanId.get('method') === ('easypaisa' || null) && (
                  <EasyPaisaMethodMobile
                    onClickPayNow={handleAddPaymentByPaymob}
                    loading={paymentLoading}
                    error={paymentError}
                  />
                )}
              {/* EasyPaisa Method */}
              {/* {selectedPlanId.get('type') === 'mobile' &&
                selectedPlanId.get('planName') !== ('' || null) &&
                selectedPlanId.get('method') === ('visaCard' || null) && (
                  <VisaMethodMobile
                    onClickPayNow={handleAddPaymentByPaymob}
                    loading={paymentLoading}
                    error={paymentError}
                  />
                )} */}
              {/* EasyPaisa Method */}
              {selectedPlanId.get('type') === 'mobile' &&
                selectedPlanId.get('planName') !== ('' || null) &&
                selectedPlanId.get('method') === ('debitCard' || null) && (
                  <BankTransferethodMobile
                    onClickPayNow={handleAddPaymentByPaymob}
                    loading={paymentLoading}
                    error={paymentError}
                  />
                )}
            </React.Fragment>
          )}
      </Box>
    </React.Fragment>
  )
}
