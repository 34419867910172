// Library Imports
import React, { useState, useRef, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'

// Local Imports
import TableMainBody from './TableBody'
import TableHeader from './TableHeader'

const CustomTable = ({
  rows,
  columns,
  tableColumnExtensions,
  dataProviders,
  onRowClick,
  isUnits,
  open,
  isSelectedCampus,
  metaData,
}) => {
  // REFS
  const tableRef = useRef(null)
  // STATEs
  const [selectedIds, setSelectedIds] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
  }
  const rowsToShow =
    rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []

  return (
    <React.Fragment>
      <div
        className={`flex flex-col  text-black ${
          isSelectedCampus ? 'h-[calc(100vh-240px)]' : 'h-[calc(100vh-195px)] '
        } overflow-auto border-1 border-gray-200 rounded-md`}
      >
        <TableContainer
          component={Paper}
          sx={{ height: '74vh', boxShadow: 'none', width: '100vw' }}
        >
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
            <TableHeader
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
            {/* TABLE MAIN BODY ROWS */}
            <TableMainBody
              rows={metaData !== '' ? rows : rowsToShow}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              dataProviders={dataProviders}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              onRowClick={onRowClick}
              isUnits={isUnits}
            />
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          className='border-t border-gray-200 '
          sx={{
            p: {
              marginBottom: '0px !important',
            },
            // color: 'white',
            overflow: 'hidden',
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular': {
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 0,
              padding: 0,
              paddingLeft: '13px',
              borderTop: '0px !important',
            },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div:first-of-type':
              {
                display: 'none',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div:nth-child(3)':
              {
                fontSize: '15px',
                marginLeft: '15px',
                paddingBottom: '0px !important',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > p:nth-child(2)':
              {
                fontSize: '12px',
                marginBottom: '0px !important',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > p:nth-child(4)':
              {
                marginLeft: 'auto',
                fontSize: '12px',
                marginBottom: '0px',
              },
            '& .MuiTablePagination-actions button > svg': {
              fontSize: '20px',
            },
            '& .MuiSvgIcon-root': {
              //   backgroundColor: gray,
              fill: 'gray',
            },
          }}
          count={rows?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </div>
    </React.Fragment>
  )
}
export default CustomTable
