// Library Imports
import ApiServices from '../apiService/apiServices';

// Local Imports
import { endPoints } from '../constant/constants';

class controller {
  //************************* Fetch Campus Locations API *******************************//
  fetchLocationsCall = (callback) => {
    return ApiServices.sendGetWithoutAuth(
      {},
      endPoints.fetchLocations,
      callback
    );
  };

  //************************* Fetch States API *******************************//
  fetchStatesCall = (callback) => {
    return ApiServices.sendGetWithAuth({}, endPoints.fetchStates, callback);
  };

  //************************* Fetch Cities API *******************************//
  fetchCitiesCall = (payload, callback) => {
    return ApiServices.sendPostWithAuth(
      payload,
      endPoints.fetchStates,
      callback
    );
  };

  //************************* Login API *******************************//
  loginCall = (payload, callback) => {
    return ApiServices.sendPostWithoutAuth(payload, endPoints.login, callback);
  };

  //************************* Forgot Password API *******************************//
  forgotPasswordCall = (payload, callback) => {
    return ApiServices.sendPostWithoutAuth(
      payload,
      endPoints.forgotPassword,
      callback
    );
  };

  //************************* Forgot Password API *******************************//
  changePassewordCall = (payload, callback) => {
    return ApiServices.sendPostWithAuth(
      payload,
      endPoints.changePassword,
      callback
    );
  };
  //*************** */ fetchBloglist************//

fetchBlogsListPageCall = (payload, callBack) => {
  // Initialize endpoint with the base URL for fetching blog lists
  let endpoint = endPoints.fetchBlogsListPage;

  // Safely check if 'payload' is defined and if 'tags' is an array with elements
  if (payload && payload.tags && Array.isArray(payload.tags) && payload.tags.length > 0) {
    // Encode tags array into a query string parameter
    const tagsParam = `tag=${encodeURIComponent(payload.tags.join(','))}`;
    // Append tags parameter to the endpoint
    endpoint += `?${tagsParam}`;
  }

  // Perform the API call
  ApiServices.sendGetWithoutAuth({}, endpoint, (response) => {
    // Ensure that 'callBack' is a function before calling it
    if (typeof callBack === "function") {
      callBack(response);
    } else {
      console.error("Callback is not a function");
    }
  });
};



    //************************* Fetch Single Blog Page Detail *******************************//
    fetchSingleBlogPageCall = (payload, callBack) => {
      const endpoint = payload?.password
          ? `${endPoints.fetchSingleBlogPage(payload.uid)}?password=${encodeURIComponent(payload.password)}`
          : endPoints.fetchSingleBlogPage(payload.uid);
          // console.log("payload of imageess", payload)
  
      return ApiServices.sendGetWithoutAuth({}, endpoint, callBack);
  };
  

   //*************** */ fetchTagsListCall************//


    fetchTagsListCall = (callBack) => {
      return ApiServices.sendGetWithoutAuth(
        {}, // Assuming no payload is needed for fetching tags list
        endPoints.fetchTagsList,
        callBack
      );
    };
  //************************* Blog List Page  *******************************//

  getBlogPageListCall = (payload, callBack) => {
    if (payload) {
      let endpoint = endPoints.blogList + '?search=' + payload;
      return ApiServices.sendGetWithAuth({}, endpoint, callBack);
    }
    return ApiServices.sendGetWithAuth({}, endPoints.blogList, callBack);
  };

  //************************* Add New Blog Page  *******************************//

  addNewBlogPageCall = (payload, callBack) => {
    return ApiServices.sendPostWithAuth(
      payload?.data,
      endPoints.addNewBlog,
      callBack
    );
  };


  //************************* Add New Blog Page Images Upload  *******************************//

  addNewBlogPageUploadImagesCall = (payload, callBack) => {
    // console.log("Preparing to upload images with payload:", payload);

    // Ensure we have the correct endpoint with the blogId
    const endpoint = endPoints.addNewBlogImagesUpload(payload?.blogId);
    // console.log("Endpoint for image upload:", endpoint);

    // Logging FormData contents
    for (let [key, value] of payload.formData.entries()) {
        // console.log(`from controller${key}: `, value);
    }

    // Making the API call
    return ApiServices.sendPostWithAuthFormData(
        payload.formData,
        endpoint,
        (response) => {
            // console.log("Response from image upload:", response);
            callBack(response);
        }
    );
};

  //************************* Get Single Blog Details Upload  *******************************//

  getSingleBlogPageDetailsCall = (payload, callBack) => {
    let endpoint = `${endPoints.singleBlogPageDetail}/${payload}/`;
    return ApiServices.sendGetWithAuth({}, endpoint, callBack);
  };

  //************************* Update Blog  *******************************//

  updateBlogCall = (payload, callBack) => {
    let endpoint = endPoints.updateBlog(payload.blogId);
    // Proceed with the API call
    return ApiServices.sendPutWithAuth(payload.data, endpoint, callBack);
};


  //*************************  Delete Blog Page *******************************//

  deleteBlogPageCall = (id, callBack) => {
    let endpoint = endPoints.deleteBlogPage(id); // Correctly use the endpoint function
    return ApiServices.sendDeleteWithAuth(endpoint, {}, callBack); // Assuming sendDeleteWithAuth accepts (url, {}, callback)
  };

  //*************************  Delete Blog Page Images *******************************//

  deleteBlogImagesCall = (payload, callBack) => {
    return ApiServices.sendDeleteWithAuthAndPayloadInBody(
      payload,
      endPoints.deleteBlogImages,
      callBack
    );
  };

  //*************************  Update Video Controls  *******************************//

  updateVideoControlsCall = (payload, callBack) => {
    // This will call the function to get the correct endpoint with the blog ID
    let endpoint = endPoints.updateVideoControls(payload?.blogId);
    return ApiServices.sendPutWithAuthFormData(
      payload?.formdata,
      endpoint,
      callBack
    );
  };

  //*************************  Get Tags Listing  *******************************//

  getTagsListingCall = (payload, callBack) => {
    return ApiServices.sendGetWithAuth({}, endPoints.tagsList, callBack);
  };
}

const ApiController = new controller();
export default ApiController;
