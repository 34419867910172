import * as React from 'react'
const VisaIconMobile = (props) => (
  <svg
    width={70}
    height={25}
    viewBox='0 0 75 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M32.3836 23.8307H26.2882L30.0977 0.418346H36.1938L32.3836 23.8307ZM21.1603 0.418346L15.3492 16.5215L14.6615 13.0539L14.6622 13.0552L12.6111 2.52636C12.6111 2.52636 12.3631 0.418346 9.71966 0.418346H0.112728L0 0.814772C0 0.814772 2.93782 1.42601 6.37602 3.49081L11.6717 23.8313H18.0227L27.7204 0.418346H21.1603ZM69.1041 23.8307H74.7011L69.8212 0.41772H64.9213C62.6586 0.41772 62.1075 2.1625 62.1075 2.1625L53.0166 23.8307H59.3707L60.6414 20.353H68.3902L69.1041 23.8307ZM62.3968 15.5489L65.5995 6.78748L67.4013 15.5489H62.3968ZM53.4932 6.04848L54.3631 1.02081C54.3631 1.02081 51.6789 0 48.8807 0C45.8559 0 38.6726 1.32205 38.6726 7.75067C38.6726 13.7992 47.1034 13.8743 47.1034 17.0514C47.1034 20.2284 39.5412 19.6591 37.0455 17.6557L36.1393 22.9126C36.1393 22.9126 38.8611 24.2346 43.0195 24.2346C47.1792 24.2346 53.4544 22.0809 53.4544 16.219C53.4544 10.1317 44.9478 9.56497 44.9478 6.91837C44.9484 4.27114 50.8848 4.6112 53.4932 6.04848Z'
      fill='#2566AF'
    />
  </svg>
)
export default VisaIconMobile
