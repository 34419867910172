import React, { useState} from 'react'
import TwentyOff from '../../assets/landingPage-imgs/PricingOff20.png'
import TenOff from '../../assets/landingPage-imgs/pricingOff10.png'
import bgImage from '../../assets/landingPage-imgs/bg.jpg'
import arrow from '../../assets/landingPage-imgs/Arrow.png'
const pricingPlans = {
  individual: [
    {
      id: 'Starter',
      price: '2,400 PKR',
      duration: 'Monthly',
      planType: 'Starter',
      features: [
        'Unlimited Queries',
        'Access to 20,000+ Judgments',
        'Lexa All Services',
      ],
      discount: null,
      buttonClass: 'bg-[#0e877d]',
      bgColor: 'bg-black',
      textColor: 'text-white',
    },
    {
      id: 'Premium',
      price: '19,999 PKR',
      duration: 'Annually',
      planType: 'Premium',
      features: [
        'Unlimited Queries',
        'Access to 20,000+ Judgments',
        'Lexa All Services',
      ],
      discount: TwentyOff,
      buttonClass: 'bg-[#0e877d]',
      bgColor: 'bg-[#0e877d]',
      textColor: 'text-white',
    },
    {
      id: 'Premium Six Month',
      price: '11,999 PKR',
      duration: 'Six Month',
      planType: 'Premium',
      features: [
        'Unlimited Queries',
        'Access to 20,000+ Judgments',
        'Lexa All Services',
      ],
      discount: TenOff,
      buttonClass: 'bg-[#0e877d]',
      bgColor: 'bg-black',
      textColor: 'text-white',
    },
  ],
  student: [
    {
      id: 'Premium',
      price: '999 PKR',
      duration: 'Monthly',
      planType: 'Basic',
      features: [
        'Unlimited Queries',
        'Access Chat, statuses, MCQs',
        'Lexa limited Services',
      ],
      // discount: TwentyOff,
      buttonClass: 'bg-[#0e877d]',
      bgColor: 'bg-[#0e877d]',
      textColor: 'text-white',
    },
  ],
  lawFirm: [
    {
      id: 'Premium',
      price: '72,000 PKR',
      duration: 'Annually',
      planType: 'Basic',
      features: [
        'Unlimited Queries',
        'Access to 20,000+ Judgments',
        'Including 3 Users',
      ],
      // discount: TwentyOff,
      buttonClass: 'bg-[#0e877d]',
      bgColor: 'bg-[#0e877d]',
      textColor: 'text-white',
    },
  ],
}

const ToggleButton = ({ isAnnual, onToggle }) => {
  return (
    <div className='flex items-center'>
      <div className={`toggle ${isAnnual ? 'active' : ''}`} onClick={onToggle}>
        <div className='toggle-slider'></div>
      </div>
      <span className='ml-5 text-sm'>Billed Annually</span>
    </div>
  )
}


const Pricing = ({handlePath,plan}) => {
  const [selectedCategory, setSelectedCategory] = useState('individual')
  const [isAnnual, setIsAnnual] = useState(true)

  const handleToggle = () => {
    setIsAnnual(!isAnnual)
  }
  return (
    <div
      className='bg-cover bg-center py-12'
      id='Pricing'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className='container-fluid mx-auto px-4'>
        <div className='row'>
          <div className='col-lg-4 col-md-12 '>
            <h2 className='text-[#20808E] text-[48px] mt-24'>
              Packages <br /> and Pricing
            </h2>
            <p className='text-lg leading-relaxed'>
              With our Pro plan, you can take <br /> your Legal knowledge to the{' '}
              <br /> next level faster.
            </p>
            <ToggleButton isAnnual={isAnnual} onToggle={handleToggle} />
            <img src={arrow} alt='' className='ml-2 mt-2' />
            <p className='off-para ml-32 -mt-7 text-2xl'>
              get 20 percent off <br /> if you pay annually
            </p>
          </div>
          <div className='col-md-8'>
            <div className='xs:block md:inline-block lg:block'>
              <div className='button-wrapper bg-[#12343d] block m-auto lg:w-fit xs:w-auto rounded-full mb-5 mt-2'>
                <div className='flex justify-center my-2'>
                  <button
                    onClick={() => setSelectedCategory('student')}
                    className={`px-4 py-2 rounded-full ${
                      selectedCategory === 'student'
                        ? 'bg-[#627D84] text-white'
                        : 'bg-transparent text-white'
                    }`}
                  >
                    STUDENT
                  </button>
                  <button
                    onClick={() => setSelectedCategory('individual')}
                    className={`px-4 py-2 rounded-full ${
                      selectedCategory === 'individual'
                        ? 'bg-[#627D84] text-white'
                        : 'bg-transparent text-white'
                    }`}
                  >
                    INDIVIDUAL
                  </button>
                  <button
                    onClick={() => setSelectedCategory('lawFirm')}
                    className={`px-4 py-2 rounded-full ${
                      selectedCategory === 'lawFirm'
                        ? 'bg-[#627D84] text-white'
                        : 'bg-transparent text-white'
                    }`}
                  >
                    LAW FIRM
                  </button>
                </div>
              </div>

              <div className='md:flex xs:block justify-center'>
                {pricingPlans[selectedCategory]?.map((plan, index) => (
                  <div
                    key={index}
                    className={`rounded-lg p-6 lg:w-80 xs:w-auto xs:mt-8 lg:mt-0 ${
                      plan?.bgColor
                    } ${
                      plan?.textColor
                    } border-2 border-[#212121] relative text-center rounded-2xl lg:${
                      index === 1 ? 'md:transform xs:transform-none md:scale-110 xs:scale-0 z-10' : 'z-0'
                    }`}
                  >
                    {plan?.discount && (
                      <img
                        src={plan?.discount}
                        alt='Discount Badge'
                        className='absolute top-[-20px] right-[-10px] w-16 h-16'
                      />
                    )}
                    <h3 className='text-4xl font-bold mb-2'>{plan?.price}</h3>
                    <p className='text-xl mb-4'>{plan?.duration}</p>
                    <p className='text-4xl font-semibold mb-2 mt-3'>
                      {plan?.planType}
                    </p>
                    
                    <div className='divider border-1 border-white w-20 block m-auto mb-4'></div>
                    <ul className='text-lg mb-4 space-y-2 p-0'>
                      {plan?.features?.map((feature, idx) => (
                        <li className='text-sm ' key={idx}>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => handlePath(plan?.name)}
                      className={`inline-block mt-5 py-2 px-8 text-white font-medium text-lg rounded-full border-1 border-white ${plan.buttonClass} hover:bg-[#0e736a]`}
                    >
                      Subscribe
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
