import React from "react";
import { RotatingLines } from "react-loader-spinner";

function SubmitButtonLogin(props) {
  return (
    <button
      type="button"
      onClick={props.handleSubmit}
      className="  flex xs:px-[40px] xs:py-[14px] justify-center items-center bg-[#0c1f21] md:w-[130px] 2xl:w-[130px] h-[40px] 2xl:h-[50px] 3xl:w-[180px] 3xl:h-[65px] 4xl:w-[280px] 4xl:h-[120px] 3xl:text-2xl 4xl:text-[35px] rounded-[150px] text-white font-medium text-[17px]"
    >
      {props.loading ? (
        <RotatingLines
          strokeColor="white"
          strokeWidth="2"
          animationDuration="0.75"
          width="25"
          visible={true}
        />
      ) : (
        <>{props.title}</>
      )}
    </button>
  );
}

export default SubmitButtonLogin;
