// User Table Data

export const usersData = {
  columns: [
    { name: 'id', title: '#Sr' },
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'First Name' },
    { name: 'email', title: 'Email' },
    { name: 'action', title: 'Actions' },
  ],
  rows: [
    {
      id: 1,
      firstName: 'John Doe',
      lastName: 'John Doe',
      email: 'Ql DataGridViewCellStyle',
      status: true,
    },
    {
      id: 2,
      userName: 'John Wick',
      email: 'Ql DataGridViewCellStyle',
      status: false,
    },
    {
      id: 3,
      userName: 'John Doe',
      email: 'Ql DataGridViewCellStyle',
      status: true,
    },
    {
      id: 4,
      userName: 'John Doe',
      email: 'Ql DataGridViewCellStyle',
      status: false,
    },
  ],
  usersColumnExtensionsData: [
    { columnName: 'id', width: 50 },
    { columnName: 'firstName', width: 150 },
    { columnName: 'lastName', width: 150 },
    { columnName: 'email', width: 150 },

    { columnName: 'action', width: 80 },
  ],
}
