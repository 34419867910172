// Library Imports
import React, { useState, useEffect } from 'react';
// Imports
import BlogsListSection from './BlogSite/blogs/BlogsListSections/BlogsListSection';
import ApiController from './BlogSite/utils/network/Api/api';
import BlogPageSkelton from './BlogSite/blogs/shared/BlogPageSkelton'; 
import { useNavigate } from 'react-router-dom';
 


const HeroSectionAndBlogsSection=()=> {
  // STATE
  const navigate = useNavigate();
  const [blogsLoading, setBlogsLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState('');
  const [tagfilter, setTagfilter] = useState([]);

  // TAGs ADD TAG FILTER
  const handleAddTagFilter = (tag) => {
    if (tagfilter.includes(tag)) {
      return;
    }
    setTagfilter([...tagfilter, tag]);
  };

  // TAGs REMOVE TAG FILTER
  const handleRemoveTagFilter = (tag) => {
    setTagfilter(tagfilter.filter((t) => t !== tag));
  };

  // METHODS FETCH BLOGS & FETCH TAGS LIST
  const fetchBlogsListCall = async (payload) => {
    setBlogsLoading(true);
    ApiController.fetchBlogsListPageCall(payload, (response) => {
      if (response.success) {
        if (response?.data?.length > 0) {
          setBlogs(response?.data);
        } else {
          setBlogs([]);
        }
        setBlogsLoading(false);
      } else {
        setBlogs([]);
        setBlogsLoading(false);
      }
    });
  };
  
  const fetchTagsListCall = async () => {
    setTagsLoading(true);
    ApiController.fetchTagsListCall((response) => { // No need to pass an empty object "{}" as parameter
      if (response.success) {
        // console.log("response from tags list:", response);
        setTags(response?.data?.tags?.length > 0 ? response.data.tags : []);
      } else {
        setTags([]);
      }
      setTagsLoading(false);
    });
  };
  
  useEffect(() => {
    const tags = tagfilter.join(',');
    fetchBlogsListCall();
    fetchTagsListCall();
  }, [tagfilter]);
  
  if (blogsLoading || tagsLoading) {
    return (
      <div style={{backgroundColor:'black' , color: 'white', }}> 
        <BlogPageSkelton />
      </div>
    );
  }
  const onClickToNavigateToHome = () => {
    navigate('/');
  };

  return (
    <div style={{backgroundColor:'black', color: 'white',  }}> 
      <BlogsListSection
        blogs={blogs}
        tags={tags}
        tagfilter={tagfilter}
        handleAddTagFilter={handleAddTagFilter}
        handleRemoveTagFilter={handleRemoveTagFilter}
      />
    </div>
  );
}
export default HeroSectionAndBlogsSection;
