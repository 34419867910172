// Library Imports
import React, { useEffect, useState } from 'react'
import { CircularProgress, Box } from '@mui/material'
// Local Imports
import Layout from '../components/Layout'
import { Api } from '../api'

import MainSectionPaymentPlans from '../components/Payment/MainSection'

const Payment = () => {
  // STATES
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(false)

  // FETCH ALL ACTIVE PLANS
  const fetchActivePlans = async () => {
    setLoading(true)
    try {
      const plans = await Api.fetchPlans()
      setPlans(plans?.data?.data)
      setPlans( plans?.data?.data.sort((a, b) => parseFloat(a.amount.replace(/[^0-9.-]+/g, "")) - parseFloat(b.amount.replace(/[^0-9.-]+/g, ""))));

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
  }

  // FETCH ALL ACTIVE PLANS
  useEffect(() => {
    fetchActivePlans()
  }, [])

  if (loading)
    return (
      <Layout>
        <Box
          display='flex'
          justifyContent='center'
          height='100vh'
          alignItems='center'
        >
          <CircularProgress color='inherit' />
        </Box>
      </Layout>
    )

  return (
    <Layout>
      <MainSectionPaymentPlans plans={plans} />
    </Layout>
  )
}

export default Payment
