import React from 'react'

const UpArrowRound = () => {
  return (
    <React.Fragment>
      <svg
        width='29'
        height='29'
        viewBox='0 0 29 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.00106522 15.1452C0.00106522 14.3794 0.00106522 13.6145 0.00106522 12.8486C0.0649782 12.4087 0.106522 11.9624 0.19813 11.5278C1.372 5.95136 4.68482 2.29341 10.0951 0.527282C10.9898 0.235413 11.9198 0.116109 12.8497 0C13.6156 0 14.3804 0 15.1463 0C15.353 0.0287608 15.5596 0.0585869 15.7663 0.0862825C18.9907 0.507043 21.7634 1.86839 24.0164 4.20228C26.983 7.27649 28.2943 10.9739 27.9619 15.2422C27.8096 17.1968 27.2376 19.029 26.2927 20.7419C23.4326 25.9295 17.5654 28.9547 11.5203 27.7787C5.94391 26.6943 2.30619 23.3176 0.534739 17.9287C0.237543 17.0254 0.116109 16.0848 0 15.1452H0.00106522ZM25.4097 14.0182C25.4182 7.72388 20.3126 2.59593 14.0257 2.58528C7.73134 2.57356 2.59913 7.67701 2.58528 13.9629C2.57143 20.27 7.6834 25.4043 13.9831 25.4097C20.2764 25.415 25.4011 20.3062 25.4097 14.0182Z'
          fill='#9FACAD'
        />
        <path
          d='M12.6005 9.5933C12.3193 9.87665 12.0391 10.1611 11.7568 10.4444C11.1901 11.0111 10.6351 11.5906 10.0514 12.1402C9.31854 12.8316 8.16704 12.5333 7.89861 11.5927C7.78037 11.1762 7.84215 10.7789 8.11165 10.4295C8.18409 10.3358 8.26398 10.2484 8.34813 10.1643C9.88098 8.63035 11.416 7.09963 12.9456 5.56253C13.3568 5.14922 13.8233 4.95322 14.4039 5.12898C14.6361 5.19929 14.8246 5.34309 14.994 5.51246C16.5663 7.08365 18.1375 8.65591 19.7097 10.2271C20.1134 10.6308 20.2572 11.1102 20.0751 11.6524C19.9057 12.1551 19.5254 12.4374 19.0046 12.5099C18.5444 12.5738 18.1726 12.381 17.8531 12.0614C17.0936 11.2998 16.333 10.5403 15.5724 9.78078C15.5096 9.71793 15.4425 9.65935 15.3636 9.58585C15.2891 9.71793 15.3146 9.83724 15.3146 9.95015C15.3125 12.0177 15.3146 14.0864 15.3114 16.154C15.3114 16.3446 15.2987 16.5364 15.2731 16.7249C15.19 17.3577 14.6521 17.8306 14.0183 17.8391C13.3674 17.8487 12.8125 17.3832 12.7208 16.742C12.6921 16.5449 12.6804 16.3436 12.6804 16.1433C12.6772 14.0843 12.6782 12.0252 12.6782 9.96613V9.61887C12.6516 9.60928 12.625 9.5997 12.5983 9.59011L12.6005 9.5933Z'
          fill='#9FACAD'
        />
        <path
          d='M15.3083 21.4517C15.3041 22.2389 14.7342 22.812 13.9971 22.8088C13.2535 22.8045 12.7135 22.2314 12.706 21.4389C12.706 21.4208 12.706 21.4027 12.706 21.3846C12.706 20.574 12.706 19.7623 12.706 18.9516C12.706 18.1314 12.7028 17.3112 12.706 16.491C12.7103 15.6985 13.2525 15.1254 13.996 15.1211C14.7331 15.1169 15.303 15.691 15.3073 16.4771'
          fill='#9FACAD'
        />
      </svg>
    </React.Fragment>
  )
}

export default UpArrowRound
