import React from "react";
import { RotatingLines } from "react-loader-spinner";

function SubmitButton(props) {
  return (
    <button
      type="button"
      onClick={props.handleSubmit}
      className="flex justify-center items-center  bg-[#0c1f21] xs:w-[200px]
       md:w-[220px] 2xl:w-[230px] h-[40px] 2xl:h-[50px] 3xl:w-[280px]
        3xl:h-[65px] 4xl:w-[300px] 4xl:h-[120px] 3xl:text-2xl
         4xl:text-[35px] rounded-[150px] text-[#FCFCF9] font-[400] text-[16px]"
    >
      {props.loading ? (
        <RotatingLines
          strokeColor="white"
          strokeWidth="2"
          animationDuration="0.75"
          width="25"
          visible={true}
        />
      ) : (
        <>{props.title}</>
      )}
    </button>
  );
}

export default SubmitButton;
